import React, {useState} from 'react';
import Select, { components } from 'react-select'
import { observer } from 'mobx-react'
import Pool from "../autorize/UserPool";
import Domen from "../../stores/Domen";
import './index.css';
import GlobalState, {changeChannelCompany, changepopupdeleteCompany,changemikrosegmentations, changepopupMicrosegment, addChannelCompany, changemodelflag, changemessages, changepopupNewMessage, addmickrosegments} from "../../stores/GlobalState";
import PopupDeleteCompany from './popupDeleteCompany'
import PopupMicrosegment from './popupMicrosegment'
import lang from "../../lang"
import zIndex from '@material-ui/core/styles/zIndex';
const Compaings = observer(({setDatasegment, datasegment, setCompanyname}) => {

    const openPopupDeleteCompany = (id) =>{
        changepopupdeleteCompany(id);
    }
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    let optionsCompaings = [{value: "E-mail", label: "E-mail"}, {value: "Push", label: "Push"}];
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            display: 'flex',
            flexWrap: 'nowrap',
            width: '115px',
            height: '38px',
            zIndex: "revert-layer",
            color: 'rgba(2, 2, 3, 0.6)',
            paddingLeft: "10px",
            border: "1px solid rgba(244, 245, 247, 1)",
            background: "rgba(255, 255, 255, 1)",
            boxShadow: 'none',
            '&:hover': {
                border: "1px solid rgba(244, 245, 247, 1)",
            },
            '&:focus': {
                border: "1px solid rgba(244, 245, 247, 1)",
            }
        }),
        
        menu: (provided) => ({
            ...provided,
            background: "rgba(255, 255, 255, 1)",
            border: "1px solid rgba(244, 245, 247, 1)",
            color: 'rgba(2, 2, 3, 0.6)',
            width: '115px',
            marginTop: '-7px',
            boxShadow: 'none',
            cursor: 'pointer',
            zIndex:99999,
            position:"absolute"
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 15,
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'rgba(2, 2, 3, 0.6)',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
    };
    const DropdownIndicator = props => {
        return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {
                props.selectProps.menuIsOpen ? 
                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="#020203"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="black" fill-opacity="0.2"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="black" fill-opacity="0.2"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="#020203"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="black" stroke-opacity="0.2"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="black" stroke-opacity="0.2"/>
                    </svg>
                    : 
                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="#020203"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="black" fill-opacity="0.2"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="black" fill-opacity="0.2"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="#020203"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="black" stroke-opacity="0.2"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="black" stroke-opacity="0.2"/>
                    </svg>
                }
            </components.DropdownIndicator>
        )
        );
    };
    const handlecompaings = (e, id, idcomp) => {
        var result = GlobalState.compaings;
        result.items.map((x)=>
            x.id===idcomp? x.channel=e:<></>
        )

        changeChannelCompany(result);

        var company = {
            campaign_name: GlobalState.compaings?.items[id]?.campaign_name||"",
            description:  GlobalState.compaings?.items[id].description||"",
            goal: GlobalState.compaings?.items[id].goal||"",
            segment: GlobalState.compaings?.items[id].segment||"",
            channel: e,
        };
        changefieldcompaing(company, idcomp)
    };


    const campaignNamech = async(e, id) => {
        var result = GlobalState.compaings;
        result.items.map((x)=>
            x._id===id? x.campaign_name=e:<></>
        )
        changeChannelCompany(result);
    }
    const campaignName = async(e, id, idcomp) => {
        var company = {
            campaign_name: e,
            description:  GlobalState.compaings?.items[id].description||"",
            goal: GlobalState.compaings?.items[id].goal||"",
            segment: GlobalState.compaings?.items[id].segment||"",
            channel: GlobalState.compaings?.items[id].channel||"",
        };
        changefieldcompaing(company, idcomp)
    };
    const campaignDescriptionch = async(e, id) => {
        var result = GlobalState.compaings;
        result.items.map((x)=>
            x._id===id? x.description=e:<></>
        )
        changeChannelCompany(result);
    }
    const campaignDescription = async(e, id, idcomp) => {
        var company = {
            campaign_name: GlobalState.compaings?.items[id]?.campaign_name,
            description:  e,
            goal: GlobalState.compaings?.items[id].goal||"",
            segment: GlobalState.compaings?.items[id].segment||"",
            channel: GlobalState.compaings?.items[id].channel||"",
        };
        changefieldcompaing(company, idcomp)
    };
    const campaignGoalch = async(e, id) => {
        var result = GlobalState.compaings;
        result.items.map((x)=>
            x._id===id? x.goal=e:<></>
        )
        changeChannelCompany(result);
    }
    const campaignGoal = async(e, id, idcomp) => {
        var company = {
            campaign_name: GlobalState.compaings?.items[id]?.campaign_name,
            description:  GlobalState.compaings?.items[id].description||"",
            goal: e,
            segment: GlobalState.compaings?.items[id].segment||"",
            channel: GlobalState.compaings?.items[id].channel||"",
        };
        changefieldcompaing(company,idcomp)
    };

    const changefieldcompaing = async(company, idcomp) =>{
        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `campaign/`+idcomp, {
            method: 'PUT',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(company),
        })
        if(response.status>199&&response.status<399)
        {
            openCompaings()
        }
    }
    const changecompaing = async(id, count, name) =>{

        if(count>0)
        {
            var token = localStorage.getItem("token");
            const response = await fetch(Domen.url + `campaign/`+id+`/cards`, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                }
            })
            if(response.status>199&&response.status<399)
            {
                const data = await response.json();
                
                var newmes = [];
                data.map((n, id)=>
                    
               
                newmes.push({
                    id: n?.card_id,
                    generated_message: n?.latest_message?.generated_message,
                    message_id: n?.latest_message?.message_id,
                    message_name: n?.latest_message?.message_name,
                    status: n?.latest_message?.status,
                    user_id: n?.latest_message?.user_id,
                    user_parameters: {
                        campaign_goal: n?.latest_message?.user_parameters?.campaign_goal,
                        communication_format: n?.latest_message?.user_parameters?.communication_format,
                        company_name: n?.latest_message?.user_parameters?.company_name,
                        copywriting_formula: n?.latest_message?.user_parameters?.copywriting_formula,
                        language: n?.latest_message?.user_parameters?.language,
                        length_message: n?.latest_message?.user_parameters?.length_message,
                        message_type: n?.latest_message?.user_parameters?.message_type,
                        react: n?.latest_message?.user_parameters?.react,
                        sequence_goal: n?.latest_message?.user_parameters?.sequence_goal,
                        signature: n?.latest_message?.user_parameters?.signature,
                        tool: n?.latest_message?.user_parameters?.tool,
                        type_segment: n?.latest_message?.user_parameters?.type_segment,
                        writing_style: n?.latest_message?.user_parameters?.writing_style
                        
                    },
                    version: n?.latest_message?.version
                })
                )
                changemessages(newmes)
                console.log(newmes)
            }
            setDatasegment(id);
            setCompanyname(name);
        }
        else
        {
           
            changepopupNewMessage(id)
        }
        


        
    }
    async function openCompaings()
    {
        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `campaign/`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        if(response.status=="200")
        {
            const data = await response.json();
         
            addChannelCompany(data);
            
        }
        else
        {
            const user = Pool.getCurrentUser();
            user.getSession((err, session) => {
                if (err) {
                    console.error("Error fetching user session:", err);                       
                } 
                else 
                {
                    if (!session) {
                        console.log("No session found");
                        return;
                    }
                    else
                    {
                        const refreshToken  = session.getRefreshToken();
                        user.refreshSession(refreshToken, (err, result) => {
                            const { accessToken } = result;
                            localStorage.setItem("token", accessToken.jwtToken);
                            addCompaings();
                        });
                    }
                    
                }
            })
        }
    } 
    async function addCompaings()
    {
        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `campaign/`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        if(response.status>199&&response.status<399)
        {
            openCompaings()
        }
        
    } 
    if(GlobalState.modelflag==0){ 
        openCompaings();
        changemodelflag(1);
    }

    const changepopupMicrosegment0 = async(a)=>{
        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `segmentations/get_micro_segment/?campaign_key=`+a, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
        if(response.status>199&&response.status<399)
        {
            const data = await response.json();
            console.log(data);
            addmickrosegments(data);

            var prod = [];
            Object.entries(data.micro_segmentations.micro_segment.cat||{}).map(([key, value])=>
            {
                prod = [...prod, {name: key, parametrs: value, check: "false"}]
            }

                
            )
            Object.entries(data.micro_segmentations.micro_segment.num||{}).map(([key, value])=>
                prod = [...prod, {name: key, parametrs: value, check: "false"}]
            )
            Object.entries(data.micro_segmentations.filtered_columns_segments||{}).map(([key, value])=>
                {
                    for(var i = 0; i < prod.length; i++)
                    {
                        if(prod[i].name==key)
                        {
                            prod[i].check = "true";
                        }
                    }
                }
                
            )
            console.log(JSON.stringify(prod)+"reytiue");
            changemikrosegmentations(prod);
            changepopupMicrosegment();
        }
    }

    return (
        <>
            <div className='add_compaing' onClick={()=>addCompaings()}>
                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.4226 15C31.7799 15.6188 31.7799 16.3812 31.4226 17L24.5773 28.8564C24.2201 29.4752 23.5598 29.8564 22.8453 29.8564L9.1547 29.8564C8.44017 29.8564 7.77991 29.4752 7.42265 28.8564L0.57735 17C0.220084 16.3812 0.220084 15.6188 0.57735 15L7.42265 3.14359C7.77991 2.52479 8.44017 2.14359 9.1547 2.14359L22.8453 2.14359C23.5598 2.14359 24.2201 2.52479 24.5774 3.14359L31.4226 15Z" fill="#F5C538" stroke="#F5C538"/>
                    <path d="M16 9V23" stroke="#020203" stroke-linecap="round"/>
                    <path d="M16 9V23" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                    <path d="M16 9V23" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                    <path d="M9 16H23" stroke="#020203" stroke-linecap="round"/>
                    <path d="M9 16H23" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                    <path d="M9 16H23" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                </svg>
                {langv?.compaings?.main?.text8}
            </div>
            <div className='tittles_compaings'>
                <div className='title_compaing'>
                    {langv?.compaings?.main?.text1}
                </div>
                <div className='title_compaing'>
                {langv?.compaings?.main?.text2}
                </div>
                <div className='title_compaing'>
                {langv?.compaings?.main?.text3}
                </div>
                <div className='title_compaing'>
                {langv?.compaings?.main?.text4}
                </div>
                <div className='title_compaing tc2 ghf'>
                {langv?.compaings?.main?.text5}
                </div>
                <div className='title_compaing tc2'>
                {langv?.compaings?.main?.text6}
                </div>
            </div>
            <div className='lines_compaings'>
                {
                    GlobalState.compaings?.items?.map((n, id)=>

                        <div className='line_compain'>
                           
                            <div className='open_compaing' onClick={()=>changecompaing(n._id, n.cards, n?.campaign_name)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.6">
                                    <path d="M20.1464 10.1464C20.4614 10.4614 21 10.2383 21 9.79289V3H14.2071C13.7617 3 13.5386 3.53857 13.8536 3.85355L16.29 6.29L6.29 16.29L3.85355 13.8536C3.53857 13.5386 3 13.7617 3 14.2071V21H9.79289C10.2383 21 10.4614 20.4614 10.1464 20.1464L7.71 17.71L17.71 7.71L20.1464 10.1464Z" fill="#1C1E20"/>
                                    </g>
                                </svg>
                            </div>
                            <input
                                id="workspacename"
                                type="text"
                                placeholder="blank"
                                className="line_input_compaing"
                                value={n?.campaign_name}
                                onChange={(event) => campaignNamech(event.target.value, n._id)}
                                onBlur={(event) => campaignName(event.target.value, id, n._id)}
                            />
                            <input
                                id="workspacename"
                                type="text"
                                placeholder="blank"
                                className="line_input_compaing"
                                value={n.description}
                                onChange={(event) => campaignDescriptionch(event.target.value, n._id)}
                                onBlur={(event) => campaignDescription(event.target.value, id, n._id)}
                            />
                            <input
                                id="workspacename"
                                type="text"
                                placeholder="blank"
                                className="line_input_compaing"
                                value={n.goal}
                                onChange={(event) => campaignGoalch(event.target.value, n._id)}
                                onBlur={(event) => campaignGoal(event.target.value, id, n._id)}
                            />
                            
                            <div className="line_input_compaing_micro" onClick={()=>changepopupMicrosegment0(n._id)}>
                                <input
                                    id="workspacename"
                                    type="text"
                                    placeholder="blank"
                                    className="line_input_compaing"
                                    value={n.segment}//"Microsegment"
                                    disabled="true"
                                />
                                <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(0 -1 -1 0 3.07617 8.53857)" fill="#020203"/>
                                    <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(0 -1 -1 0 3.07617 8.53857)" fill="black" fill-opacity="0.2"/>
                                    <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(0 -1 -1 0 3.07617 8.53857)" fill="black" fill-opacity="0.2"/>
                                    <path d="M3.84615 13.1538L10 6.81348L3.84615 0.846116" stroke="#020203"/>
                                    <path d="M3.84615 13.1538L10 6.81348L3.84615 0.846116" stroke="black" stroke-opacity="0.2"/>
                                    <path d="M3.84615 13.1538L10 6.81348L3.84615 0.846116" stroke="black" stroke-opacity="0.2"/>
                                </svg>
                            </div>
                            <Select 
                                components={{ DropdownIndicator }}
                                options={optionsCompaings} 
                                styles={customStyles} 
                                classNamePrefix='filtercampaing'
                                className='selectproduct'
                                isSearchable={false} 
                                placeholder={<div className='selectplace'>{n.channel || langv?.compaings?.main?.text7}</div>}
                                onChange={(option) => handlecompaings(option.value, id, n._id)}
                            />
                            <input
                                id="workspacename"
                                type="text"
                                placeholder="blank"
                                className="line_input_compaing tc2"
                                disabled="true"
                                value={n.messages}
                            />
                            <div className='open_compaing' onClick={()=>openPopupDeleteCompany(n._id)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.4551 5.27441L5.90838 18.181" stroke="#EA4334" stroke-width="2" stroke-linecap="round"/>
                                    <path d="M5.72656 5.45312L18.6332 17.9998" stroke="#EA4334" stroke-width="2" stroke-linecap="round"/>
                                </svg>
                            </div>
                        </div>
                    )
                }
            </div>
            
            
            

        </>
    );
})

export default Compaings;
