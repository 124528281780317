import { observable, runInAction } from 'mobx'

const GlobalState = observable({
  cname: '',
  website: '',
  desciption: '',
  products: [],
  dataset: [ ],
  datasetPrew:[],
  segmentations: [ ],
  loadsegmentations: 1,
  segmentationsCategory: [],
  activedatasetSegment: {
    id:"",
    idWorkspace:"",
    nameSegment: "",
    nameDataset: "",
    Columns: "", 
    Rows: "", 
    file_key: "",
    file_name: "",
    key_column: "",
    segmentations: [],
    source: "",
    status: "",
    upload_time: "",
    user_id: ""
  },
  compaings:[],
  microsegments:[
    {
      title: "Revenue",
      options: [{
        name: "Low",
        subname: "Number of Objects",
        value: "7 000",
        cheked: "false"
      },
      {
        name: "Mid Low",
        subname: "Number of Objects",
        value: "8 500",
        cheked: "false"
      },
      {
        name: "Mid",
        subname: "Number of Objects",
        value: "2 400",
        cheked: "false"
      },
      {
        name: "Mid High",
        subname: "Number of Objects",
        value: "12 857",
        cheked: "true"
      }]
    },
    {
      title: "Trades",
      options: [{
        name: "<=10 ",
        subname: "Number of Objects",
        value: "2 000",
        cheked: "false"
      },
      {
        name: ">10; <=20 ",
        subname: "Number of Objects",
        value: "3 500",
        cheked: "false"
      },
      {
        name: ">20; <=60 ",
        subname: "Number of Objects",
        value: "1 300",
        cheked: "false"
      },
      {
        name: ">60",
        subname: "Number of Objects",
        value: "6 057",
        cheked: "false"
      }]
    },
    // {
    //   title: "Lifecycle",
    //   options: [{
    //     name: "Onetime",
    //     subname: "Number of Objects",
    //     value: "1 100",
    //     cheked: "false"
    //   },
    //   {
    //     name: "Beginner",
    //     subname: "Number of Objects",
    //     value: "1 700",
    //     cheked: "false"
    //   },
    //   {
    //     name: "Mid",
    //     subname: "Number of Objects",
    //     value: "1 000",
    //     cheked: "false"
    //   },
    //   {
    //     name: "Regular",
    //     subname: "Number of Objects",
    //     value: "1 000",
    //     cheked: "false"
    //   }]
    // }
  ],
  messages:[
    {id:0, check: false, successful: false}
  ],
  models:[],
  activemodel:[],
  activemodeldatasetprew:[],
  activemodeldatasetprewbigquery:[],
  activemodeldatasetprewbigquerypredict:[],
  prewdatasetbigquery:[],
  prewdatasetbigquerypredict:[],
  deploycsv: 0,
  segmentdeploymass1:[],
  segmentdeploymass2:[],
  gmessage: false,
  numbersubmenu: 1,
  categorySegment:"",
  ispopupinfoShow: false,
  ispopupproductShow: false,
  ispopupproductShow2: false,
  ispopupaddworkspaceShow: false,
  ispopupadddatasetShow: false,
  ispopupaddAttachmentShow: false,
  ispopupaddAttachmentShowbigdata: 0,
  ispopupaddAttachmentShowAnalitics: false,
  ispopupaddAttachmentShowAnaliticsbigdata: 0,
  ispopupdeleteProductShow: false,
  ispopupdeleteDatasetShow: false,
  ispopuprefreshDatasetShow: false,
  ispopuprefreshDatasetShowcsv: false,
  ispopuprefreshDatasetnum: 0,
  ispopupdeletedatasetid: "",
  ispopupdeleteatasetname: "",
  ispopupDeploymentShow: false,
  ispopupdeleteCompanyShow: false,
  ispopupdeleteMessageShow: false,
  ispopupdeleteWorkspaceShow: false,
  ispopupMicrosegmentShow: false,
  ispopupaddSegmentationShow: false,
  ispopupaddNewMessage: false,
  curentIdProduct: 'z',
  datasetflag: 0,
  modelflag: 0,
  activemodelflag: 0,
  inputDataset:"",
  outputDataset:"",
  inputDatasetname:"",
  outputDatasetname:"",
  credentialbigquery:[],
  tablebigquery:"",
  comgaingdeleteid:"",
  comgaingdeletename:"",
  ispopupdeleteModelShow: false,
  ispopupdeletemodelid:"",
  ispopupsavenewmessage:false,
  allpagesmodels:0,
  numpagesmodels:1,
  numpagesdatasets:1,
  datasetinmodel:[],
  datasetstottal:0,
  modelstottal:0,
  iddeleteCompany:"",
  iddeleteMessage:"",
  mickrosegments:[],
  idchangecompany:"",
  newmessageemptycompany:[],
  mikrosegmentations:[],
  dataworksektion:"",
})

export default GlobalState


export const changemikrosegmentations = (a) => {
  runInAction(() => {
      GlobalState.mikrosegmentations = a;
  })
}
export const changepopuprefreshDatasetnum = (a) => {
  runInAction(() => {
      GlobalState.ispopuprefreshDatasetnum = a;
  })
}
export const changedataworksektion = (a) => {
  runInAction(() => {
      GlobalState.dataworksektion = a;
  })
}

export const changedatasettottal = (a) => {
  runInAction(() => {
      GlobalState.datasetstottal = a;
  })
}
export const changemodalstottal = (a) => {
  runInAction(() => {
      GlobalState.modelstottal = a;
  })
}
export const minusnumpagesmodels = () => {
  runInAction(() => {
    if(GlobalState.numpagesmodels>1)
    {
      GlobalState.numpagesmodels = GlobalState.numpagesmodels*1-1;
    }
  })
}
export const plusnumpagesmodels = () => {
  runInAction(() => {
    if(GlobalState.numpagesmodels<GlobalState.allpagesmodels)
    {
      GlobalState.numpagesmodels = GlobalState.numpagesmodels*1+1;
    }
  })
}

export const minusnumpagesdatasets = () => {
  runInAction(() => {
    if(GlobalState.numpagesdatasets>1)
    {
      GlobalState.numpagesdatasets = GlobalState.numpagesdatasets*1-1;
    }
  })
}
export const plusnumpagesdatasets = () => {
  runInAction(() => {
    if(GlobalState.numpagesdatasets<Math.ceil(GlobalState.datasetstottal/8))
    {
      GlobalState.numpagesdatasets = GlobalState.numpagesdatasets*1+1;
    }
  })
}

export const addmickrosegments = (m) => {
  runInAction(() => {
    GlobalState.mickrosegments = m;
  })
}
export const addnewmessageemptycompany = (m) => {
  runInAction(() => {
    GlobalState.newmessageemptycompany = m;
  })
}


export const changeallpagesmodels = (count) => {
  runInAction(() => {
    GlobalState.allpagesmodels = count
  })
}
export const changeinfocname = (cname) => {
  runInAction(() => {
    GlobalState.cname = cname
  })
}
export const changeLoadSegmentation = (name) => {
  runInAction(() => {
    GlobalState.loadsegmentations = name
  })
}
export const deletecompaingproduct = (id, name) => {
  runInAction(() => {
    GlobalState.comgaingdeleteid = id
    GlobalState.comgaingdeletename = name
  })
}
export const changeinfowebsite = (website) => {
  runInAction(() => {
    GlobalState.website = website
  })
}
export const changecredentialbigquery = (website) => {
  runInAction(() => {
    GlobalState.credentialbigquery = website
  })
}
export const changetablebigquery = (website) => {
  runInAction(() => {
    GlobalState.tablebigquery = website
  })
}
export const changeinfodescriptions = (desciption) => {
  runInAction(() => {
    GlobalState.desciption = desciption
  })
}

export const addsegmentdeploymass1 = (mass) => {
  runInAction(() => {
    GlobalState.segmentdeploymass1 = mass
  })
}

export const addsegmentdeploymass2 = (mass) => {
  runInAction(() => {
    GlobalState.segmentdeploymass2 = mass
  })
}

export const changeInputDataset = (dataset) => {
  runInAction(() => {
    GlobalState.inputDataset = dataset
  })
}

export const changeOutputDataset = (dataset) => {
  runInAction(() => {
    GlobalState.outputDataset = dataset
  })
}

export const changeInputDatasetName = (dataset) => {
  runInAction(() => {
    GlobalState.inputDatasetname = dataset
  })
}

export const changeOutputDatasetName = (dataset) => {
  runInAction(() => {
    GlobalState.outputDatasetname = dataset
  })
}

export const clearinfoproduct = () => {
  runInAction(() => {
    GlobalState.products = []
  })
}
export const addinfoproduct = (product) => {
  runInAction(() => {
    GlobalState.products = [...GlobalState.products, product]
  })
}
export const adddataset = (dataset) => {
  runInAction(() => {
    GlobalState.dataset = [...GlobalState.dataset, dataset]
  })
}
export const cleardataset = () => {
  runInAction(() => {
    GlobalState.dataset = []
  })
}
export const datasetworkspace = (dataset) => {
  runInAction(() => {
    GlobalState.datasetPrew = dataset
  })
}
export const addsegmentations = (segmentations) => {
  runInAction(() => {
    GlobalState.segmentations= [...GlobalState.segmentations, segmentations]
  })
}
export const addsegmentationsCategory = (segment) => {
  runInAction(() => {
    GlobalState.segmentationsCategory= [...GlobalState.segmentationsCategory, segment]
  })
}
export const addModels = (model) => {
  runInAction(() => {
    GlobalState.models= [...GlobalState.models, model]
  })
}
export const allModels = (model) => {
  runInAction(() => {
    GlobalState.models=model
  })
}
export const changesegmentationsCategory = (segment) => {
  runInAction(() => {
    GlobalState.segmentationsCategory = segment
  })
}
export const addactivedataset = (dataset) => {
  runInAction(() => {
    GlobalState.activedatasetSegment = dataset
  })
}
export const changeinfoproductstype = (id, name) => {
  runInAction(() => {
    if(name!='')
    {
      GlobalState.products[id].type = name
    }
    
  })
}
export const changeinfoproductsname = (id, name) => {
  runInAction(() => {
    if(name!='')
    {
      GlobalState.products[id].name = name
    }
  })
}
export const changeinfoproductsdescription = (id, name) => {
  runInAction(() => {
    GlobalState.products[id].desciption = name
  })
}
export const curentIdProduct = (name) => {
  runInAction(() => {
    GlobalState.curentIdProduct = name
  })
}

export const changeActiveModel = (n) => {
  runInAction(() => {
    GlobalState.activemodel = n
  })
}

export const changeActiveModeldatasetPrew = (n) => {
  runInAction(() => {
    GlobalState.activemodeldatasetprew = n
  })
}
export const changedatasetinModel = (n) => {
  runInAction(() => {
    GlobalState.datasetinmodel = n
  })
}

export const changeActiveModeldatasetPrewbigquery = (n) => {
  runInAction(() => {
    GlobalState.activemodeldatasetprewbigquery = n
  })
}
export const changeActiveModeldatasetPrewbigquerypredict = (n) => {
  runInAction(() => {
    GlobalState.activemodeldatasetprewbigquerypredict = n
  })
}
export const changePrewdatasetbigquery = (n) => {
  runInAction(() => {
    GlobalState.prewdatasetbigquery = n
  })
}
export const changePrewdatasetbigquerypredict = (n) => {
  runInAction(() => {
    GlobalState.prewdatasetbigquerypredict = n
  })
}

export const changepopupinfo = () => {
  runInAction(() => {
    GlobalState.ispopupinfoShow = !GlobalState.ispopupinfoShow
  })
}
export const changepopupsavenewmessage = () => {
  runInAction(() => {
    GlobalState.ispopupsavenewmessage = !GlobalState.ispopupsavenewmessage
  })
}
export const addpopupproduct = () => {
  runInAction(() => {
    GlobalState.ispopupproductShow = !GlobalState.ispopupproductShow
  })
}
export const changepopupproduct = (a) => {
  runInAction(() => {
    GlobalState.curentIdProduct = a;
    GlobalState.ispopupproductShow2 = !GlobalState.ispopupproductShow2
  })
}
export const changepopupNewMessage = (m) => {
  runInAction(() => {
    GlobalState.ispopupaddNewMessage = !GlobalState.ispopupaddNewMessage
    GlobalState.idchangecompany = m
  })
}

export const deletepopupproduct = (a) => {
  runInAction(() => {
    GlobalState.products.splice(a, 1);
  })
}
export const deletepopupdataset = (a) => {
  runInAction(() => {
    GlobalState.dataset.splice(a, 1);
  })
}

export const changepopupworkspace = () => {
  runInAction(() => {
    GlobalState.ispopupaddworkspaceShow = !GlobalState.ispopupaddworkspaceShow;
  })
}

export const changepopupSegmentation = () => {
  runInAction(() => {
    GlobalState.ispopupaddSegmentationShow = !GlobalState.ispopupaddSegmentationShow;
  })
}

export const changepopupdataset = () => {
  runInAction(() => {
    GlobalState.ispopupadddatasetShow = !GlobalState.ispopupadddatasetShow;
  })
}

export const changepopupdeleteProduct = () => {
  runInAction(() => {
    GlobalState.ispopupdeleteProductShow = !GlobalState.ispopupdeleteProductShow;
  })
}

export const changepopupdeleteDataset = (n ,id) => {
  runInAction(() => {
    GlobalState.ispopupdeleteDatasetShow = !GlobalState.ispopupdeleteDatasetShow;
    GlobalState.ispopupdeletedatasetid = n;
    GlobalState.ispopupdeleteatasetname = id;
  })
}
export const changepopuprefreshDataset = (n ,id) => {
  runInAction(() => {
    GlobalState.ispopuprefreshDatasetShow = !GlobalState.ispopuprefreshDatasetShow;
    GlobalState.ispopupdeletedatasetid = n;
    GlobalState.ispopupdeleteatasetname = id;
  })
}
export const changepopuprefreshDatasetcsv = (n ,id) => {
  runInAction(() => {
    GlobalState.ispopuprefreshDatasetShowcsv = !GlobalState.ispopuprefreshDatasetShowcsv;
    GlobalState.ispopupdeletedatasetid = n;
    GlobalState.ispopupdeleteatasetname = id;
  })
}

export const changepopupdeleteModel = (n) => {
  runInAction(() => {
    GlobalState.ispopupdeleteModelShow = !GlobalState.ispopupdeleteModelShow;
    GlobalState.ispopupdeletemodelid = n;
  })
}

export const changepopupDeployment = () => {
  runInAction(() => {
    GlobalState.ispopupDeploymentShow = !GlobalState.ispopupDeploymentShow;
  })
}

export const changepopupdeleteCompany = (n) => {
  runInAction(() => {
    GlobalState.ispopupdeleteCompanyShow = !GlobalState.ispopupdeleteCompanyShow;
    GlobalState.iddeleteCompany = n;
  })
}
export const changepopupdeleteMessage = (n, b) => {
  runInAction(() => {
    GlobalState.ispopupdeleteMessageShow = !GlobalState.ispopupdeleteMessageShow;
    GlobalState.iddeleteMessage = n;
    GlobalState.idchangecompany = b;
  })
}
export const changepopupdeleteWorkspace = () => {
  runInAction(() => {
    GlobalState.ispopupdeleteWorkspaceShow = !GlobalState.ispopupdeleteWorkspaceShow;
  })
}

export const changepopupMicrosegment = () => {
  runInAction(() => {
    GlobalState.ispopupMicrosegmentShow = !GlobalState.ispopupMicrosegmentShow;
  })
}

export const changepopupaddAttachment = (a) => {
  runInAction(() => {
    GlobalState.ispopupaddAttachmentShow = !GlobalState.ispopupaddAttachmentShow;
    GlobalState.ispopupaddAttachmentShowbigdata = a;
  })
}
export const changepopupaddAttachmentbig = (a) => {
  runInAction(() => {
    GlobalState.ispopupaddAttachmentShowbigdata = a;
  })
}

export const changepopupaddAttachmentAnalitics = (a) => {
  runInAction(() => {
    GlobalState.ispopupaddAttachmentShowAnalitics = !GlobalState.ispopupaddAttachmentShowAnalitics;
    GlobalState.ispopupaddAttachmentShowAnaliticsbigdata = a;
  })
}

export const changedatasetflag = (flag) => {
  runInAction(() => {
    GlobalState.datasetflag = flag
  })
}
export const changemodelflag = (flag) => {
  runInAction(() => {
    GlobalState.modelflag = flag
  })
}
export const changeactivemodelflag = (flag) => {
  runInAction(() => {
    GlobalState.activemodelmodelflag = flag
  })
}
export const changeChannelCompany = (segment) => {
  runInAction(() => {
    GlobalState.compaings = segment
  })
}
export const addChannelCompany = (compaings) => {
  runInAction(() => {
    GlobalState.compaings =  compaings
  })
}
export const addmessage = (message) => {
  runInAction(() => {
    GlobalState.messages= [...GlobalState.messages, message]
  })
}
export const changemessages = (n) => {
  runInAction(() => {
    GlobalState.messages = n
    
    var rez = GlobalState.messages
    rez.map((i,z)=>
      i['check'] = "true"
    )
    GlobalState.messages = rez
    })
}
export const changemessagesone = (n) => {
  runInAction(() => {
    GlobalState.messages = n
    })
}
export const changemessagetext = (id, text) => {
  runInAction(() => {
    var rez = GlobalState.messages;
    rez.map((i,z)=>
      i.id.includes(id)?i.generated_message=text:<></>
    )
    GlobalState.messages = rez
  })
}
export const changeMesName = (id, text) => {
  runInAction(() => {
    var rez = GlobalState.messages;
    rez.map((i,z)=>
      i.id.includes(id)?i.user_parameters.campaign_name=text:<></>
    )
    GlobalState.messages = rez
  })
}

export const changeCondition = (id, text) => {
  runInAction(() => {
    var rez = GlobalState.messages;
    rez.map((i,z)=>
      i.id.includes(id)?i.user_parameters.react=text:<></>
    )
    GlobalState.messages = rez
  })
}

export const ChangeMessageType = (id, text) => {
  runInAction(() => {
    var rez = GlobalState.messages;
    rez.map((i,z)=>
      i.id.includes(id)?i.user_parameters.message_type=text:<></>
    )
    GlobalState.messages = rez
  })
}
export const changeTool = (id, text) => {
  runInAction(() => {
    var rez = GlobalState.messages;
    rez.map((i,z)=>
      i.id.includes(id)?i.user_parameters.tool=text:<></>
    )
    GlobalState.messages = rez
  })
}
export const ChangeWritingStyle = (id, text) => {
  runInAction(() => {
    var rez = GlobalState.messages;
    rez.map((i,z)=>
      i.id.includes(id)?i.user_parameters.writing_style=text:<></>
    )
    GlobalState.messages = rez
  })
}

export const ChangeCopywritingFormula = (id, text) => {
  runInAction(() => {
    var rez = GlobalState.messages;
    rez.map((i,z)=>
      i.id.includes(id)?i.user_parameters.copywriting_formula=text:<></>
    )
    GlobalState.messages = rez
  })
}

export const changeGoal = (id, text) => {
  runInAction(() => {
    var rez = GlobalState.messages;
    rez.map((i,z)=>
      i.id.includes(id)?i.user_parameters.campaign_goal=text:<></>
    )
    GlobalState.messages = rez
  })
}

export const changeSignature = (id, text) => {
  runInAction(() => {
    var rez = GlobalState.messages;
    rez.map((i,z)=>
      i.id.includes(id)?i.user_parameters.signature=text:<></>
    )
    GlobalState.messages = rez
  })
}

export const ChangeCommunicationFormat = (id, text) => {
  runInAction(() => {
    var rez = GlobalState.messages;
    rez.map((i,z)=>
      i.id.includes(id)?i.user_parameters.communication_format=text:<></>
    )
    GlobalState.messages = rez
  })
}







export const gmessage = () => {
  runInAction(() => {
    GlobalState.gmessage = true
  })
}
export const catSegment = (n) => {
  runInAction(() => {
    GlobalState.categorySegment = n
  })
}
export const changenumsubmenu = (num) => {
  runInAction(() => {
    GlobalState.numbersubmenu = num
  })
}