import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react'
import './index.css';

const LoadingCompleted = observer(({text}) => {
    return (
        <div className='loading_block'>
            <div className='loading_icon'>
                <div className='big_circle'>
                    <div className='green_circle'>
                    
                    </div>
                </div>
            </div>
            <div className='loading_text'>
                {text}
            </div>
        </div>
    )

})

export default LoadingCompleted;