import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import GlobalState from "../../../stores/GlobalState";

const TabsTarget = observer(() => {
    var tabs = [];
    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets?.map((n, i)=>{
        if(n.target!=null)
        {
            var y = {  
                z:"Target '"+n.target+"'",
                lines:[
                    {
                        title: "True Positives",
                        type: true,
                        procent: (n.confusionMatrix.truePositivesPercentage*100).toFixed(0),
                        col1:n.confusionMatrix.tp,
                        col2:n.confusionMatrix.positivesTotal,
                        text:"The Model correctly predicted "+n.confusionMatrix.tp+" of the "+n.confusionMatrix.positivesTotal+" rows in the test set where <span>Target<span> is <span>"+n.target+"<span>"
                    },
                    {
                        title: "False Positives",
                        type: false,
                        procent: (n.confusionMatrix.falsePositivesPercentage*100).toFixed(0),
                        col1:n.confusionMatrix.fp,
                        col2:n.confusionMatrix.negativesTotal,
                        text:"It incorrectly predicted "+n.confusionMatrix.fp+" of the remaining "+n.confusionMatrix.negativesTotal+" rows as <span>"+n.target+"<span>"
                    },
                    {
                        title: "True Negatives",
                        type: true,
                        procent: (n.confusionMatrix.trueNegativesPercentage*100).toFixed(0),
                        col1:n.confusionMatrix.tn,
                        col2:n.confusionMatrix.negativesTotal,
                        text:"The Model correctly predicted "+n.confusionMatrix.tn+" of the "+n.confusionMatrix.negativesTotal+" rows in the test set where <span>Target<span> is not <span>"+n.target+"<span>"
                    },
                    {
                        title: "False Negatives",
                        type: false,
                        procent: (n.confusionMatrix.falseNegativesPercentage*100).toFixed(0),
                        col1:n.confusionMatrix.fn,
                        col2:n.confusionMatrix.positivesTotal,
                        text:"It incorrectly predicted "+n.confusionMatrix.fn+" of the remaining "+n.confusionMatrix.positivesTotal+" rows as  not <span>"+n.target+"<span>"
                    },
                ]
            }
            tabs.push(y);
        }
    })
    

    const [activetab, setActivetab] = useState(0);
    return (
        <div className='tabstarget'>
            <div className='tabstarget_head'>
                {
                    tabs.map((n, id)=>(
                        <div className={id==activetab?'tabstarget_head_tab active':'tabstarget_head_tab'} onClick={()=>setActivetab(id)}>
                            {n.z}
                        </div>
                    ))
                }
            </div>
            <div className='tabstarget_body'>
                {
                    tabs.map((n, id)=>(
                        <div className={id==activetab?'tabstarget_body_tab active':'tabstarget_body_tab'}>
                            {
                                n.lines.map((x, i)=>(
                                    <div className='tabstarget_body_tab_line'>
                                        <div className='tabstarget_body_tab_line_col1'>
                                            {x.title}
                                        </div>
                                        <div className='tabstarget_body_tab_line_col2'>
                                            <div className='zagruz_line'>
                                               
                                                <span style={x.procent>=0&&x.procent<=49?{width: x.procent+"%", background:"rgba(234, 67, 52, 1)"}:x.procent>49&&x.procent<=59?{width: x.procent+"%", background:"rgba(235, 115, 27, 1)"}:x.procent>59&&x.procent<=69?{width: x.procent+"%", background:"rgba(66, 133, 244, 1)"}:x.procent>69&&x.procent<=79?{width: x.procent+"%", background:"rgba(65, 168, 177, 1)"}:{width: x.procent+"%", background:"rgba(51, 167, 83, 1)"}}></span>
                                            </div>
                                        </div>
                                        <div className='tabstarget_body_tab_line_col3'>
                                            <span>{x.col1}</span>/{x.col2}
                                        </div>
                                        <div className='tabstarget_body_tab_line_col4'>
                                            
                                            <span style={x.procent>=0&&x.procent<=49?{color:"rgba(234, 67, 52, 1)"}:x.procent>49&&x.procent<=59?{color:"rgba(235, 115, 27, 1)"}:x.procent>59&&x.procent<=69?{color:"rgba(66, 133, 244, 1)"}:x.procent>69&&x.procent<=79?{color:"rgba(65, 168, 177, 1)"}:{color:"rgba(51, 167, 83, 1)"}}>{x.procent}%</span>
                                        </div>
                                        <div className='tabstarget_body_tab_line_col5' dangerouslySetInnerHTML={{__html:x.text}}></div>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
})
export default TabsTarget;