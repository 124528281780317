import React, {useState} from 'react';
import './index.css';
import logo from "../../assets/logo.svg"
import lang from "../../lang"

function Welcome() {
    
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    return (
        <>
        <div className='central_img'></div>
        <div className='left_img'></div>
        <div className="welcome">
           
            <h1 className='utp' dangerouslySetInnerHTML={{ __html: langv.welcome }}></h1>
            <div className='logo'>
                <svg width="280" height="32" viewBox="0 0 280 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.0673 31.7291C23.1595 32.0313 21.1434 31.419 19.6742 29.8922C18.5932 28.7689 17.9886 27.338 17.8604 25.8707C16.759 17.1135 14.153 14.3965 5.7395 13.2483C4.31701 13.1212 2.92825 12.492 1.83966 11.3607C-0.613264 8.81172 -0.613264 4.67895 1.83966 2.12993C4.29259 -0.419087 8.26957 -0.419087 10.7225 2.12993C11.7486 3.19618 12.3454 4.53955 12.5131 5.9282L12.5186 5.92248C12.9692 9.62957 13.6772 12.2721 14.958 14.178C16.6605 17.0122 20.1419 18.9544 24.1599 18.9544C28.6425 18.9544 32.4572 16.5371 33.8791 13.1606C34.7737 11.3965 35.3225 9.10577 35.6969 6.11056C35.8332 4.65838 36.4365 3.24458 37.5068 2.13233C39.9598 -0.416647 43.9368 -0.416646 46.3898 2.13233C48.8428 4.68132 48.8428 8.81404 46.3898 11.363C45.364 12.429 44.0717 13.0491 42.7358 13.2236L42.7378 13.2257C34.1144 14.3577 31.4769 17.0524 30.3696 25.9399C30.2284 27.3827 29.626 28.786 28.5622 29.8914C27.5772 30.915 26.3464 31.5276 25.0673 31.7291Z" fill="#F8CD02"/>
                    <path d="M19.7278 11.1424C17.2749 8.59344 17.2749 4.46072 19.7278 1.91174C22.1807 -0.637246 26.1576 -0.637246 28.6105 1.91174C31.0634 4.46072 31.0634 8.59344 28.6105 11.1424C26.1576 13.6914 22.1807 13.6914 19.7278 11.1424Z" fill="white"/>
                    <path d="M36.8083 30.0883C34.3554 27.5393 34.3554 23.4066 36.8083 20.8576C39.2612 18.3086 43.2381 18.3086 45.691 20.8576C48.1439 23.4066 48.1439 27.5393 45.691 30.0883C43.2381 32.6372 39.2612 32.6372 36.8083 30.0883Z" fill="white"/>
                    <path d="M1.86767 30.0883C-0.585224 27.5393 -0.585224 23.4065 1.86767 20.8576C4.32057 18.3086 8.2975 18.3086 10.7504 20.8576C13.2033 23.4065 13.2033 27.5393 10.7504 30.0883C8.2975 32.6372 4.32057 32.6372 1.86767 30.0883Z" fill="white"/>
                    <path d="M161.978 29.6755C163.547 30.3355 165.309 30.6655 167.264 30.6655C169.99 30.6655 172.253 30.1176 174.053 29.0218C175.866 27.9135 177.223 26.4067 178.123 24.5014C179.023 22.5837 179.474 20.4107 179.474 17.9824C179.474 17.8454 179.467 17.6213 179.454 17.31C179.454 16.9862 179.435 16.6811 179.396 16.3947H170.022V18.7482H176.426C176.336 20.6535 175.95 22.3035 175.269 23.6982C174.6 25.0929 173.616 26.1639 172.317 26.911C171.018 27.6582 169.372 28.0318 167.379 28.0318C165.206 28.0193 163.393 27.5337 161.94 26.5748C160.487 25.6035 159.394 24.271 158.661 22.5775C157.941 20.8714 157.587 18.8977 157.6 16.6562C157.613 14.4147 157.979 12.4409 158.699 10.7349C159.432 9.01643 160.519 7.68398 161.959 6.73758C163.399 5.77871 165.206 5.30551 167.379 5.31796C169.437 5.31796 171.153 5.80985 172.529 6.79361C173.918 7.77738 174.915 9.09114 175.519 10.7349L178.374 10.0625C177.641 7.80851 176.342 6.01532 174.478 4.68287C172.626 3.33798 170.221 2.66553 167.264 2.66553C164.615 2.66553 162.345 3.24458 160.455 4.40269C158.564 5.56079 157.111 7.1921 156.095 9.29661C155.092 11.3887 154.591 13.8419 154.591 16.6562C154.591 18.7856 154.88 20.7158 155.459 22.4467C156.037 24.1652 156.873 25.6346 157.966 26.855C159.072 28.0754 160.41 29.0155 161.978 29.6755Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M187.636 29.358C189.127 30.2297 190.889 30.6655 192.921 30.6655C194.94 30.6655 196.747 30.1861 198.341 29.2272C199.949 28.2559 201.164 26.8923 201.987 25.1365L199.344 24.1465C198.701 25.4042 197.82 26.3693 196.702 27.0418C195.596 27.7018 194.297 28.0318 192.805 28.0318C190.619 28.0318 188.928 27.3344 187.732 25.9397C186.671 24.6773 186.086 22.9588 185.976 20.7843H202.392C202.456 18.4183 202.115 16.3822 201.37 14.6762C200.624 12.9702 199.524 11.6626 198.071 10.7536C196.618 9.83208 194.863 9.37133 192.805 9.37133C190.799 9.37133 189.057 9.80718 187.578 10.6789C186.112 11.5506 184.974 12.7896 184.164 14.396C183.354 16.0024 182.949 17.9077 182.949 20.1118C182.949 22.2412 183.36 24.0967 184.183 25.6782C185.006 27.2597 186.157 28.4863 187.636 29.358ZM199.421 18.4867C199.224 16.5204 198.664 14.9887 197.743 13.8917C196.637 12.5592 195.017 11.893 192.882 11.893C190.645 11.893 188.928 12.5966 187.732 14.0038C186.793 15.1212 186.226 16.6155 186.033 18.4867H199.421Z" fill="white"/>
                    <path d="M221.924 19.813V30.1052H224.779V19.0098C224.779 18.1007 224.689 17.1917 224.509 16.2826C224.329 15.3611 224.04 14.4956 223.641 13.6862C223.243 12.8643 222.715 12.1358 222.06 11.5008C221.417 10.8657 220.626 10.3613 219.687 9.98774C218.761 9.61416 217.674 9.42737 216.427 9.42737C214.73 9.42737 213.225 9.7885 211.913 10.5108C210.923 11.0564 210.093 11.7974 209.425 12.7339V9.93171H206.86V30.1052H209.715V19.346C209.715 18.2003 209.85 17.1792 210.12 16.2826C210.39 15.386 210.782 14.6264 211.296 14.0038C211.823 13.3687 212.466 12.883 213.225 12.5468C213.997 12.2106 214.884 12.0424 215.887 12.0424C216.98 12.0424 217.906 12.2355 218.665 12.6215C219.436 13.0075 220.06 13.5555 220.536 14.2653C221.024 14.9626 221.378 15.7845 221.597 16.7309C221.815 17.6649 221.924 18.6922 221.924 19.813Z" fill="white"/>
                    <path d="M230.942 3.02043V6.10249H233.778V3.02043H230.942Z" fill="white"/>
                    <path d="M233.778 9.93171H230.942V30.1052H233.778V9.93171Z" fill="white"/>
                    <path d="M245.019 30.0491C245.958 30.4227 247.051 30.6095 248.298 30.6095C249.996 30.6095 251.5 30.2484 252.812 29.5261C253.803 28.9805 254.632 28.2394 255.3 27.3029V30.1052H257.846V9.93171H255.011V20.6909C255.011 21.8241 254.876 22.8452 254.606 23.7543C254.336 24.6509 253.937 25.4167 253.41 26.0518C252.896 26.6744 252.253 27.1539 251.481 27.4901C250.722 27.8263 249.841 27.9944 248.838 27.9944C247.745 27.9944 246.813 27.8014 246.042 27.4154C245.283 27.0293 244.659 26.4876 244.171 25.7903C243.695 25.0805 243.348 24.2524 243.129 23.306C242.91 22.3595 242.801 21.3322 242.801 20.2239V9.93171H239.946V21.0271C239.946 21.9362 240.036 22.8514 240.216 23.7729C240.396 24.682 240.686 25.5474 241.084 26.3693C241.483 27.1788 242.004 27.901 242.647 28.5361C243.303 29.1712 244.093 29.6755 245.019 30.0491Z" fill="white"/>
                    <path d="M265.745 29.1152C267.327 30.1363 269.34 30.6468 271.783 30.6468C274.342 30.6468 276.348 30.1052 277.801 29.0218C279.267 27.9259 280 26.4254 280 24.5201C280 23.4741 279.768 22.5961 279.306 21.8863C278.843 21.1765 278.065 20.5601 276.972 20.0371C275.878 19.5141 274.374 19.016 272.458 18.5428C270.825 18.1443 269.565 17.7769 268.677 17.4407C267.803 17.1045 267.198 16.7496 266.864 16.376C266.53 15.99 266.363 15.5292 266.363 14.9937C266.363 14.0349 266.826 13.269 267.751 12.6962C268.677 12.1109 269.88 11.837 271.358 11.8743C272.927 11.8992 274.22 12.2604 275.236 12.9577C276.264 13.6426 276.875 14.5766 277.068 15.7596L279.961 15.2553C279.82 14.0847 279.37 13.0573 278.611 12.1732C277.852 11.2891 276.849 10.6042 275.602 10.1185C274.368 9.62039 272.966 9.37133 271.397 9.37133C269.828 9.37133 268.452 9.61416 267.269 10.0998C266.086 10.573 265.16 11.2455 264.492 12.1172C263.823 12.9764 263.488 13.9788 263.488 15.1245C263.488 16.0709 263.714 16.8741 264.164 17.5341C264.614 18.1816 265.372 18.7545 266.44 19.2526C267.507 19.7507 268.986 20.2488 270.876 20.7469C272.638 21.2077 273.962 21.6062 274.85 21.9424C275.737 22.2786 276.322 22.646 276.605 23.0444C276.901 23.4305 277.049 23.9348 277.049 24.5575C277.049 25.6658 276.592 26.5437 275.679 27.1912C274.766 27.8263 273.519 28.1438 271.937 28.1438C270.317 28.1438 268.954 27.8076 267.848 27.1352C266.755 26.4503 266.054 25.535 265.745 24.3893L262.852 24.8563C263.212 26.6744 264.176 28.094 265.745 29.1152Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M64.3179 3.20722V30.1052H75.9299C77.563 30.1052 78.9968 29.8001 80.2313 29.1899C81.4658 28.5797 82.4239 27.7142 83.1054 26.5935C83.787 25.4603 84.1277 24.1154 84.1277 22.5588C84.1277 20.7905 83.6134 19.2837 82.5846 18.0384C81.7971 17.0852 80.7836 16.3509 79.5439 15.8354C80.3519 15.3881 81.0311 14.7776 81.5816 14.0038C82.3789 12.8706 82.7775 11.5817 82.7775 10.1372C82.7775 8.867 82.456 7.70889 81.813 6.66286C81.1701 5.60438 80.2699 4.76382 79.1126 4.14118C77.9552 3.51854 76.5986 3.20722 75.0426 3.20722H64.3179ZM75.2355 27.4714H67.1727V17.2726H75.7563C76.7979 17.2726 77.7238 17.5154 78.5339 18.0011C79.3569 18.4743 80.0063 19.1094 80.4821 19.9064C80.9579 20.7033 81.1958 21.5999 81.1958 22.5961C81.1958 23.6173 80.9322 24.489 80.4049 25.2112C79.8906 25.9335 79.1833 26.4939 78.2831 26.8923C77.383 27.2784 76.3671 27.4714 75.2355 27.4714ZM75.0233 14.6762H67.1727V5.80362H75.0233C76.425 5.80362 77.5759 6.20211 78.476 6.99908C79.3891 7.79606 79.8456 8.82964 79.8456 10.0998C79.8456 10.9964 79.627 11.7872 79.1897 12.4721C78.7654 13.157 78.1867 13.6987 77.4537 14.0971C76.7207 14.4832 75.9106 14.6762 75.0233 14.6762Z" fill="white"/>
                    <path d="M89.1344 3.02043V6.10249H91.9699V3.02043H89.1344Z" fill="white"/>
                    <path d="M89.1344 9.93171V30.1052H91.9699V9.93171H89.1344Z" fill="white"/>
                    <path d="M113.57 19.813V30.1052H116.425V19.0098C116.425 18.1007 116.335 17.1917 116.154 16.2826C115.974 15.3611 115.685 14.4956 115.286 13.6862C114.888 12.8643 114.361 12.1358 113.705 11.5008C113.062 10.8657 112.271 10.3613 111.332 9.98774C110.406 9.61416 109.32 9.42737 108.072 9.42737C106.375 9.42737 104.87 9.7885 103.559 10.5108C102.568 11.0564 101.738 11.7974 101.07 12.7339V9.93171H98.505V30.1052H101.36V19.346C101.36 18.2003 101.495 17.1792 101.765 16.2826C102.035 15.386 102.427 14.6264 102.941 14.0038C103.469 13.3687 104.112 12.883 104.87 12.5468C105.642 12.2106 106.529 12.0424 107.532 12.0424C108.625 12.0424 109.551 12.2355 110.31 12.6215C111.081 13.0075 111.705 13.5555 112.181 14.2653C112.67 14.9626 113.023 15.7845 113.242 16.7309C113.46 17.6649 113.57 18.6922 113.57 19.813Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.194 29.2646C126.57 30.1986 128.229 30.6655 130.171 30.6655C132.087 30.6655 133.707 30.1986 135.032 29.2646C135.794 28.7199 136.45 28.0659 136.999 27.3025V30.1052H139.545V3.20722H136.69V12.3151C136.2 11.7159 135.634 11.1954 134.993 10.7536C133.656 9.83208 132.01 9.37133 130.055 9.37133C128.152 9.37133 126.519 9.83831 125.156 10.7723C123.792 11.7062 122.744 12.9764 122.011 14.5828C121.291 16.1892 120.931 17.9949 120.931 19.9998C120.931 22.0171 121.298 23.829 122.031 25.4354C122.764 27.0418 123.818 28.3182 125.194 29.2646ZM136.999 19.9998C136.999 21.5563 136.761 22.9511 136.285 24.1839C135.81 25.4042 135.089 26.3631 134.125 27.0605C133.16 27.7578 131.939 28.1065 130.46 28.1065C129.007 28.1065 127.798 27.7516 126.834 27.0418C125.869 26.332 125.143 25.3669 124.654 24.1465C124.178 22.9137 123.94 21.5314 123.94 19.9998C123.94 18.4805 124.172 17.1107 124.635 15.8903C125.111 14.67 125.837 13.7049 126.814 12.9951C127.792 12.2853 129.026 11.9304 130.518 11.9304C131.971 11.9304 133.173 12.2728 134.125 12.9577C135.089 13.6426 135.81 14.5953 136.285 15.8156C136.761 17.0235 136.999 18.4183 136.999 19.9998Z" fill="white"/>
                </svg>
            </div>
            
            <div className='start-buttons'>
                {
                    langv?.menu_welcome?.map((n, i)=>
                        <a href={n.link} className='start-button'>{n.text}</a>
                    )
                }
            </div>
        </div>
        <div className='betta'>
            <div className='betta_inside'>
            {langv?.beta?.head_text}
            </div>
        </div>
        <div className='right_img'></div>
        </>
        
    );
}

export default Welcome;
