import React, {useState} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import {changepopupdataset, changepopupaddAttachment} from "../../stores/GlobalState";
import lang from "../../lang"
const PopupInfo = observer(() =>{
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const rechangepopup = (a) =>{
        changepopupdataset()
        changepopupaddAttachment(a)
    }
    return (
        <div className="popupInfofon">
            <div className="popupInfo dataset">
                <div className='top-element'>
                    <div className='namepopup'>
                        {langv?.dataset?.popupadddataset?.text1}
                    </div>
                    {/* <div className='separator'></div> */}
                    <div></div>
                    <div className='buttonspopup'>
                        <div className='buttonexit' onClick={changepopupdataset}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='adddataset-element centerflex'>
                    <div className='buttonDataset' onClick={()=>rechangepopup(2)}>
                    {langv?.dataset?.popupadddataset?.text2}
                    </div>
                    <div className='buttonDataset' onClick={()=>rechangepopup(1)}>
                    {langv?.dataset?.popupadddataset?.text3}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PopupInfo;
