import React, {useState, useEffect} from 'react';
import './SettingsPage.css';
import { observer } from 'mobx-react'
import Sidebar from '../../components/sidebar/index.js';
import logoBig from '../../assets/logo-big.svg'
import Compaings from '../../components/compaings/index.js'
import Messages from '../../components/compaings/messages.js'
import GlobalState from "../../stores/GlobalState.js";
import PopupMicrosegment from '../../components/compaings/popupMicrosegment.js'
import PopupDeleteCompany from '../../components/compaings/popupDeleteCompany.js'
import Groupsettings from '../../components/groupsettings/index.js'
import PopupInfo from '../../components/groupsettings/popupUpdateInfo.js'
import PopupAddProduct from '../../components/groupsettings/popupAddProducts.js'
import PopupChangeProduct from '../../components/groupsettings/popupChangeProducts.js'
import PopupDeleteProduct from '../../components/groupsettings/popupDeleteProduct.js'
import Beta from '../../components/Beta/index.js';
import UserPool from '../../components/autorize/UserPool';
import {Helmet} from "react-helmet";

const Campaigns= observer(() => {
    const [holdBlock, setholdBlock] = useState(true);
    const [datasegment, setDatasegment] = useState(0);
    const [user, setUser]=useState("");
    const changemenu = () =>{
        setholdBlock(false)
    }
    window.onbeforeunload = () => {
        setholdBlock(true)
    }
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const token = localStorage.getItem("token");
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const cognitoUser = UserPool.getCurrentUser();
            setUser(cognitoUser.username)
        } 
    }, []);
    return (
        <>
            {
                langmenu=="de"?
                <Helmet>
                    <title>Bind Genius — Unternehmenseinstellungen konfigurieren</title>
                    <meta name="description" content="Hilf dem generativen KI-Modell, Inhalte in Deinem Namen zu erstellen. Füge eine Beschreibung Deines Unternehmens, seiner Produkte, Werte, Kommunikationsprinzipien und der wichtigsten Tools für die Interaktion mit Kunden hinzu." />
                </Helmet>
                :
                <Helmet>
                    <title>Bind Genius — Company Settings Configuration</title>
                    <meta name="description" content="Help the generative AI model create content on your behalf. Add a description of your company, its products, values, communication principles, and key tools for customer interaction." />
                </Helmet>
            }
            <Sidebar/>
            {
                user=="admin"||user=="test_team_2"||user=="test_team_3"||user=="test"?
                
                <>
                    <div className="campaign hold">
                        <div className='body_segment'>
                            <Groupsettings/>
                        </div>
                    </div>
                    {GlobalState.ispopupdeleteCompanyShow?<PopupDeleteCompany/>:<></>}
                    {GlobalState.ispopupMicrosegmentShow?<PopupMicrosegment />:<></>}

                    {GlobalState.ispopupinfoShow?<PopupInfo/>:<></>}
                    {GlobalState.ispopupproductShow?<PopupAddProduct/>:<></>}
                    {GlobalState.ispopupproductShow2?<PopupChangeProduct/>:<></>}
                    {GlobalState.ispopupdeleteProductShow?<PopupDeleteProduct id={GlobalState.comgaingdeleteid} name={GlobalState.comgaingdeletename} />:<></>}
                    </>
                :
                <div className="main-page">
                    <Beta/>
                </div>
            }
        </>
    );
})

export default Campaigns;

