import React, {useState} from 'react';
import './index.css';
import logo from "../../assets/logo.svg"
import lang from "../../lang"

function Beta() {
    
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    return (
        <>
        <div className='central_img'></div>
        <div className='left_img'></div>
        <div className="welcome">
           
            <h1 className='utp utp_beta' dangerouslySetInnerHTML={{ __html: langv?.beta?.title_page }}>
                
            </h1>
        </div>
        <div className='betta'>
            <div className='betta_inside'>
            {langv?.beta?.head_text}
            </div>
        </div>
        <div className='right_img'></div>
        </>
        
    );
}

export default Beta;
