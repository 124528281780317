import React, {useState} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import GlobalState, {changeinfocname, changeinfowebsite, changepopupinfo, changeinfodescriptions} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import lang from "../../lang"
const PopupInfo = observer(() =>{
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [flag, setFlag] = useState(0)

    const [cname, setCname] = useState(GlobalState.cname)
    const [website, setWebsite] = useState(GlobalState.website)
    const [desciption, setDesciption] = useState(GlobalState.desciption)


    
    const updateGeneralSettings = async () => {
        if(cname!=''&&website!='')
        {
            var data =  {
                "company": {
                "company_name": cname,
                "link": website,
                "description": desciption
                }
            }; 
            try 
            {
                var token = localStorage.getItem("token");
                fetch(Domen.url + `general_settings/`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                .then(response => {
                    changeinfocname(cname);
                    changeinfowebsite(website);
                    changeinfodescriptions(desciption);
                    setFlag(1);
                })
                .catch(error => {
                    console.error('There was an error with the fetch operation:', error);
                });
            } 
            catch (error) {
                console.error('Error fetching data:', error);
            }
            changepopupinfo();
        }
    };

   

    const chname = (a) =>{
        setCname(a)
        setFlag(1);
    }
    const chlink = (a) =>{
        setWebsite(a)
        setFlag(1);
    }
    const chdesk = (a) =>{
       setDesciption(a)
        setFlag(1);
    }

    return (
        <div className="popupInfofon">
            <div className="popupInfo">
                <div className='top-element'>
                    <div className='namepopup'>
                        {langv?.groupsettings?.popupupdateproduct?.text1}
                    </div>
                    {/* <div className='separator'></div> */}
                    <div></div>
                    <div className='buttonspopup'>
                        <div className={cname==""||website==""||flag==0?'buttonsave':'buttonsave active'} onClick={updateGeneralSettings}>{langv?.groupsettings?.popupupdateproduct?.text2}</div>
                        <div className='buttonexit' onClick={changepopupinfo}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='form-element'>
                    <div className='popup-inputs'>
                        <input
                            id="cname"
                            type="text"
                            placeholder={langv?.groupsettings?.popupupdateproduct?.text3}
                            className="pop-input"
                            value={cname}
                            onChange={(event) => chname(event.target.value)}
                        />
                        <input
                            id="website"
                            type="text"
                            placeholder={langv?.groupsettings?.popupupdateproduct?.text4}
                            className="pop-input"
                            value={website}
                            onChange={(event) => chlink(event.target.value)}
                        />
                    </div>
                    <div className='popup-textarea'>
                        <textarea placeholder={langv?.groupsettings?.popupupdateproduct?.text5} className="pop-area" onChange={(event) => chdesk(event.target.value)}>{desciption}</textarea>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PopupInfo;
