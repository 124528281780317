import React, {useState} from 'react';
import Select, { components } from 'react-select'
import { observer } from 'mobx-react'
import './index.css';
import GlobalState, {changepopupSegmentation, addsegmentationsCategory, changesegmentationsCategory } from "../../stores/GlobalState";
import { BarChart } from '@mui/x-charts/BarChart';
import lang from "../../lang"
const PopupInfo = observer(() =>{
    
    
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [wnamesegmentation, setWnameSegmantation] = useState("");
    const [objectid, setObjectid] = useState("");
    const [objectcount, setObjectcount] = useState("-");
    const [objectOther, setObjectOther] = useState("");
    const [objectprecent, setObjectprecent] = useState("-");
    const [tableexel, setTableexel] = useState([]);
    const [datachartnum, setdatachartnum] = useState([]);
    const [datacharttitle, setdatacharttitle] = useState([]);
    const [flag, setFlag] = useState(0);
    const [segments, setSegments] = useState(GlobalState.segmentationsCategory);

    const updateGeneralSettings = async () => {
        changepopupSegmentation();  
    };

    let options = [];
   

    Object.keys(GlobalState.activedatasetSegment?.segmentations?.cat).map((key, id)=>
        options = [...options, { value: key, label: key }]
    )
    Object.keys(GlobalState.activedatasetSegment?.segmentations?.num).map((key, id)=>
        options = [...options, { value: key, label: key }]
    )
    Object.entries(tableexel).map(([key, value]) =>
        options = [...options, { value: key, label: key }]
    )
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            display: 'flex',
            flexWrap: 'nowrap',
            width: '314px',
            height: '58px',
            color: 'rgba(245, 197, 56, 1)',
            border: '0 !important',
            boxShadow: 'none',
            '&:hover': {
            border: '0 !important',
            },
            '&:focus': {
                border: '0 !important',
            }
        }),
        
        menu: (provided) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            width: '314px',
            marginTop: '-7px',
            boxShadow: 'none',
            cursor: 'pointer'
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 15,
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'white',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
    };
    const customStylesSegment = {
        control: (provided, state) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            display: 'flex',
            flexWrap: 'nowrap',
            width: '172px',
            height: '38px',
            color: 'rgba(245, 197, 56, 1)',
            border: '0 !important',
            borderRadius: "8px !important",
            boxShadow: 'none',
            '&:hover': {
            border: '0 !important',
            },
            '&:focus': {
                border: '0 !important',
            }
        }),
        
        menu: (provided) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            width: '172px',
            marginTop: '-7px',
            boxShadow: 'none',
            cursor: 'pointer'
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 15,
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'white',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
    };
    const DropdownIndicator = props => {
        return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {
                props.selectProps.menuIsOpen ? 
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="12" r="2" fill="white"/>
                        <path d="M1 9L9.24242 1L17 9" stroke="white"/>
                    </svg>
                    : 
                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 7 4.5)" fill="white"/>
                        <path d="M1 5.5L9.24242 13.5L17 5.5" stroke="white"/>
                    </svg> 
                }
            </components.DropdownIndicator>
        )
        );
    };

    
    const addsegmcat = (val) => {
        var z1 = [];
        var z2 = [];
        var iter = 0;
        Object.entries(val[1]).map((key, value)=>{
           
            if(key[0].includes("Other"))
            {
                setObjectcount(key[1].count)
                setObjectOther("Other");
                setObjectprecent(key[1].percent)
                iter=1;
            }
            else
            {
               
                var segment = {
                    id: GlobalState.segmentationsCategory.length+1,
                    segmentname:key[0]||"",
                    optionsSegment1:"",
                    optionsSegment2:"",
                    startvalue: "",
                    endvalue: "",
                    numberofobjects: key[1].count,
                    shareofobjects: key[1].percent,
                    categories: key[1].value,
                    type: 1
                }
                z1 = [...z1, key[1].count];
                z2 = [...z2, key[1].value!=undefined?key[1].value:"Missing Values"];
                setFlag(1);
                addsegmentationsCategory(segment)
                setSegments(GlobalState.segmentationsCategory)
            }
            
        } )
        if(iter!=1){
            setObjectcount("-")
            setObjectprecent("-")
            setObjectOther("");
        }
        setdatachartnum(z1)
        setdatacharttitle(z2)
    } 
    const addsegmnum = (val) => {
        var z1 = [];
        var z2 = [];
        var iter = 0;
        Object.entries(val[1]).map((key, value)=>{
    
            if(key[0].includes("Other")){
                setObjectcount(key[1].count)
                setObjectOther("Other");
                setObjectprecent(key[1].percent)
                iter=1;
            }
            else
            {

                var segment = {
                    id: GlobalState.segmentationsCategory.length+1,
                    segmentname:
                        key[1].left_operator!=undefined?
                        key[1].left_operator+key[1].left+", "+key[1].right_operator+key[1].right
                        :
                        "Missing Values",

                    optionsSegment1:key[1].left_operator!=undefined?key[1].left_operator:"",
                    optionsSegment2:key[1].left_operator!=undefined?key[1].right_operator:"",
                    startvalue: key[1].left,
                    endvalue: key[1].right,
                    numberofobjects: key[1].count,
                    shareofobjects: key[1].percent,
                    categories: key[1].value,
                    type: 2
                }
                setFlag(2);
                z1 = [...z1, key[1].count];
                console.log(key[1].left_operator+"triton")
                z2 = [...z2, (key[1].left_operator!=undefined? key[1].left_operator+key[1].left+", "+key[1].right_operator+key[1].right:"Missing Values")];
                addsegmentationsCategory(segment)
                setSegments(GlobalState.segmentationsCategory)
            }
            
        } )
        if(iter!=1){
            setObjectcount("-")
            setObjectOther("");
            setObjectprecent("-")
        }
        setdatachartnum(z1)
        setdatacharttitle(z2)
        
    } 

    



    const changecategory = (val) =>{
        changesegmentationsCategory([]);
        setdatachartnum([])
        setdatacharttitle([])
        Object.entries(GlobalState.activedatasetSegment?.segmentations?.cat).map((key, value)=>
            key.includes(val)?addsegmcat(key):<></>
        )
        Object.entries(GlobalState.activedatasetSegment?.segmentations?.num).map((key, value)=>
            key.includes(val)?addsegmnum(key):<></>
        )
        setObjectid(val);
    }
    if(GlobalState.categorySegment!==undefined)
    {
        if(flag==0)
        {
            changecategory(GlobalState.categorySegment)
        }
       
    }
    
    
    return (
        <div className="popupInfofon">
            <div className="popupInfo addwork addSegment">
                <div className='top-element'>
                    <div className='namepopup'>
                        {langv?.segmentation?.add_segmentation?.text1}
                    </div>
                    {/* <div className='separator'></div> */}
                    <div></div>
                    <div className='buttonspopup'>
                        <div className={wnamesegmentation===''?'buttonsave':'buttonsave active'}>{langv?.segmentation?.add_segmentation?.text16}</div>
                        <div className='buttonexit' onClick={changepopupSegmentation}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='form-element'>
                    <div className='linenamework'>
                        {/* <input
                            id="workspacename"
                            type="text"
                            placeholder="Workspace Name"
                            className="pop-input"
                            value={wnamesegmentation}
                            onChange={(event) => setWnameSegmantation(event.target.value)}
                        /> */}
                        {GlobalState.categorySegment!==undefined
                        ?
                            <Select 
                                components={{ DropdownIndicator }}
                                options={options} 
                                styles={customStyles} 
                                classNamePrefix='filter'
                                className='selectproduct'
                                isSearchable={false} 
                                placeholder={<div className='selectplace active'>{GlobalState.categorySegment}</div>}
                                onChange={(option) => changecategory(option.value)}
                            />
                            :
                            <Select 
                                components={{ DropdownIndicator }}
                                options={options} 
                                styles={customStyles} 
                                classNamePrefix='filter'
                                className='selectproduct'
                                isSearchable={false} 
                                placeholder={<div className='selectplace'>{langv?.segmentation?.add_segmentation?.text2}</div>}
                                onChange={(option) => changecategory(option.value)}
                            />
                        }
                    </div>
                </div>
                <div className='datasetblock'>
                    <div className='segment_lines'>
                        {
                            flag==2?
                            <div className='segment_line_top'>
                                <div className='topname tp1'>
                                {langv?.segmentation?.add_segmentation?.text3}
                                </div>
                                <div className='topname tp2'>
                                {langv?.segmentation?.add_segmentation?.text4}
                                </div>
                                <div className='topname tp3'>
                                {langv?.segmentation?.add_segmentation?.text5}
                                </div>
                                <div className='topname tp4'>
                                {langv?.segmentation?.add_segmentation?.text6}
                                </div>
                                <div className='topname tp5'>
                                {langv?.segmentation?.add_segmentation?.text7}
                                </div>
                            </div>
                            :
                            <></>
                        }
                        {
                            flag==1?
                            <div className='segment_line_top category_segment_line_top'>
                                <div className='topname tp6'>
                                {langv?.segmentation?.add_segmentation?.text3}
                                </div>
                                <div className='topname tp7'>
                                {langv?.segmentation?.add_segmentation?.text8}
                                </div>
                                <div className='topname tp4'>
                                {langv?.segmentation?.add_segmentation?.text6}
                                </div>
                                <div className='topname tp5'>
                                {langv?.segmentation?.add_segmentation?.text7}
                                </div>
                            </div>:
                            <></>
                        }
                                
                        {
                            flag==2?
                                segments?.map((n, id)=>
                                    <>
                                        <div className='segment_line_inputs'>
                                            <div className='inputname'>
                                                <input
                                                    id="segmentname"
                                                    type="text"
                                                    placeholder={langv?.segmentation?.add_segmentation?.text2}
                                                    className="inputsegment"
                                                    value={n.segmentname||""}
                                                    disabled="true"
                                                    // onChange={(e) => handlesegment_segmentname(e, n.id)}
                                                />
                                            </div>
                                            <div className='inputname  block2'>
                                                <span>
                                                {langv?.segmentation?.add_segmentation?.text17}
                                                </span>
                                                <Select 
                                                    components={{ DropdownIndicator }}
                                                    // options={optionsSegment1} 
                                                    styles={customStylesSegment} 
                                                    classNamePrefix='filter'
                                                    className='selectproduct'
                                                    isSearchable={false} 
                                                    isDisabled
                                                    placeholder={<div className='selectplace'>{n.optionsSegment1||langv?.segmentation?.add_segmentation?.text9}</div>}
                                                    // onChange={(option) => handlesegment_optionsSegment1(option.value, n.id)}
                                                />
                                                <input
                                                    id="startvalue"
                                                    type="text"
                                                    placeholder={langv?.segmentation?.add_segmentation?.text18}
                                                    className="inputsegment mininput"
                                                    value={n.startvalue}
                                                    disabled="true"
                                                    // onChange={(e) => handlesegment_startvalue(e, n.id)}
                                                />
                                            </div>
                                            <div className='inputname block3'>
                                                <span>
                                                {langv?.segmentation?.add_segmentation?.text10}
                                                </span>
                                                <Select 
                                                    components={{ DropdownIndicator }}
                                                    // options={optionsSegment2} 
                                                    styles={customStylesSegment} 
                                                    classNamePrefix='filter'
                                                    className='selectproduct'
                                                    isSearchable={false} 
                                                    isDisabled
                                                    placeholder={<div className='selectplace'>{n.optionsSegment2||langv?.segmentation?.add_segmentation?.text9}</div>}
                                                    // onChange={(option) => handlesegment_optionsSegment2(option.value, n.id)}
                                                />
                                                <input
                                                    id="endvalue"
                                                    type="text"
                                                    placeholder={langv?.segmentation?.add_segmentation?.text11}
                                                    className="inputsegment mininput"
                                                    value={n.endvalue}
                                                    disabled="true"
                                                   //onChange={(e) => handlesegment_endvalue(e, n.id)}
                                                />
                                            </div>
                                            <div className='inputname block4'>
                                                <input
                                                    id="numberofobjects"
                                                    type="text"
                                                    placeholder={langv?.segmentation?.add_segmentation?.text6}
                                                    className="inputsegment mininput"
                                                    value={n.numberofobjects}
                                                    disabled="true"
                                                    // onChange={(e) => handlesegment_numberofobjects(e, n.id)}
                                                />
                                            </div>
                                            <div className='inputname block4'>
                                                <input
                                                    id="shareofobjects"
                                                    type="text"
                                                    placeholder={langv?.segmentation?.add_segmentation?.text7}
                                                    className="inputsegment mininput"
                                                    value={n.shareofobjects}
                                                    disabled="true"
                                                    // onChange={(e) => handlesegment_shareofobjects(e, n.id)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                                :
                                <></>
                        }
                        {
                            flag==1?
                            segments?.map((n, id)=>
                                <>
                                    <div className='segment_line_inputs category_segment_line_top'>
                                        <div className='inputname block5'>
                                            <input
                                                id="segmentname"
                                                type="text"
                                                placeholder={langv?.segmentation?.add_segmentation?.text3}
                                                className="inputsegment"
                                                value={n.segmentname}
                                                disabled="true"
                                                // onChange={(e) => handlesegment_segmentnamecategory(e, n.id)}
                                            />
                                        </div>
                                        <div className='inputname  block6'>
                                            <input
                                                id="categories"
                                                type="text"
                                                placeholder={langv?.segmentation?.add_segmentation?.text12}
                                                className="inputsegment"
                                                value={n.segmentname}
                                                disabled="true"
                                                // onChange={(e) => handlesegment_categories(e, n.id)}
                                            />
                                        </div>
                                        <div className='inputname block4'>
                                            <input
                                                id="numberofobjects"
                                                type="text"
                                                placeholder={langv?.segmentation?.add_segmentation?.text6}
                                                className="inputsegment mininput"
                                                value={n.numberofobjects}
                                                disabled="true"
                                                // onChange={(e) => handlesegment_numberofobjects(e, n.id)}
                                            />
                                        </div>
                                        <div className='inputname block4'>
                                            <input
                                                id="shareofobjects"
                                                type="text"
                                                placeholder={langv?.segmentation?.add_segmentation?.text7}
                                                className="inputsegment mininput"
                                                value={n.shareofobjects}
                                                disabled="true"
                                                // onChange={(e) => handlesegment_shareofobjects(e, n.id)}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                            :
                            <></>
                        }
                        
                    </div>
                    <div className='button_addsegment' /*onClick={()=>addsegment()}*/>
                        <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32.4226 15C32.7799 15.6188 32.7799 16.3812 32.4226 17L25.5773 28.8564C25.2201 29.4752 24.5598 29.8564 23.8453 29.8564L10.1547 29.8564C9.44017 29.8564 8.77991 29.4752 8.42265 28.8564L1.57735 17C1.22008 16.3812 1.22008 15.6188 1.57735 15L8.42265 3.14359C8.77991 2.52479 9.44017 2.14359 10.1547 2.14359L23.8453 2.14359C24.5598 2.14359 25.2201 2.52479 25.5774 3.14359L32.4226 15Z" fill="#2D3239" stroke="#2D3239"/>
                            <g opacity="0.15">
                            <path d="M17 9V23" stroke="white" stroke-linecap="round"/>
                            <path d="M10 16H24" stroke="white" stroke-linecap="round"/>
                            </g>
                        </svg>
                        {langv?.segmentation?.add_segmentation?.text13}
                    </div>
                    {
                        flag!=0?
                            <>
                            <div className='noselected'>
                                <div className='noselected_title'>
                                {langv?.segmentation?.add_segmentation?.text14}
                                    <span>
                                       {
                                        // flag==2?<>{">"} 11, {"<="} Max</>:<></>
                                       } 
                                    </span>
                                </div>
                                <p>
                                {langv?.segmentation?.add_segmentation?.text6} {objectcount} 
                                </p>
                                <p>
                                {langv?.segmentation?.add_segmentation?.text7} {objectprecent} 
                                </p>
                                <div className='noselected_bootom'>
                                {langv?.segmentation?.add_segmentation?.text15}
                                    <input
                                        id="other"
                                        type="text"
                                        placeholder=""
                                        className="inputsegment"
                                        value= {objectOther}
                                    />
                                </div>
                            </div>
                            <div className='graph'>
                          
                            <BarChart
                                series={[
                                    { data: datachartnum },
                                ]}
                                height={400}
                                width={1033}
                                xAxis={[{ 
                                    data: datacharttitle, 
                                    scaleType: 'band',
                                    categoryGapRatio: 0.7, 
                                    colorMap: {
                                        type: 'piecewise',
                                        thresholds: [0, 10],
                                        colors: ['rgba(66, 133, 244, 1)', 'rgba(66, 133, 244, 1)', 'rgba(66, 133, 244, 1)'],
                                    } 
                                }]}
                                margin={{ top: 10, bottom: 30, left: 50, right: 10 }}
                                borderRadius={4}
                                grid={{ horizontal: true }}
                                color= {'#333'}
                                strokeWidth = {"2"}
                                barPercentage= {"0.5"}
                                
                                />
                            </div>
                            </>
                            :
                            <></>
                    }
                </div>
            </div>
        </div>
    );
});

export default PopupInfo;
