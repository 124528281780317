import React, {useState, useEffect} from "react";
import Select, { components } from 'react-select'
import { observer } from 'mobx-react';
import Papa from "papaparse";
import GlobalState, {adddataset} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import lang from "../../lang"
const TableExelfile = observer(({attachments, timedata, setTimedata, save, nameDataset, setnameDataset, objectid, setObjectid, dataid, setDataid}) =>{

  const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
  const [tableexel, setTableexel] = useState([]);
  const [tableexelmap, setTableexelmap] = useState([]);
  const [tableexelcol, setTableexelcol] = useState(0);
  const [tableexelrow, setTableexelrow] = useState(0);
  

  useEffect(() => { 
   
      if(attachments[0]?.file)
      {
          Papa.parse(attachments[0]?.file, {
              header: true,
              skipEmptyLines: true,
              complete: function (results) {
                setTableexel(results.data[0])
                setTableexelrow(results.data.length);
                setTableexelmap(results.data.slice(0,6));
              },
          });
      }
    
  }, [attachments]);
  useEffect(() => { 
    setTableexelcol(Object.keys(tableexel).length);
    var iter = 0;
    var obj_id = "";
    Object.entries(tableexel).map(([key, value]) =>
      {
        iter===0?obj_id=key:<></>
        iter++
      }
    )
    //setObjectid(obj_id);
  }, [tableexelmap]);

  useEffect(() => { 
    
    
    let currentDate = new Date();
    var mounth = currentDate.getMonth()*1+1;
    var dataset = {
      name: nameDataset,
      source: "csv",
      last_changes: "",
      rows: tableexelrow,
      columns: Object.keys(tableexel).length,
      object_ID: objectid,
      used_in_models: ""
    }
    if(tableexelmap.length>0&&nameDataset!=''&&objectid!=''){
      adddataset(dataset);
      
    }
  }, [save]);

  let options = [];
  let options2 = [];
  
  Object.entries(tableexel).map(([key, value]) =>
    options = [...options, { value: key, label: key }]
  )
  if(GlobalState.activemodeldatasetprewbigquery.length>0)
  {
    Object.entries(GlobalState.activemodeldatasetprewbigquery[0]).map(([key, value])=>
      options = [...options, { value: key, label: key }]
    )
  }
  

  const customStyles = {
      control: (provided, state) => ({
        ...provided,
        background: 'rgba(45, 50, 57, 1)',
        display: 'flex',
        flexWrap: 'nowrap',
        width: '178px',
        height: '38px',
        color: 'rgba(245, 197, 56, 1)',
        border: '0 !important',
        borderRadius: "8px",
        marginRight:"20px",
        boxShadow: 'none',
        '&:hover': {
          border: '0 !important',
          },
        '&:focus': {
            border: '0 !important',
        },
        '&::placeholder': {
            border: '0 !important',
        }
      }),
      
      menu: (provided) => ({
        ...provided,
        background: 'rgba(45, 50, 57, 1)',
        width: '178px',
        marginTop: '-7px',
        boxShadow: 'none',
        cursor: 'pointer',
        paddingTop: "0px",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "10px",
      }),
      option: (provided, state) => ({
          ...provided,
          fontSize: 15,
          color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'white',
          backgroundColor: 'rgba(255, 255, 255, 0)', 
          '&:hover': {
              color: 'rgba(235, 115, 27, 1)',
          },
          '&:focus': {
              backgroundColor: 'rgba(255, 255, 255, 0)', 
          },
          '&:active': {
              backgroundColor: 'rgba(255, 255, 255, 0)', 
          }
        }),
  };
  const customStyles2 = {
    control: (provided, state) => ({
      ...provided,
      background: 'rgba(45, 50, 57, 1)',
      display: 'flex',
      flexWrap: 'nowrap',
      width: '178px',
      height: '38px',
      color: 'rgba(245, 197, 56, 1)',
      border: '0 !important',
      borderRadius: "8px",
     
      boxShadow: 'none',
      '&:hover': {
        border: '0 !important',
        },
      '&:focus': {
          border: '0 !important',
      },
      '&::placeholder': {
          border: '0 !important',
      }
    }),
    
    menu: (provided) => ({
      ...provided,
      background: 'rgba(45, 50, 57, 1)',
      width: '178px',
      marginTop: '-7px',
      boxShadow: 'none',
      cursor: 'pointer',
      paddingTop: "0px",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingBottom: "10px",
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: 15,
        color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'white',
        backgroundColor: 'rgba(255, 255, 255, 0)', 
        '&:hover': {
            color: 'rgba(235, 115, 27, 1)',
        },
        '&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0)', 
        },
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 0)', 
        }
      }),
};

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
            {
            props.selectProps.menuIsOpen ? 
                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="12" r="2" fill="white"/>
                    <path d="M1 9L9.24242 1L17 9" stroke="white"/>
                </svg>
                : 
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 7 4.5)" fill="white"/>
                    <path d="M1 5.5L9.24242 13.5L17 5.5" stroke="white"/>
                </svg> 
            }
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <div className="tableexcel_line">
      <div className="tableexcel_head">
        <div className="tableexcel_select place">
        <input
            id="website"
            type="text"
            placeholder={langv?.deploy?.text22}
            className="pop-input datasetname"
            value={nameDataset}
            onChange={(event) => setnameDataset(event.target.value)}
        />
        <div className="svitchtime">
          <div className="svitchtime_text">{langv?.deploy?.text35}</div>
          <div className={timedata?"svitchtime_butt butr1":"svitchtime_butt butr1 active"} onClick={()=>setTimedata(false)}>
          {langv?.deploy?.text36}
          </div>
          <div className={timedata?"svitchtime_butt butr2 active":"svitchtime_butt butr2"} onClick={()=>setTimedata(true)}>
          {langv?.deploy?.text37}
          </div>
        </div>
        <Select 
              components={{ DropdownIndicator }}
              options={options} 
              styles={customStyles} 
              className='selectproduct'
              classNamePrefix='filter'
              isSearchable={false} 
              placeholder={<div className='selectplace'>{"Object ID"}</div>}
              onChange={(option) => setObjectid(option.value)}
          />
          {
            timedata?<></>:
              <Select 
                components={{ DropdownIndicator }}
                options={options} 
                styles={customStyles2} 
                classNamePrefix='filter'
                className='selectproduct'
                isSearchable={false} 
                placeholder={<div className='selectplace'>{langv?.deploy?.text23}</div>}
                onChange={(option) => setDataid(option.value)}
            />
          }
          
         
        </div>
        <div className="params_dataset">
            {/* <div className={timedata?"checkWithoutdate active":"checkWithoutdate"} onClick={()=>setTimedata(!timedata)}>
              <svg className="default_checkWithoutdate" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 1.33126C8.6188 0.973991 9.3812 0.973991 10 1.33126L15.7942 4.67656C16.413 5.03382 16.7942 5.69408 16.7942 6.40861L16.7942 13.0992C16.7942 13.8137 16.413 14.474 15.7942 14.8313L10 18.1766C9.3812 18.5338 8.6188 18.5338 8 18.1766L2.20577 14.8313C1.58697 14.474 1.20577 13.8137 1.20577 13.0992L1.20577 6.40861C1.20577 5.69408 1.58697 5.03382 2.20577 4.67656L8 1.33126Z" stroke="white" stroke-width="1.5"/>
              </svg>
              <svg className="hover_checkWithoutdate" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="10" r="2" fill="#F5C538"/>
                  <path d="M8 1.57735C8.6188 1.22008 9.3812 1.22008 10 1.57735L15.7942 4.92265C16.413 5.27992 16.7942 5.94017 16.7942 6.6547L16.7942 13.3453C16.7942 14.0598 16.413 14.7201 15.7942 15.0774L10 18.4226C9.3812 18.7799 8.6188 18.7799 8 18.4226L2.20577 15.0774C1.58697 14.7201 1.20577 14.0598 1.20577 13.3453L1.20577 6.6547C1.20577 5.94017 1.58697 5.27992 2.20577 4.92265L8 1.57735Z" stroke="#F5C538" stroke-width="1.5"/>
              </svg>
              <svg className="active_checkWithoutdate" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="10" r="4" fill="#33A753"/>
                <path d="M8 1.57735C8.6188 1.22008 9.3812 1.22008 10 1.57735L15.7942 4.92265C16.413 5.27992 16.7942 5.94017 16.7942 6.6547L16.7942 13.3453C16.7942 14.0598 16.413 14.7201 15.7942 15.0774L10 18.4226C9.3812 18.7799 8.6188 18.7799 8 18.4226L2.20577 15.0774C1.58697 14.7201 1.20577 14.0598 1.20577 13.3453L1.20577 6.6547C1.20577 5.94017 1.58697 5.27992 2.20577 4.92265L8 1.57735Z" stroke="#33A753" stroke-width="1.5"/>
              </svg>


              {langv?.deploy?.text21}
            </div> */}
            <div className="line1_params_dataset">
            <div className="param1_title_dataset">
                {langv?.deploy?.text18}
                </div>
                <div className="param1_val_dataset">
                    {tableexelrow||GlobalState.prewdatasetbigquery.rowsCount}
                </div>
                <div className="param2_title_dataset">
                {langv?.deploy?.text17}
                </div>
                <div className="param2_val_dataset">
                    {tableexelcol||GlobalState.prewdatasetbigquery.columnsCount}
                </div>
                
                
               
            </div>
        </div>
      </div>
      <div className="tableexcel_body">
          {
            GlobalState.ispopupaddAttachmentShowbigdata==2 ?
            <table>
            <thead>
              <tr>
              {
                  GlobalState.activemodeldatasetprewbigquery.length>0?
                  Object.entries(GlobalState.activemodeldatasetprewbigquery[0]).map(([key, value]) =>
                    <th>{key}</th>
                  )
                  :
                  <></>
                }
              </tr>
            </thead>
            <tbody>
              {
                GlobalState.activemodeldatasetprewbigquery.length>0?
                GlobalState.activemodeldatasetprewbigquery?.map((n, id)=>
                    <tr>
                      {
                        n!=null?
                        Object.entries(n).map(([key, value]) =>
                          <td>{value}</td>
                        )
                        :
                        <></>
                      }
                    </tr>
                  )
                  :
                  <></>
              }
            </tbody>
          </table>
          :
          <table>
            <thead>
              <tr>
                {
                  Object.entries(tableexel).map(([key, value]) =>
                    <th>{key}</th>
                  )
                }
              </tr>
            </thead>
            <tbody>
              {
                tableexelmap?.map((n, id)=>
                  <tr>
                    {
                      Object.entries(n).map(([key, value]) =>
                        <td>{value.slice(0,10)}{value.length>15?"...":<></>}</td>
                      )
                    }
                  </tr>
                )
              }
            </tbody>
          </table>
        }
        </div>
    </div>
  );
})

export default TableExelfile;

