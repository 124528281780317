import React from 'react';
import './GSettingsPage.css';
import Sidebar from '../../components/sidebar';
import Groupsettings from '../../components/groupsettings'

function GSetingsPage() {

    return (
        <div className="gsetings-page">
            <Sidebar/>
            <Groupsettings/>
        </div>
    );
}

export default GSetingsPage;

