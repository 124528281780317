import React, {useState} from 'react';
import Slider from "react-slick";
import { observer } from 'mobx-react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';
import lang from "../../lang"
import GlobalState, {
    changepopupworkspace, 
    changepopupDeployment, 
    catSegment, 
    addactivedataset, 
    changedatasetflag, 
    adddataset, 
    addsegmentations, 
    changepopupSegmentation,
    addsegmentdeploymass1,
    addsegmentdeploymass2,
    datasetworkspace,
    changeLoadSegmentation,
    changedataworksektion} from "../../stores/GlobalState";
import Pool from "../autorize/UserPool";
import Domen from "../../stores/Domen";
import Loading from '../common/loading';
import LoadingCompleted from '../common/loading_completed';

const Sidebar = observer(() => {
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [mass1, setMass1] = useState([]);
    const [mass2, setMass2] = useState([]);
    const [loadwork, setLoadwork] = useState("1");
    const [textloading, setTextloading] = useState(langv?.loading?.workspace?.text1);
    const [textloadingcompleated, setTextloadingcompleated] = useState(langv?.loading?.workspace?.text2);

    const checksegments = (a) =>
    {
        if(mass1.includes(a))
        {
            var mass0 = [];
            mass1.map((n, id)=>{
                if(n!=a)
                {
                    mass0.push( n );
                }
            })
            setMass1(mass0);
        }
        else
        {
            var mass0 = [];
            mass1.map((n, id)=>{
                mass0.push( n );
            })
            mass0.push( a );
            setMass1(mass0)
        }
    }
    const checksegments2 = (a) =>
        {
            if(mass2.includes(a))
            {
                var mass0 = [];
                mass2.map((n, id)=>{
                    if(n!=a)
                    {
                        mass0.push( n );
                    }
                })
                setMass2(mass0);
            }
            else
            {
                var mass0 = [];
                mass2.map((n, id)=>{
                    mass0.push( n );
                })
                mass0.push( a );
                setMass2(mass0)
            }
    }
    async function getDataAttach()
    {
       
        var token = localStorage.getItem("token");
        if(GlobalState.datasetflag==0){
            const response = await fetch(Domen.url + `dataset`, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                }
            })
            if(response.status=="200")
            {
                const data = await response.json();

                if(data&&data.items.length>0)
                {
                    data.items?.map((n, id)=>
                        {
                            var dataset = {
                                id: n.id,
                                name: n.name,
                                source: n.source,
                                last_changes: n.uploadedAt,
                                rows: n.rowsCount,
                                columns: n.columnsCount,
                                object_ID: n.objectId,
                                used_in_models: n.usedInModels,
                                file_key: n.id,
                                file_name: n.file_name,
                                key_column: n.objectId,
                                status: n.status,
                                upload_time: n.uploadedAt,
                                user_id: n.user_id
                            }
                            
                            adddataset(dataset);
                            
                            
                            
                            
                            return "";
                        }
                        
                    )
                }
            }
            else
            {
                const user = Pool.getCurrentUser();
         
                user.getSession((err, session) => {
                    if (err) {
                        console.error("Error fetching user session:", err);                       
                    } 
                    else 
                    {
                        if (!session) {
                            console.log("No session found");
                            return;
                        }
                        else
                        {
                          
                            const refreshToken  = session.getRefreshToken();
                 
                            user.refreshSession(refreshToken, (err, result) => {
                    
                                const { accessToken } = result;
                                localStorage.setItem("token", accessToken.jwtToken);
                                getDataAttach();
                            });
                        }
                        
                    }
                })
            }
        }
    }
    async function getDataSegments()
    {
       
        var token = localStorage.getItem("token");
        if(GlobalState.datasetflag==0){
            const response = await fetch(Domen.url + `segmentations/`, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                }
            })
            if(response.status=="200")
            {
                const data = await response.json();

                if(data&&data.length>0)
                    {
                        data?.map((n, id)=>
                            {
                                
                                var segmentations = {
                                    date_create: n.date_create,
                                    key_file: n.key_file,
                                    name: n.name,
                                    segmentations: n.segmentations,
                                    user_id: n.user_id,
                                    _id: n._id,
                                }
                                
                                addsegmentations(segmentations);
                                
                                return "";
                            }
                            
                        )
                    }
                console.log(data)
            }
            else
            {
                const user = Pool.getCurrentUser();
         
                user.getSession((err, session) => {
                    if (err) {
                        console.error("Error fetching user session:", err);                       
                    } 
                    else 
                    {
                        if (!session) {
                            console.log("No session found");
                            return;
                        }
                        else
                        {
                          
                            const refreshToken  = session.getRefreshToken();
                 
                            user.refreshSession(refreshToken, (err, result) => {
                    
                                const { accessToken } = result;
                                localStorage.setItem("token", accessToken.jwtToken);
                                getDataSegments();
                            });
                        }
                        
                    }
                })
            }
        }
    }
    const updateGeneralSettings = async (n, name, workspace_key) => {

        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `segmentations/?key_file=`+n, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        
        if(response.status=="200")
        {
            const data = await response.json();
            console.log(data)

            if( data.segmentations!="")
            {
                var dataset = {
                    id: data._id,
                    idWorkspace: workspace_key,
                    nameSegment: name,
                    nameDataset: data.name,
                    Columns: "", 
                    Rows: "", 
                    file_key: data.key_file,
                    file_name: "",
                    segmentations: data.segmentations,
                    key_column: "",
                    source: "",
                    status: "",
                    upload_time: "",
                    user_id: data.user_id,
                    object_id:data.object_id,
                    date_create: data.date_create,
                };
                addactivedataset(dataset);
    
                var segmentations = {
                    date_create: data.date_create,
                    key_file: data.key_file,
                    name: data.name,
                    segmentations: data.segmentations,
                    user_id: data.user_id,
                    _id: data._id
                }
                addsegmentations(segmentations);
            }
            else
            {
                alert(data.status)
            }
           
            
        } 
    }
    const adddatasetprewWorkspace = async (n, name, workspace_key) => {

        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `dataset/`+n+`/preview`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        
        if(response.status=="200")
        {
            const data = await response.json();
            console.log(data)
            datasetworkspace(data.sample);
        } 
    }
    async function getDataWorkspaceSegments()
    {
        
       
        var token = localStorage.getItem("token");
        if(GlobalState.datasetflag==0){
            const response = await fetch(Domen.url + `segmentations/workspace`, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                }
            })
            if(response.status=="200")
            {
                
                const data = await response.json();
                
                if(data.length>0)
                {
                    //changeLoadSegmentation(3);
                    updateGeneralSettings(data[0]?.dataset_key, data[0]?.name,data[0]?._id)
                    adddatasetprewWorkspace(data[0]?.dataset_key, data[0]?.name,data[0]?._id)
                    changedataworksektion(data[0]?.created_at)
                    setTimeout(function(){
                        changeLoadSegmentation(3);
                    },1000)
                    console.log(data[0] + "uhsdvljdsfb,kjaszd")
                }
                else{
                    changeLoadSegmentation(3);
                }
            }
            else
            {
                const user = Pool.getCurrentUser();
         
                user.getSession((err, session) => {
                    if (err) {
                        console.error("Error fetching user session:", err);                       
                    } 
                    else 
                    {
                        if (!session) {
                            console.log("No session found");
                            return;
                        }
                        else
                        {
                          
                            const refreshToken  = session.getRefreshToken();
                 
                            user.refreshSession(refreshToken, (err, result) => {
                    
                                const { accessToken } = result;
                                localStorage.setItem("token", accessToken.jwtToken);
                                getDataAttach();
                            });
                        }
                        
                    }
                })
            }
        }
    }

    if(GlobalState.datasetflag==0){ 
        getDataAttach();
        getDataSegments();
        getDataWorkspaceSegments();
        changedatasetflag(1);
    }
    const openpopupsegments = (v) =>
    {
        catSegment(v)
        changepopupSegmentation()
    }
    const changepopupDeployment0 = () =>
    {
        if(mass1.length>0||mass2.length>0)
        {
            addsegmentdeploymass1(mass1);
            addsegmentdeploymass2(mass2);
            changepopupDeployment();
        }
    }
    console.log(GlobalState.activedatasetSegment)
    return (
        <div className={GlobalState.loadsegmentations==1||GlobalState.loadsegmentations==2?'workspaceload':<></>}>
            {
                GlobalState.loadsegmentations==1?
                <Loading text = {textloading}/>
                :
                GlobalState.loadsegmentations==2?
                <LoadingCompleted text = {textloadingcompleated}/>
                :
                <div className="segment" >
                    <div className='header-segment'>
                        {/* <Slider {...settings}> */}
                            <div className='first_line_sliders'>
                                {/* <div className='demo_workspace'>
                                    Demo
                                </div> */}
                                <div className={GlobalState.activedatasetSegment.nameSegment!==""?'createbut active':'createbut'} onClick={()=>changepopupworkspace()}>
                                    
                                    
                                    {
                                        GlobalState.activedatasetSegment.nameSegment===""?
                                        <svg className='hoverarrowcreate' width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path className='hoverarrowcreatepath' d="M39.1883 18.5664C39.5455 19.1852 39.5455 19.9476 39.1883 20.5664L30.593 35.4539C30.2357 36.0727 29.5755 36.4539 28.8609 36.4539L11.6703 36.4539C10.9558 36.4539 10.2955 36.0727 9.93827 35.4539L1.34297 20.5664C0.985709 19.9476 0.985709 19.1852 1.34297 18.5664L9.93827 3.67891C10.2955 3.06011 10.9558 2.67891 11.6703 2.67891L28.8609 2.67891C29.5755 2.67891 30.2357 3.06011 30.593 3.67891L39.1883 18.5664Z" fill="#F5C538" stroke="#F5C538"/>
                                            <path d="M20.7656 10.7539V27.3789" stroke="#020203" stroke-linecap="round"/>
                                            <path d="M12.4531 19.0664H29.0781" stroke="#020203" stroke-linecap="round"/>
                                        </svg>
                                        :
                                        <></>
                                    }
                                    {GlobalState.activedatasetSegment.nameSegment!==""?GlobalState.activedatasetSegment.nameSegment:"Create Workspace"}
                                    {
                                        GlobalState.activedatasetSegment.nameSegment!==""?
                                        <svg className='arowhov' style={{marginLeft: "19px", opacity: "0.6"}} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.2636 7.14645C17.5786 7.46143 18.1172 7.23835 18.1172 6.79289V0H11.3243C10.8788 0 10.6558 0.538571 10.9707 0.853553L13.4072 3.29L3.40719 13.29L0.970742 10.8536C0.655759 10.5386 0.117188 10.7617 0.117188 11.2071V18H6.91008C7.35553 18 7.57862 17.4614 7.26363 17.1464L4.82719 14.71L14.8272 4.71L17.2636 7.14645Z" fill="#1C1E20"/>
                                        </svg>
                                        :
                                        <></>
                                    }

                                </div>
                                {GlobalState.activedatasetSegment.nameSegment!==""?
                                    <svg  width="41" height="38" viewBox="0 0 41 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M39.5398 18.5C39.8971 19.1188 39.8971 19.8812 39.5398 20.5L30.9445 35.3875C30.5873 36.0063 29.927 36.3875 29.2125 36.3875L12.0219 36.3875C11.3074 36.3875 10.6471 36.0063 10.2898 35.3875L1.69454 20.5C1.33727 19.8812 1.33727 19.1188 1.69454 18.5L10.2898 3.6125C10.6471 2.9937 11.3074 2.6125 12.0219 2.6125L29.2125 2.6125C29.927 2.6125 30.5873 2.9937 30.9445 3.6125L39.5398 18.5Z" fill="#F4F5F7" stroke="#F4F5F7"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6172 10.6875C21.6172 10.4114 21.3933 10.1875 21.1172 10.1875C20.841 10.1875 20.6172 10.4114 20.6172 10.6875V18.5H12.8047C12.5285 18.5 12.3047 18.7239 12.3047 19C12.3047 19.2761 12.5285 19.5 12.8047 19.5H20.6172V27.3125C20.6172 27.5886 20.841 27.8125 21.1172 27.8125C21.3933 27.8125 21.6172 27.5886 21.6172 27.3125V19.5H29.4297C29.7058 19.5 29.9297 19.2761 29.9297 19C29.9297 18.7239 29.7058 18.5 29.4297 18.5H21.6172V10.6875Z" fill="#020203" fill-opacity="0.2"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6172 10.6875C21.6172 10.4114 21.3933 10.1875 21.1172 10.1875C20.841 10.1875 20.6172 10.4114 20.6172 10.6875V18.5H12.8047C12.5285 18.5 12.3047 18.7239 12.3047 19C12.3047 19.2761 12.5285 19.5 12.8047 19.5H20.6172V27.3125C20.6172 27.5886 20.841 27.8125 21.1172 27.8125C21.3933 27.8125 21.6172 27.5886 21.6172 27.3125V19.5H29.4297C29.7058 19.5 29.9297 19.2761 29.9297 19C29.9297 18.7239 29.7058 18.5 29.4297 18.5H21.6172V10.6875Z" fill="black" fill-opacity="0.2"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6172 10.6875C21.6172 10.4114 21.3933 10.1875 21.1172 10.1875C20.841 10.1875 20.6172 10.4114 20.6172 10.6875V18.5H12.8047C12.5285 18.5 12.3047 18.7239 12.3047 19C12.3047 19.2761 12.5285 19.5 12.8047 19.5H20.6172V27.3125C20.6172 27.5886 20.841 27.8125 21.1172 27.8125C21.3933 27.8125 21.6172 27.5886 21.6172 27.3125V19.5H29.4297C29.7058 19.5 29.9297 19.2761 29.9297 19C29.9297 18.7239 29.7058 18.5 29.4297 18.5H21.6172V10.6875Z" fill="black" fill-opacity="0.2"/>
                                    </svg>
                                    :
                                    <></>
                                }
                            </div>
                            <div className='border_line'>
                                <div className='openpopdeploy_tooltipe' onClick={()=>changepopupDeployment0()}>
                                    {
                                        mass1.length>0||mass2.length>0?
                                        <span className='hover_n'>
                                            <svg className='def_download' width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M31.4226 15.3232C31.7799 15.942 31.7799 16.7044 31.4226 17.3232L24.5773 29.1796C24.2201 29.7985 23.5598 30.1796 22.8453 30.1796L9.1547 30.1796C8.44017 30.1796 7.77991 29.7985 7.42265 29.1796L0.57735 17.3232C0.220084 16.7044 0.220084 15.942 0.57735 15.3232L7.42265 3.46683C7.77991 2.84803 8.44017 2.46683 9.1547 2.46683L22.8453 2.46684C23.5598 2.46684 24.2201 2.84803 24.5774 3.46684L31.4226 15.3232Z" fill="#F5C538"/>
                                                <path d="M16 19.3892L13.8207 17.2093C13.5058 16.8943 13.7289 16.3558 14.1743 16.3558H15.5714V10.3232H16.4286V16.3558H17.8257C18.2711 16.3558 18.4942 16.8943 18.1793 17.2093L16 19.3892ZM10 22.3232V21.4661H10.8571H21.1429H22V22.3232H10Z" fill="#020203"/>
                                                <path d="M16 19.3892L13.8207 17.2093C13.5058 16.8943 13.7289 16.3558 14.1743 16.3558H15.5714V10.3232H16.4286V16.3558H17.8257C18.2711 16.3558 18.4942 16.8943 18.1793 17.2093L16 19.3892ZM10 22.3232V21.4661H10.8571H21.1429H22V22.3232H10Z" fill="black" fill-opacity="0.2"/>
                                                <path d="M16 19.3892L13.8207 17.2093C13.5058 16.8943 13.7289 16.3558 14.1743 16.3558H15.5714V10.3232H16.4286V16.3558H17.8257C18.2711 16.3558 18.4942 16.8943 18.1793 17.2093L16 19.3892ZM10 22.3232V21.4661H10.8571H21.1429H22V22.3232H10Z" fill="black" fill-opacity="0.2"/>
                                            </svg>
                                            <svg className='hov_download'  width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M31.4226 15.3232C31.7799 15.942 31.7799 16.7044 31.4226 17.3232L24.5773 29.1796C24.2201 29.7985 23.5598 30.1796 22.8453 30.1796L9.1547 30.1796C8.44017 30.1796 7.77991 29.7985 7.42265 29.1796L0.57735 17.3232C0.220084 16.7044 0.220084 15.942 0.57735 15.3232L7.42265 3.46683C7.77991 2.84803 8.44017 2.46683 9.1547 2.46683L22.8453 2.46684C23.5598 2.46684 24.2201 2.84803 24.5774 3.46684L31.4226 15.3232Z" fill="#41A8B1"/>
                                                <path d="M16 19.3892L13.8207 17.2093C13.5058 16.8943 13.7289 16.3558 14.1743 16.3558H15.5714V10.3232H16.4286V16.3558H17.8257C18.2711 16.3558 18.4942 16.8943 18.1793 17.2093L16 19.3892ZM10 22.3232V21.4661H10.8571H21.1429H22V22.3232H10Z" fill="#020203"/>
                                                <path d="M16 19.3892L13.8207 17.2093C13.5058 16.8943 13.7289 16.3558 14.1743 16.3558H15.5714V10.3232H16.4286V16.3558H17.8257C18.2711 16.3558 18.4942 16.8943 18.1793 17.2093L16 19.3892ZM10 22.3232V21.4661H10.8571H21.1429H22V22.3232H10Z" fill="black" fill-opacity="0.2"/>
                                                <path d="M16 19.3892L13.8207 17.2093C13.5058 16.8943 13.7289 16.3558 14.1743 16.3558H15.5714V10.3232H16.4286V16.3558H17.8257C18.2711 16.3558 18.4942 16.8943 18.1793 17.2093L16 19.3892ZM10 22.3232V21.4661H10.8571H21.1429H22V22.3232H10Z" fill="black" fill-opacity="0.2"/>
                                            </svg>
                                        </span>
                                        :
                                       
                                        <span className='hover_n'>
                                            <svg  className='def_download' width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M32.4226 15.3232C32.7799 15.942 32.7799 16.7044 32.4226 17.3232L25.5773 29.1796C25.2201 29.7985 24.5598 30.1796 23.8453 30.1796L10.1547 30.1796C9.44017 30.1796 8.77991 29.7985 8.42265 29.1796L1.57735 17.3232C1.22008 16.7044 1.22008 15.942 1.57735 15.3232L8.42265 3.46683C8.77991 2.84803 9.44017 2.46683 10.1547 2.46683L23.8453 2.46684C24.5598 2.46684 25.2201 2.84803 25.5774 3.46684L32.4226 15.3232Z" fill="#F4F5F7" stroke="#F4F5F7"/>
                                                <path d="M17 19.3892L14.8207 17.2093C14.5058 16.8943 14.7289 16.3558 15.1743 16.3558H16.5714V10.3232H17.4286V16.3558H18.8257C19.2711 16.3558 19.4942 16.8943 19.1793 17.2093L17 19.3892ZM11 22.3232V21.4661H11.8571H22.1429H23V22.3232H11Z" fill="#020203"/>
                                                <path d="M17 19.3892L14.8207 17.2093C14.5058 16.8943 14.7289 16.3558 15.1743 16.3558H16.5714V10.3232H17.4286V16.3558H18.8257C19.2711 16.3558 19.4942 16.8943 19.1793 17.2093L17 19.3892ZM11 22.3232V21.4661H11.8571H22.1429H23V22.3232H11Z" fill="black" fill-opacity="0.2"/>
                                                <path d="M17 19.3892L14.8207 17.2093C14.5058 16.8943 14.7289 16.3558 15.1743 16.3558H16.5714V10.3232H17.4286V16.3558H18.8257C19.2711 16.3558 19.4942 16.8943 19.1793 17.2093L17 19.3892ZM11 22.3232V21.4661H11.8571H22.1429H23V22.3232H11Z" fill="black" fill-opacity="0.2"/>
                                            </svg>
                                            <svg className='hov_download'  width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M31.4226 15.3232C31.7799 15.942 31.7799 16.7044 31.4226 17.3232L24.5773 29.1796C24.2201 29.7985 23.5598 30.1796 22.8453 30.1796L9.1547 30.1796C8.44017 30.1796 7.77991 29.7985 7.42265 29.1796L0.57735 17.3232C0.220084 16.7044 0.220084 15.942 0.57735 15.3232L7.42265 3.46683C7.77991 2.84803 8.44017 2.46683 9.1547 2.46683L22.8453 2.46684C23.5598 2.46684 24.2201 2.84803 24.5774 3.46684L31.4226 15.3232Z" fill="#41A8B1"/>
                                                <path d="M16 19.3892L13.8207 17.2093C13.5058 16.8943 13.7289 16.3558 14.1743 16.3558H15.5714V10.3232H16.4286V16.3558H17.8257C18.2711 16.3558 18.4942 16.8943 18.1793 17.2093L16 19.3892ZM10 22.3232V21.4661H10.8571H21.1429H22V22.3232H10Z" fill="#020203"/>
                                                <path d="M16 19.3892L13.8207 17.2093C13.5058 16.8943 13.7289 16.3558 14.1743 16.3558H15.5714V10.3232H16.4286V16.3558H17.8257C18.2711 16.3558 18.4942 16.8943 18.1793 17.2093L16 19.3892ZM10 22.3232V21.4661H10.8571H21.1429H22V22.3232H10Z" fill="black" fill-opacity="0.2"/>
                                                <path d="M16 19.3892L13.8207 17.2093C13.5058 16.8943 13.7289 16.3558 14.1743 16.3558H15.5714V10.3232H16.4286V16.3558H17.8257C18.2711 16.3558 18.4942 16.8943 18.1793 17.2093L16 19.3892ZM10 22.3232V21.4661H10.8571H21.1429H22V22.3232H10Z" fill="black" fill-opacity="0.2"/>
                                            </svg>
                                        </span>

                                        
                                    }
                                    
                                    {
                                        mass1.length==0&&mass2.length==0?
                                        <div className='tooltipe'>
                                            {langv?.segmentation?.tooltipe}
                                        </div>
                                        :
                                        <></>
                                    }
                                    
                                </div>
                            </div>
                            
                        {/* </Slider> */}
                    </div>
                    <div className='body-segment'>
                        
                            {GlobalState.activedatasetSegment.id==""?
                                <>
                                    <div className='add-segment-but'>
                                        <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M32.4226 15.5181C32.7799 16.1369 32.7799 16.8993 32.4226 17.5181L25.5773 29.3745C25.2201 29.9933 24.5598 30.3745 23.8453 30.3745L10.1547 30.3745C9.44017 30.3745 8.77991 29.9933 8.42265 29.3745L1.57735 17.5181C1.22008 16.8993 1.22008 16.1369 1.57735 15.5181L8.42265 3.66166C8.77991 3.04286 9.44017 2.66166 10.1547 2.66166L23.8453 2.66166C24.5598 2.66166 25.2201 3.04286 25.5774 3.66166L32.4226 15.5181Z" fill="#F4F5F7" stroke="#F4F5F7"/>
                                            <g opacity="0.15">
                                            <path d="M17 9.51807V23.5181" stroke="#020203" stroke-linecap="round"/>
                                            <path d="M10 16.5181H24" stroke="#020203" stroke-linecap="round"/>
                                            </g>
                                        </svg>
                                        {langv?.segmentation?.button_addnewlogic}
                                    </div>
                                </>
                            :
                                <>
                                    <div className='actual_segments'>
                                            <div className='title_segments_index'>
                                                {/* <div className='title_segm ts1'>
                                                    Segmentation logic
                                                </div> */}
                                                <div className='title_segm ts2'>
                                                    {langv?.segmentation?.actual_segments?.text1}
                                                </div>
                                                <div className='title_segm ts2'>
                                                    {langv?.segmentation?.actual_segments?.text2}
                                                </div>
                                                <div className='title_segm ts2'>
                                                    {langv?.segmentation?.actual_segments?.text3}
                                                </div>
                                            </div>
                                            {
                                                
                                                Object.entries(GlobalState.activedatasetSegment?.segmentations?.cat).map((key, value)=>
                                                    <div className={mass1.includes(key[0])?'line_segment_actual active':'line_segment_actual'}>
                                                        <div className='icon_check' onClick={()=>checksegments(key[0])}>
                                                            {
                                                                mass1.includes(key[0])?
                                                                <svg className='first_s_icon' width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="9" cy="9.5" r="4" fill="#33A753"/>
                                                                    <path d="M8 1.07735C8.6188 0.720085 9.3812 0.720085 10 1.07735L15.7942 4.42265C16.413 4.77992 16.7942 5.44017 16.7942 6.1547L16.7942 12.8453C16.7942 13.5598 16.413 14.2201 15.7942 14.5774L10 17.9226C9.3812 18.2799 8.6188 18.2799 8 17.9226L2.20577 14.5774C1.58697 14.2201 1.20577 13.5598 1.20577 12.8453L1.20577 6.1547C1.20577 5.44017 1.58697 4.77992 2.20577 4.42265L8 1.07735Z" stroke="#33A753" stroke-width="1.5"/>
                                                                </svg>
                                                                :
                                                                <svg className='first_s_icon' width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8 1.07735C8.6188 0.720085 9.3812 0.720085 10 1.07735L15.7942 4.42265C16.413 4.77992 16.7942 5.44017 16.7942 6.1547L16.7942 12.8453C16.7942 13.5598 16.413 14.2201 15.7942 14.5774L10 17.9226C9.3812 18.2799 8.6188 18.2799 8 17.9226L2.20577 14.5774C1.58697 14.2201 1.20577 13.5598 1.20577 12.8453L1.20577 6.1547C1.20577 5.44017 1.58697 4.77992 2.20577 4.42265L8 1.07735Z" stroke="#1C1E20" stroke-width="1.5"/>
                                                                </svg>

                                                            }
                                                            
                                                        </div>
                                                        <div className='icon_check' onClick={()=>openpopupsegments(key[0])}>
                                                            <svg className='second_s_icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g opacity="0.6">
                                                                <path d="M20.1464 10.1464C20.4614 10.4614 21 10.2383 21 9.79289V3H14.2071C13.7617 3 13.5386 3.53857 13.8536 3.85355L16.29 6.29L6.29 16.29L3.85355 13.8536C3.53857 13.5386 3 13.7617 3 14.2071V21H9.79289C10.2383 21 10.4614 20.4614 10.1464 20.1464L7.71 17.71L17.71 7.71L20.1464 10.1464Z" fill="#1C1E20"/>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <input
                                                            id="workspacename"
                                                            type="text"
                                                            placeholder="blank"
                                                            className="line_segment_actual_input ts02"
                                                            value={key[0]}
                                                            disabled="true"
                                                        />
                                                        <input
                                                            id="workspacename"
                                                            type="text"
                                                            placeholder="blank"
                                                            className="line_segment_actual_input ts02"
                                                            value={GlobalState.dataworksektion.split('.')[0].split('T')[0] + " " + GlobalState.dataworksektion.split('.')[0].split('T')[1]}
                                                            disabled="true"
                                                        />
                                                        <input
                                                            id="workspacename"
                                                            type="text"
                                                            placeholder="blank"
                                                            className="line_segment_actual_input ts02"
                                                            value={Object.keys(key[1]).length}
                                                            disabled="true"
                                                        />
                                                    </div>
                                                )  
                                            }
                                            {
                                                Object.entries(GlobalState.activedatasetSegment?.segmentations?.num).map((key, id)=>
                                                    <div className={mass2.includes(key[0])?'line_segment_actual active':'line_segment_actual'}>
                                                        <div className='icon_check' onClick={()=>checksegments2(key[0])}>
                                                            {
                                                                mass2.includes(key[0])?
                                                                <svg className='first_s_icon' width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="9" cy="9.5" r="4" fill="#33A753"/>
                                                                    <path d="M8 1.07735C8.6188 0.720085 9.3812 0.720085 10 1.07735L15.7942 4.42265C16.413 4.77992 16.7942 5.44017 16.7942 6.1547L16.7942 12.8453C16.7942 13.5598 16.413 14.2201 15.7942 14.5774L10 17.9226C9.3812 18.2799 8.6188 18.2799 8 17.9226L2.20577 14.5774C1.58697 14.2201 1.20577 13.5598 1.20577 12.8453L1.20577 6.1547C1.20577 5.44017 1.58697 4.77992 2.20577 4.42265L8 1.07735Z" stroke="#33A753" stroke-width="1.5"/>
                                                                </svg>
                                                                :
                                                                <svg className='first_s_icon' width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8 1.07735C8.6188 0.720085 9.3812 0.720085 10 1.07735L15.7942 4.42265C16.413 4.77992 16.7942 5.44017 16.7942 6.1547L16.7942 12.8453C16.7942 13.5598 16.413 14.2201 15.7942 14.5774L10 17.9226C9.3812 18.2799 8.6188 18.2799 8 17.9226L2.20577 14.5774C1.58697 14.2201 1.20577 13.5598 1.20577 12.8453L1.20577 6.1547C1.20577 5.44017 1.58697 4.77992 2.20577 4.42265L8 1.07735Z" stroke="#1C1E20" stroke-width="1.5"/>
                                                                </svg>
                                                            }
                                                            
                                                        </div>
                                                        <div className='icon_check' onClick={()=>openpopupsegments(key[0])}>
                                                            <svg className='second_s_icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g opacity="0.6">
                                                                <path d="M20.1464 10.1464C20.4614 10.4614 21 10.2383 21 9.79289V3H14.2071C13.7617 3 13.5386 3.53857 13.8536 3.85355L16.29 6.29L6.29 16.29L3.85355 13.8536C3.53857 13.5386 3 13.7617 3 14.2071V21H9.79289C10.2383 21 10.4614 20.4614 10.1464 20.1464L7.71 17.71L17.71 7.71L20.1464 10.1464Z" fill="#1C1E20"/>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <input
                                                            id="workspacename"
                                                            type="text"
                                                            placeholder="blank"
                                                            className="line_segment_actual_input ts02"
                                                            value={key[0]}
                                                            disabled="true"
                                                        />
                                                        <input
                                                            id="workspacename"
                                                            type="text"
                                                            placeholder="blank"
                                                            className="line_segment_actual_input ts02"
                                                            value={GlobalState.dataworksektion.split('.')[0].split('T')[0] + " " + GlobalState.dataworksektion.split('.')[0].split('T')[1]}
                                                            disabled="true"
                                                        />
                                                        <input
                                                            id="workspacename"
                                                            type="text"
                                                            placeholder="blank"
                                                            className="line_segment_actual_input ts02"
                                                            value={Object.keys(key[1]).length}
                                                            disabled="true"
                                                        />
                                                    </div>
                                                )  
                                            }

                                            
                                    </div>
                                    <div className='add-segment-but' style={{color:"rgba(2, 2, 3, 0.15)", cursor:"default"}} >
                                        <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M32.4226 15C32.7799 15.6188 32.7799 16.3812 32.4226 17L25.5773 28.8564C25.2201 29.4752 24.5598 29.8564 23.8453 29.8564L10.1547 29.8564C9.44017 29.8564 8.77991 29.4752 8.42265 28.8564L1.57735 17C1.22008 16.3812 1.22008 15.6188 1.57735 15L8.42265 3.14359C8.77991 2.52479 9.44017 2.14359 10.1547 2.14359L23.8453 2.14359C24.5598 2.14359 25.2201 2.52479 25.5774 3.14359L32.4226 15Z" fill="#F4F5F7" stroke="#F4F5F7"/>
                                            <g opacity="0.15">
                                            <path d="M17 9V23" stroke="#020203" stroke-linecap="round"/>
                                            <path d="M17 9V23" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                            <path d="M17 9V23" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                            <path d="M10 16H24" stroke="#020203" stroke-linecap="round"/>
                                            <path d="M10 16H24" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                            <path d="M10 16H24" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                            </g>
                                        </svg>


                                        {langv?.segmentation?.button_addnewlogic}
                                    </div>
                                </>
                            }
                        
                    </div>
                </div>
            }
        </div>
    );
})

export default Sidebar;
