import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react'
import Select, { components } from 'react-select'
import './index.css';
import GlobalState from "../../../stores/GlobalState";
import lang from "../../../lang"
import {Bar} from "react-chartjs-2"
import {Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend, layouts, scales} from 'chart.js'
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const TopField = observer(() => {

    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [obid, setObid]= useState("")
    const [load, setLoad]= useState(1)
    const [coef, setcoef]= useState(1)
    const [leftz, setLeftz]= useState(1)

    const [firstline, setfirstline]= useState("")
    const [lastline, setlastline]= useState("")
    const [prom1line, setprom1line]= useState("")
    const [prom2line, setprom2line]= useState("")

    const [dataChartId, setDataChartId]= useState([])
    

    const [countline, setCountline]= useState(0)

    var first = 0;
    var flag = 0;
    var last = 0;

    var prom1 = 0;
    var prom2 = 0;
    var y = 0;

    var flagside = 1;
    const [f, setF] = useState(0);

    Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.metricImportance||[]).sort(([,a],[,b]) => a-b).reverse().map(([key, value])=>(
        flag==0?
        <>
            {
                first = value
            }
            {
                last=value
            }
            {
                flag=1
            }
        </>
        :
        last=value
    ))
    var step = 0;
    if(last<0)
    {
        step = (first + ((-1)*last))*0.33;
        prom1 = last + step;
        prom2 = last + 2*step;
        y = (-1)*last+first;

        if(first<(last*-1))
        {
            flagside = 0;
        }
    }
    else
    {
        step = (first + ((-1)*last))*0.33;
        prom1 = last + step;
        prom2 = last + 2*step;
        y = first-last;
    }

    const getcoef = async() =>{
        // first = 0;
        // flag = 0;
        // last = 0;
        // prom1 = 0;
        // prom2 = 0;
        // y = 0;
        // flagside = 1;
        // Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.predictPreview||[]).map(([key, value])=>
        //     key==obid?
        //     Object.entries(value).sort(([,a],[,b]) => b-a).map(([key2, value2])=>
        //         key2.includes("impact")?
        //             flag==0?
        //             <>
        //                 {
        //                     first = value2
        //                 }
        //                 {
        //                     setfirstline(first.toFixed(2))
        //                 }
        //                 {
        //                     last=value2
        //                 }
        //                 {
        //                     setlastline(last.toFixed(2))
        //                 }
        //                 {
        //                     flag=1
        //                 }
        //             </>
        //             :
        //             <>
        //             {
        //                 last=value2
        //             }
        //             {
        //                 setlastline(last.toFixed(2))
        //             }
        //             </>
                    
        //         :<></>  
        //     )
        //     :
        //     <></>
        // )
        // var step = 0;
        // if(last<0)
        // {
        //     step = (first + ((-1)*last))*0.33;
        //     prom1 = last + step;
        //     prom2 = last + 2*step;
        //     setprom1line(prom1.toFixed(2))
        //     setprom2line(prom2.toFixed(2))
        //     y = (-1)*last+first;

        //     if(first<(last*-1))
        //     {
        //         flagside = 0;
        //     }
        // }
        // else
        // {
        //     step = (first + ((-1)*last))*0.33;
        //     prom1 = last + step;
        //     prom2 = last + 2*step;
        //     setprom1line(prom1.toFixed(2))
        //     setprom2line(prom2.toFixed(2))
        //     y = first-last;
        // }

        
        // var left = (last/y*100).toFixed(0);
        // setLeftz(left)
        // if(left<0)
        // {
        //     left = left*-1
        //     setLeftz(left)
        // }
        // var right = 100 - left;
        
        // if(first>last)
        // {
        //     setcoef(right/first)
        // }
        // else
        // {
        //     setcoef(left/last)
        // }

        var labels = [];
        var datas = [];

        Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.predictPreview||[]).map(([key, value])=>
            key==obid?
            Object.entries(value).sort(([,a],[,b]) => b-a).map(([key2, value2])=>
                key2.includes("impact")?
                
                labels.push(key2?.replaceAll("impact_", "")+" ("+value2.toFixed(2)+")")
                :<></>  
            )
            :
            <></>
        )
        Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.predictPreview||[]).map(([key, value])=>
            key==obid?
            Object.entries(value).sort(([,a],[,b]) => b-a).map(([key2, value2])=>
                key2.includes("impact")?
                
                datas.push(value2.toFixed(2))
                :<></>  
            )
            :
            <></>
        )
        
    
        const dataChart = {
            labels: labels,
            datasets:[
                {
                    data: datas,
                    backgroundColor: 'rgba(66, 133, 244, 1)',
                    color: "rgba(2, 2, 3, 1)",
                    height:20
                }
            ]
        };
        
        setDataChartId(dataChart)
       
    }
    useEffect(() => {
        if(f==0)
        {
            var left = (last/y*100).toFixed(0);
            setLeftz(left)
            if(left<0)
            {
                left = left*-1
                setLeftz(left)
            }
            var right = 100 - left;
            if(first>last)
            {
                setcoef(right/first)
            }
            else
            {
                setcoef(left/last)
            }   
        }
        else{
            getcoef();
        }        
        setCountline(Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.metricImportance||[]).sort(([,a],[,b]) => a-b).reverse().length)
    }, []);
    useEffect(() => {
        if(f==0)
        {
            var left = (last/y*100).toFixed(0);
            setLeftz(left)
            if(left<0)
            {
                left = left*-1
                setLeftz(left)
            }
            var right = 100 - left;
            if(first>last)
            {
                setcoef(right/first)
            }
            else
            {
                setcoef(left/last)
            }   
        }
        else{
            getcoef();
        }        
        
    }, [obid]);
    var labels = [];
    var datas = [];
    Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.metricImportance||[]).sort(([,a],[,b]) => a-b).reverse().map(([key, value])=>
        labels.push(key?.replaceAll("impact_", "")+" ("+value.toFixed(2)+")")
    )
    Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.metricImportance||[]).sort(([,a],[,b]) => a-b).reverse().map(([key, value])=>
        datas.push(value.toFixed(2))
    )
    
    const dataChart = {
        labels: labels,
        datasets:[
            {
                data: datas,
                backgroundColor: 'rgba(66, 133, 244, 1)',
                color: "rgba(2, 2, 3, 1)",
                height:20
            }
        ]
    };
    const optionsChart = {
        indexAxis: 'y',
        layout:{
            padding: 20
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            }
        },
        scales:{
            x:{
                grid:{
                    drawOnChartArea: false
                }
            },
            y:{
                grid:{
                    drawOnChartArea: false
                },
                ticks:{
                    color:"black"
                }
            }
        },
        tooltips: {
            callbacks: {
               label: function(tooltipItem) {
                      return tooltipItem.yLabel;
               }
            }
        }
    };


    let options = [{value: "total", label: "Total"}];
    Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.predictPreview||[]).reverse().map(([key, value])=>

        Object.entries(value).map(([key2, value2])=>
       
        key2==GlobalState.activemodel?.lastTrain?.objectId?
        options =[ ...options,  {value: key, label: (value2)}]
        :
        <></>
        )
    )

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            display: 'flex',
            flexWrap: 'nowrap',
            width: '222px',
            height: '38px',
            color: 'rgba(2, 2, 3, 0.6)',
            border: "1px solid rgba(244, 245, 247, 1)",
            background: "rgba(255, 255, 255, 1)",
            marginTop:"10px",
            marginBottom:"10px",
            boxShadow: 'none',
            '&:hover': {
                border: '1px solid rgba(245, 197, 56, 1)',
            },
            '&:focus': {
                border: "1px solid rgba(244, 245, 247, 1)",
            }
        }),
        
        menu: (provided) => ({
            ...provided,
            background: "rgba(255, 255, 255, 1)",
            border: "1px solid rgba(244, 245, 247, 1)",
            color: 'rgba(2, 2, 3, 0.6)',
            width: '222px',
            marginTop: '-7px',
            boxShadow: 'none',
            cursor: 'pointer'
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 12,
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'rgba(2, 2, 3, 0.6)',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
    };
    const DropdownIndicator = props => {
        return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {
                props.selectProps.menuIsOpen ? 
                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="#020203"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="black" fill-opacity="0.2"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="black" fill-opacity="0.2"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="#020203"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="black" stroke-opacity="0.2"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="black" stroke-opacity="0.2"/>
                    </svg>
                    : 
                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="#020203"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="black" fill-opacity="0.2"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="black" fill-opacity="0.2"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="#020203"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="black" stroke-opacity="0.2"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="black" stroke-opacity="0.2"/>
                    </svg>
                }
            </components.DropdownIndicator>
        )
        );
    };
    const handlecompaings = (e) => {
        setLoad(0)
        if(e=="total")
        {
            setObid("")
            setF(0)
        }
        else
        {
            setObid(e)
            setF(1)
        }
        
        
        setTimeout(function(){
            setLoad(1);
        }, 100)
    };

    return (
        <div className='topfield_block tfb2'>
            
            <div className='contop_block_right'>
                <div className='contop_block_title'>
                {langv?.module_models?.metric?.text1}
                </div>
                <div className='linefilter'>
                    <span className='obidspan'>{langv?.module_models?.metric?.text4}</span>
                    <Select 
                        components={{ DropdownIndicator }}
                        options={options} 
                        styles={customStyles} 
                        classNamePrefix='filter'
                        className='filterobid'
                        isSearchable={false} 
                        placeholder={<div className='placefilter'>{langv?.module_models?.metric?.text5}</div>}
                        onChange={(option) => handlecompaings(option.value)}
                    />
                </div>
                <div className='centr_metrx'>
                    {
                        load==1?
                        obid==""?
                            <>
                                <div className='contop_block_right_body' style={countline<15?{height:"500px"}:{height:"800px"}}>
                                    {/* <div className='top_rotate_texta'>
                                        <div>
                                        {langv?.module_models?.metric?.text2}
                                        </div>
                                    </div>
                                    
                                    <div className='contop_block_right_body_line_1 iu' style={{minWidth:"330px"}}>
                                        {
                                            Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.metricImportance||[]).sort(([,a],[,b]) => a-b).reverse().map(([key, value])=>
                                                <div className='contop_block_right_body_line'>
                                                    {key?.replaceAll("impact_", "")}
                                                </div>
                                            )
                                        }
                    

                                    </div>
                                    <div className={y<=10?flagside==0?'contop_block_right_body_line_2 metricright minmetricright':'contop_block_right_body_line_2 metricright':'contop_block_right_body_line_2'} style={{ width:"100%", paddingRight: "60px"}}>
                                        <div className='graphick' style={{position:"relative", left:leftz+"%"}}>
                                        {
                                            Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.metricImportance||[]).sort(([,a],[,b]) => a-b).reverse().map(([key, value])=>
                                                <div className='contop_block_right_body_line zert'>
                                                    {
                                                        value>0?
                                                        <>
                                                            
                                                            <div style={{width: ((coef)*value).toFixed(0)+"%"}}></div>
                                                            <span>{value.toFixed(2)}</span>
                                                        </>
                                                        :
                                                        <>
                                                            <div className='minusblock' style={{width: ((-1*coef)*value).toFixed(0)+"%",  left: ((coef)*value).toFixed(0)+"%"}}>

                                                            </div>
                                                            <span className='padleft'>{value.toFixed(2)}</span>
                                                        </>
                                                    }
                                                    
                                                </div>
                                            )
                                        }
                                        </div>
                                        
                                    </div> */}
                                    <Bar data={dataChart} options={optionsChart}/>
                                   
                                </div>
                                {/* <div style={{minWidth:"100%", display: "flex", justifyContent:"center"}}>
                                    <div className='contop_block_right_body treya'>
                                        <div className='top_rotate_texta'></div>
                                        <div className='contop_block_right_body_line_1 iu'></div>
                                        <div className='contop_block_right_body_line_2 newlineci'>
                                            <span>
                                                {
                                                    last.toFixed(2)
                                                }
                                            </span>
                                            <span>
                                                {
                                                    prom1.toFixed(1)
                                                }
                                            </span>
                                            <span>
                                                {
                                                    prom2.toFixed(1)
                                                }
                                            </span>
                                            <span>
                                                {
                                                    first.toFixed(2)
                                                }
                                                
                                            </span>
                                        </div>
                                    </div>
                                </div> */}
                            </>
                            :
                            <>
                                <div className='contop_block_right_body' style={countline<15?{height:"500px"}:{height:"800px"}}>
                                    <Bar data={dataChartId} options={optionsChart}/>
                                </div>
                                {/* <div className='contop_block_right_body' >
                                    <div className='top_rotate_texta'>
                                        <div>
                                        {langv?.module_models?.metric?.text2}
                                        </div>
                                    </div>
                                    
                                    <div className='contop_block_right_body_line_1 iu' style={{minWidth:"330px"}}>
                                        {
                                            Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.predictPreview||[]).map(([key, value])=>
                                                key==obid?
                                                Object.entries(value).sort(([,a],[,b]) => b-a).map(([key2, value2])=>
                                                    key2.includes("impact")?
                                                    <div className='contop_block_right_body_line'>
                                                         {key2?.replaceAll("impact_", "")}
                                                    </div>
                                                    :<></>  
                                                )
                                                :
                                                <></>
                                            )
                                        }
                    

                                    </div>
                                    <div className={y<=10?flagside==0?'contop_block_right_body_line_2 metricright minmetricright':'contop_block_right_body_line_2 metricright':'contop_block_right_body_line_2'} style={{ width:"100%", paddingRight: "60px"}}>
                                        <div className='graphick' style={{position:"relative", left:leftz+"%"}}>
                                        {
                                            Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.predictPreview||[]).map(([key, value])=>
                                                key==obid?
                                                Object.entries(value).sort(([,a],[,b]) => b-a).map(([key2, value2])=>
                                                    key2.includes("impact")?
                                                    <div className='contop_block_right_body_line zert'>
                                                        {
                                                            value2>0?
                                                            <>
                                                                <div style={{width: ((coef)*value2).toFixed(0)+"%"}}></div>
                                                                <span>{value2.toFixed(2)}</span>
                                                            </>
                                                            :
                                                            <>
                                                                <div className='minusblock' style={{width: ((-1*coef)*value2).toFixed(0)+"%",  left: ((coef)*value2).toFixed(0)+"%"}}>

                                                                </div>
                                                                <span className='padleft'>{value2.toFixed(2)}</span>
                                                            </>
                                                        }
                                                    </div>
                                                    :<></>
                                                )
                                                :
                                                <></>
                                            )
                                        }
                                        </div>
                                    </div>
                                </div>
                                <div style={{minWidth:"100%", display: "flex", justifyContent:"center",}}>
                                    <div className='contop_block_right_body treya'>
                                        <div className='top_rotate_texta'></div>
                                        <div className='contop_block_right_body_line_1 iu'></div>
                                        <div className='contop_block_right_body_line_2 newlineci'>
                                            <span>
                                                {
                                                    lastline!=""?lastline:last.toFixed(2)
                                                }
                                            </span>
                                            <span>
                                                {
                                                    prom1line!=""?prom1line:prom1.toFixed(2)
                                              
                                                }
                                            </span>
                                            <span>
                                                {
                                                    prom2line!=""?prom2line:prom2.toFixed(2)
                                                  
                                                }
                                            </span>
                                            <span>
                                                {
                                                    firstline!=""?firstline:first.toFixed(2)
                                                  
                                                }
                                                
                                            </span>
                                        </div>
                                    </div>
                                </div> */}
                            </>
                        :
                        <div className='emptymetrix'></div>
                    }
                    <div className='contop_block_right_body treya yutr' style={{minWidth:"100%", height:"20px"}}>
                        {langv?.module_models?.metric?.text3}
                    </div>
                </div>
            </div>
        </div>
    )
})
export default TopField;