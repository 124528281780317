import React, {useState} from 'react';
import './SegmentationDatasetPage.css';
import { observer } from 'mobx-react'
import Sidebar from '../../components/sidebar/index.js';
import logoBig from '../../assets/logo-big.svg'
import Segment from '../../components/segmentation/index.js'
import Dataset from '../../components/dataset/index.js'
import PopupaddWorkspace from '../../components/segmentation/popupAddWorkspace.js';
import PopupaddSegmentation from '../../components/segmentation/popupAddSegmentation.js';
import GlobalState from "../../stores/GlobalState.js";
import PopupAddDataset from '../../components/dataset/popupAddDataset.js';
import PopupAttachment from '../../components/dataset/popupAttachment.js';
import PopupDeployment from '../../components/dataset/popupDeployment.js';
import PopupDeleteDataset from '../../components/dataset/popupDeleteDataset';

const Segmentation = observer(() => {
    const [holdBlock, setholdBlock] = useState(true);
    const [datasegment, setDatasegment] = useState(1);
    const changemenu = () =>{
        setholdBlock(false)
    }
    window.onbeforeunload = () => {
        setholdBlock(true)
    }
    return (
        <>
        <Sidebar/>
        <div className="segmentation hold">
           
            <div className='body_segment'>
                <Dataset/>
            </div>
        </div>
        {GlobalState.ispopupaddworkspaceShow?<PopupaddWorkspace />:<></>}
        {GlobalState.ispopupaddSegmentationShow?<PopupaddSegmentation/>:<></>}
        {GlobalState.ispopupadddatasetShow?<PopupAddDataset/>:<></>}
        {GlobalState.ispopupaddAttachmentShow?<PopupAttachment/>:<></>}
        {GlobalState.ispopupDeploymentShow?<PopupDeployment/>:<></>}
        {GlobalState.ispopupdeleteDatasetShow ?<PopupDeleteDataset id={GlobalState.ispopupdeletedatasetid} deleteIdnum = {GlobalState.ispopupdeleteatasetname}/>:<></>}
        </>
    );
})

export default Segmentation;

