import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import GlobalState from "../../../stores/GlobalState";
import lang from "../../../lang"
import {Bar} from "react-chartjs-2"
import {Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend, layouts, scales} from 'chart.js'
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const TopField = observer(() => {
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    var coef = 1;
    var first = 0;
    var flag = 0;
    var last = 0;
    Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields||[]).sort(([,a],[,b]) => a-b).reverse().slice(0,10).map(([key, value])=>
        flag==0?
        <>
            {
                first=value
            }
            {
                last=value
            }
            {
                flag=1
            }
        </>
        :
        last=value
    )
    coef = 100/first*0.8;

    var labels = [];
    var datas = [];
    Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields||[]).sort(([,a],[,b]) => a-b).reverse().slice(0,10).map(([key, value])=>
        labels.push(key?.replaceAll("impact_", "")+" ("+value.toFixed(2)+")")
    )
    Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields||[]).sort(([,a],[,b]) => a-b).reverse().slice(0,10).map(([key, value])=>
        datas.push(value.toFixed(2))
    )

    const dataChart = {
        labels: labels,
        datasets:[
            {
                data: datas,
                backgroundColor: 'rgba(66, 133, 244, 1)',
                color: "rgba(2, 2, 3, 1)",
                height:20
            }
        ]
    };
    const optionsChart = {
        indexAxis: 'y',
        layout:{
            padding: 20
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            }
        },
        scales:{
            x:{
                grid:{
                    drawOnChartArea: false
                }
            },
            y:{
                grid:{
                    drawOnChartArea: false
                },
                ticks:{
                    color:"black"
                }
            }
        },
        tooltips: {
            callbacks: {
               label: function(tooltipItem) {
                      return tooltipItem.yLabel;
               }
            }
        }
    };
    return (
        <div className='topfield_block'>
            
            <div className='contop_block_right' style={{display:"flex", flexWrap:"wrap", alignItems:"center",justifyContent: "center"}}>
                <div className='contop_block_title' style={{minWidth: "100%", paddingBottom:"30px"}}>
                    {langv?.module_models.topfield}
                </div>
                <div className='contop_block_right_body'>
                    {/* <div className='contop_block_right_body_line_1'>
                        {
                            Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields||[]).sort(([,a],[,b]) => a-b).reverse().slice(0,10).map(([key, value])=>
                                <div className='contop_block_right_body_line'>
                                    {key}
                                </div>
                            )
                        }
                        
                    </div>
                    <div className='contop_block_right_body_line_2' style={{minWidth:"100%", paddingLeft:"20px"}}>
                        {
                                        
                            Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields).sort(([,a],[,b]) => a-b).reverse().slice(0,10).map(([key, value])=>
                                <div className='contop_block_right_body_line'>
                                    <div style={{width: value*coef+"%"}}></div>
                                    <span>{value.toFixed(2)}</span>
                                </div>
                            )
                        }
                        
                    </div> */}
                    <Bar data={dataChart} options={optionsChart}/>
                </div>
            </div>
        </div>
    )
})
export default TopField;