import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import GlobalState from "../../../stores/GlobalState";
import lang from "../../../lang"
import {Bar} from "react-chartjs-2"
import {Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend, layouts, scales} from 'chart.js'
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Contop = observer(() => {
    var i1 = 0;
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)

    var coef = 1;
    var first = 0;
    var flag = 0;
    var last = 0;
    var otstup = "- "+((GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1)*8)+"px";
    var otstup2 = "- "+((GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) - 4)+"px";
    console.log(otstup)
    Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields||[]).sort(([,a],[,b]) => a-b).reverse().slice(0,10).map(([key, value])=>
        flag==0?
        <>
            {
                first=value
            }
            {
                last=value
            }
            {
                flag=1
            }
        </>
        :
        last=value
    )
    coef = 100/first*0.8;

    var minznach = 10000;
    var maxznach = 0;

    if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tn<minznach)
    {
        minznach=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tn
    }
    if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tn>maxznach)
    {
        maxznach=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tn
    }

    if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fn<minznach)
    {
        minznach=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fn
    }
    if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fn>maxznach)
    {
        maxznach=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fn
    }

    if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fp<minznach)
    {
        minznach=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fp
    }
    if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fp>maxznach)
    {
        maxznach=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fp
    }

    if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tp<minznach)
    {
        minznach=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tp
    }
    if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tp>maxznach)
    {
        maxznach=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tp
    }

    var period = (maxznach - minznach)/5;



    var minznachmulty = 10000;
    var maxznachmulty = 0;
    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets?.map((n,i)=>
        <>
        {
            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1]?.confusionMatrix[i]?.map((z, u)=>
                minznachmulty>z?(minznachmulty=z):<></>
            
            )
        }
        </>
    )
    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets?.map((n,i)=>
        <>
        {
            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1]?.confusionMatrix[i]?.map((z, u)=>
                maxznachmulty<z?(maxznachmulty=z):<></>
            )
        }
        </>
    )

    var periodmulty = (maxznachmulty - minznachmulty)/(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1);



    var minznach3 = 10000;
    var maxznach3 = 0;
    if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length>2)
    {
        if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[2].target==null)
        {}
        else
        {
            if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3].target==null)
            {
                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][0]||0<minznach3)
                {
                    minznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][0]
                }
                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][0]||0>maxznach3)
                {
                    maxznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][0]
                }

                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][1]||0<minznach3)
                {
                    minznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][1]
                }
                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][1]||0>maxznach3)
                {
                    maxznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][1]
                }

                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][2]||0<minznach3)
                {
                    minznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][2]
                }
                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][2]||0>maxznach3)
                {
                    maxznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][2]
                }


                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][0]||0<minznach3)
                {
                    minznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][0]
                }
                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][0]||0>maxznach3)
                {
                    maxznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][0]
                }


                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][1]||0<minznach3)
                {
                    minznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][1]
                }
                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][1]||0>maxznach3)
                {
                    maxznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][1]
                }


                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][2]||0<minznach3)
                {
                    minznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][2]
                }
                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][2]||0>maxznach3)
                {
                    maxznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][2]
                }


                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][0]||0<minznach3)
                {
                    minznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][0]
                }
                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][0]||0>maxznach3)
                {
                    maxznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][0]
                }


                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][1]<minznach3)
                {
                    minznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][1]
                }
                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][1]>maxznach3)
                {
                    maxznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][1]
                }


                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][2]<minznach3)
                {
                    minznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][2]
                }
                if(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][2]>maxznach3)
                {
                    maxznach3=GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][2]
                }
            }
        }
    }


    var period3 = (maxznach3 - minznach3)/5;

    var labels = [];
    var datas = [];
    Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields||[]).sort(([,a],[,b]) => a-b).reverse().map(([key, value])=>
        labels.push(key?.replaceAll("impact_", "")+" ("+value.toFixed(2)+")")
    )
    Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields||[]).sort(([,a],[,b]) => a-b).reverse().map(([key, value])=>
        datas.push(value.toFixed(2))
    )

    const dataChart = {
        labels: labels,
        datasets:[
            {
                data: datas,
                backgroundColor: 'rgba(66, 133, 244, 1)',
                color: "rgba(2, 2, 3, 1)",
                height:20
            }
        ]
    };
    const optionsChart = {
        indexAxis: 'y',
        layout:{
            padding: 20
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            }
        },
        scales:{
            x:{
                grid:{
                    drawOnChartArea: false
                }
            },
            y:{
                grid:{
                    drawOnChartArea: false
                },
                ticks:{
                    color:"black"
                }
            }
        },
        tooltips: {
            callbacks: {
               label: function(tooltipItem) {
                      return tooltipItem.yLabel;
               }
            }
        }
    };
   
    return (
        < >
            {
                GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length>2?
                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[2].target==null?
                    <div className='contop_block'>
                        <div className='contop_block_left '>
                            <div className='contop_block_title'>
                            {langv?.module_models?.contop?.text1}
                            </div>
                            <div className='contop_block_left_body'>
                                <div className='contop_block_left_body_1'>
                                    <span>{langv?.module_models?.contop?.text2}</span>
                                </div>
                                <div className='contop_block_left_body_2'>
                                    <div className='contop_block_left_body_2_line'>
                                    {langv?.module_models?.contop?.text3}
                                    </div>
                                    <div className='contop_block_left_body_2_line_bl'>
                                        <div className='contop_block_left_body_2_line_1'>
                                            <div className='contop_block_left_col_1'>

                                            </div>
                                            <div className='contop_block_left_col_2 '>
                                                {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[0]?.target.split(" ")[0]}
                                            </div>
                                            <div className='contop_block_left_col_3'>
                                                {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.target.split(" ")[0]}
                                            </div> 
                                            
                                        </div>
                                        <div className='contop_block_left_body_2_line_2'>
                                            <div className='contop_block_left_col_1'>
                                                {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[0]?.target.split(" ")[0]}
                                            </div>
                                            <div className='contop_block_left_col_2 vb1'>
                                                {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[2]?.confusionMatrix[0][0]}
                                            </div>
                                            <div className='contop_block_left_col_3 vb2'>
                                                {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[2]?.confusionMatrix[0][1]}
                                            </div>
                                            
                                        </div>
                                        <div className='contop_block_left_body_2_line_2'>
                                            <div className='contop_block_left_col_1'>
                                                {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.target.split(" ")[0]}
                                            </div>
                                            <div className='contop_block_left_col_2 vb3'>
                                                {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[2]?.confusionMatrix[1][0]}
                                            </div>
                                            <div className='contop_block_left_col_3 vb4'>
                                                {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[2]?.confusionMatrix[1][1]}
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className='contop_block_right'>
                            <div className='contop_block_title'>
                            {langv?.module_models?.contop?.text4}
                            </div>
                            <div className='contop_block_right_body'>
                                {/* <div className='contop_block_right_body_line_1'>
                                    {
                                        Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields).sort(([,a],[,b]) => a-b).reverse().slice(0,10).map(([key, value])=>
                                            <div className='contop_block_right_body_line'>
                                                {key}
                                            </div>
                                        )
                                    }
                                </div>
                                <div className='contop_block_right_body_line_2 lintop_bl'>
                                    {
                                        
                                        Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields).sort(([,a],[,b]) => a-b).reverse().slice(0,10).map(([key, value])=>
                                            <div className='contop_block_right_body_line'>
                                                <div style={{width: value*coef+"%"}}></div>
                                                <span>{value.toFixed(2)}</span>
                                            </div>
                                        )
                                    }
                                </div> */}
                                <Bar data={dataChart} options={optionsChart}/>
                            </div>
                        </div>
                    </div>
          
                    :
                        GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3].target==null?
                        <div className='contop_block'>
                            <div className='contop_block_left zeretmortis'>
                                <div className='contop_block_title' style={{paddingBottom:"0px"}}>
                                {langv?.module_models?.contop?.text1}
                                </div>
                                <div className='contop_block_left_body'>
                                    <div className='contop_block_left_body_1'>
                                        <span>{langv?.module_models?.contop?.text2}</span>
                                    </div>
                                    <div className='contop_block_left_body_2'>
                                        <div className='contop_block_left_body_2_line'>
                                        {langv?.module_models?.contop?.text3}
                                        </div>
                                        <div className='contop_block_left_body_2_line_bl'>
                                            <div className='contop_block_left_body_2_line_1'>
                                                <div className='contop_block_left_col_1'>

                                                </div>
                                                <div className='contop_block_left_col_2 ' style={{minWidth: "calc( ( 100% - 80px ) / 3 )"}}>
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[0]?.target.split(" ")[0]}
                                                </div>
                                                <div className='contop_block_left_col_3' style={{minWidth: "calc( ( 100% - 80px ) / 3 )"}}>
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.target.split(" ")[0]}
                                                </div> 
                                                <div className='contop_block_left_col_3' style={{minWidth: "calc( ( 100% - 80px ) / 3 )"}}>
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[2]?.target.split(" ")[0]}
                                                </div>  
                                            </div>
                                            <div className='contop_block_left_body_2_line_2'>
                                                <div className='contop_block_left_col_1' >
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[0]?.target.split(" ")[0]}
                                                </div>
                                                <div className='contop_block_left_col_2 vb1' style={
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][0]>=0&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][0]<(period3)?
                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderTopLeftRadius:"8px"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][0]>=(period3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][0]<(period3*2)?
                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderTopLeftRadius:"8px"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][0]>=(period3*2)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][0]<(period3*3)?
                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderTopLeftRadius:"8px"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][0]>=(period3*3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][0]<(period3*4)?
                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderTopLeftRadius:"8px"}
                                                    :
                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderTopLeftRadius:"8px"}
                                                }>
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][0]}
                                                </div>
                                                <div className='contop_block_left_col_3 vb2' style={
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][1]>=0&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][1]<(period3)?
                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderRadius:"0px"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][1]>=(period3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][1]<(period3*2)?
                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderRadius:"0px"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][1]>=(period3*2)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][1]<(period3*3)?
                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderRadius:"0px"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][1]>=(period3*3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][1]<(period3*4)?
                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderRadius:"0px"}
                                                    :
                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderRadius:"0px"}
                                                }>
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][1]}
                                                </div>
                                                <div className='contop_block_left_col_3 vbmurly3' style={
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][2]>=0&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][2]<(period3)?
                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderLeft:"0px",  borderTopRightRadius:"8px"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][2]>=(period3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][2]<(period3*2)?
                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderLeft:"0px",  borderTopRightRadius:"8px"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][2]>=(period3*2)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][2]<(period3*3)?
                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderLeft:"0px",  borderTopRightRadius:"8px"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][2]>=(period3*3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][2]<(period3*4)?
                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderLeft:"0px",  borderTopRightRadius:"8px"}
                                                    :
                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )", borderLeft:"0px",  borderTopRightRadius:"8px"}
                                                }>
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[0][2]}
                                                </div>
                                            </div>
                                            <div className='contop_block_left_body_2_line_2'>
                                                <div className='contop_block_left_col_1'>
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.target.split(" ")[0]}
                                                </div>
                                                <div className='contop_block_left_col_2 vbmulty02' style={
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][0]>=0&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][0]<(period3)?
                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][0]>=(period3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][0]<(period3*2)?
                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][0]>=(period3*2)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][0]<(period3*3)?
                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][0]>=(period3*3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][0]<(period3*4)?
                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                }>
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][0]}
                                                </div>
                                                <div className='contop_block_left_col_3 vbmulty2'  style={
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][1]>=0&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][1]<(period3)?
                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][1]>=(period3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][1]<(period3*2)?
                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][1]>=(period3*2)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][1]<(period3*3)?
                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][1]>=(period3*3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][1]<(period3*4)?
                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                }>
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][1]}
                                                </div>
                                                <div className='contop_block_left_col_3 vbmulty2'  style={
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][2]>=0&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][2]<(period3)?
                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][2]>=(period3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][2]<(period3*2)?
                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][2]>=(period3*2)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][2]<(period3*3)?
                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][2]>=(period3*3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][2]<(period3*4)?
                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                }>
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[1][2]}
                                                </div>
                                            </div>
                                            <div className='contop_block_left_body_2_line_2'>
                                                <div className='contop_block_left_col_1' >
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[2]?.target.split(" ")[0]}
                                                </div>
                                                <div className='contop_block_left_col_2 vbmulty13'  style={
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][0]>=0&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][0]<(period3)?
                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][0]>=(period3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][0]<(period3*2)?
                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][0]>=(period3*2)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][0]<(period3*3)?
                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][0]>=(period3*3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][0]<(period3*4)?
                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                }>
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][0]}
                                                </div>
                                                <div className='contop_block_left_col_3 vbmulty23' style={
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][1]>=0&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][1]<(period3)?
                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][1]>=(period3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][1]<(period3*2)?
                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][1]>=(period3*2)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][1]<(period3*3)?
                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][1]>=(period3*3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][1]<(period3*4)?
                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                }>
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][1]}
                                                </div>
                                                <div className='contop_block_left_col_3 vbmulty33' style={
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][2]>=0&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][2]<(period3)?
                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][2]>=(period3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][2]<(period3*2)?
                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][2]>=(period3*2)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][2]<(period3*3)?
                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][2]>=(period3*3)&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][2]<(period3*4)?
                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                    :
                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% - 80px ) / 3 )"}
                                                }> 
                                                    {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[3]?.confusionMatrix[2][2]}
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className='contop_block_right'>
                                <div className='contop_block_title'>
                                {langv?.module_models?.contop?.text4}
                                </div>
                                <div className='contop_block_right_body'>
                                    {/* <div className='contop_block_right_body_line_1'>
                                        {
                                            Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields).sort(([,a],[,b]) => a-b).reverse().slice(0,10).map(([key, value])=>
                                                <div className='contop_block_right_body_line'>
                                                    {key}
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='contop_block_right_body_line_2 lintop_bl'>
                                        {
                                            
                                            Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields).sort(([,a],[,b]) => a-b).reverse().slice(0,10).map(([key, value])=>
                                                <div className='contop_block_right_body_line'>
                                                    <div style={{width: value*coef+"%"}}></div>
                                                    <span>{value.toFixed(2)}</span>
                                                </div>
                                            )
                                        }
                                    </div> */}
                                    <Bar data={dataChart} options={optionsChart}/>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='contop_block zeretabmortis_line'>
                            <div className='contop_block_left zeretabmortis'>
                                <div className='contop_block_title'>
                                {langv?.module_models?.contop?.text1}
                                </div>
                                <div className='contop_block_left_body'>
                                    <div className='contop_block_left_body_1'>
                                        <span>{langv?.module_models?.contop?.text2}</span>
                                    </div>
                                    <div className='contop_block_left_body_2'>
                                        <div className='contop_block_left_body_2_line'>
                                        {langv?.module_models?.contop?.text3}
                                        </div>
                                        <div className='contop_block_left_body_2_line_bl alik'>
                                            <div className='contop_block_left_body_2_line_1'>
                                                <div className='contop_block_left_col_1'>

                                                </div>
                                                {
                                                    GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets?.map((n,i)=>
                                                        n.target!=null?
                                                        <div className='contop_block_left_col_2' style={{minWidth: "calc( ( 100% "+otstup2+") / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )"}}>
                                                            {n.target.split(" ")[0]}
                                                        </div>
                                                        :
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            {
                                                GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets?.map((n,i)=>
                                                    n.target!=null?
                                                    <div className='contop_block_left_body_2_line_2'>
                                                        <div className='contop_block_left_col_1'>
                                                            {n.target.split(" ")[0]}
                                                           
                                                        </div>
                                                        {
                                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1].confusionMatrix[i].map((z, u)=>
                                                                
                                                                i==u?
                                                                i==0&&u==0?
                                                                <div className={'contop_block_left_col_2 vbmulty1'} style={ 
                                                                    z>=0&&z<(periodmulty)?
                                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopLeftRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty)&&z<(periodmulty*2)?
                                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopLeftRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty*2)&&z<(periodmulty*3)?
                                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopLeftRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty*3)&&z<(periodmulty*4)?
                                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopLeftRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopLeftRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    }>
                                                                    {z}
                                                                </div>
                                                                :
                                                                i==0&&u==(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1].confusionMatrix[i].length-1)?
                                                                <div className={'contop_block_left_col_2 vbmulty1'} style={ 
                                                                    z>=0&&z<(periodmulty)?
                                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopRightRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty)&&z<(periodmulty*2)?
                                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopRightRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty*2)&&z<(periodmulty*3)?
                                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopRightRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty*3)&&z<(periodmulty*4)?
                                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopRightRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopRightRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    }>
                                                                    {z}
                                                                    
                                                                </div>
                                                                :
                                                                i==(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-2)&&u==(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1].confusionMatrix[i].length-1)?
                                                                <div className={'contop_block_left_col_2 vbmulty1'} style={ 
                                                                    z>=0&&z<(periodmulty)?
                                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomRightRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty)&&z<(periodmulty*2)?
                                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomRightRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty*2)&&z<(periodmulty*3)?
                                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomRightRadis:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty*3)&&z<(periodmulty*4)?
                                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomRightRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomRightRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    }>
                                                                    {z}
                                                                    
                                                                </div>
                                                                :
                                                                i==(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-2)&&u==0?
                                                                <div className={'contop_block_left_col_2 vbmulty1'} style={ 
                                                                    z>=0&&z<(periodmulty)?
                                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomLeftRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty)&&z<(periodmulty*2)?
                                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomLeftRadius:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty*2)&&z<(periodmulty*3)?
                                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomLeftRadiu:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty*3)&&z<(periodmulty*4)?
                                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomLeftRadiu:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomLeftRadiu:"8px", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    }>
                                                                    {z}
                                                                    
                                                                </div>
                                                                :
                                                                <div className={'contop_block_left_col_2 vbmulty1'} style={ 
                                                                    z>=0&&z<(periodmulty)?
                                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty)&&z<(periodmulty*2)?
                                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty*2)&&z<(periodmulty*3)?
                                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    z>=(periodmulty*3)&&z<(periodmulty*4)?
                                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    :
                                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", border:"4px solid rgba(19, 103, 241, 1)", padding: "0px"}
                                                                    }>
                                                                    {z}
                                                                </div>
                                                                :
                                                                i==0&&u==0?
                                                                <div className={'contop_block_left_col_2 vbmulty1'} style={ 
                                                                    z>=0&&z<(periodmulty)?
                                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopLeftRadius:"8px", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty)&&z<(periodmulty*2)?
                                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopLeftRadius:"8px", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty*2)&&z<(periodmulty*3)?
                                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopLeftRadius:"8px", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty*3)&&z<(periodmulty*4)?
                                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopLeftRadius:"8px", padding: "3px"}
                                                                    :
                                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopLeftRadius:"8px", padding: "3px"}
                                                                    }>
                                                                    {z}
                                                                </div>
                                                                :
                                                                i==0&&u==(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1].confusionMatrix[i].length-1)?
                                                                <div className={'contop_block_left_col_2 vbmulty1'} style={ 
                                                                    z>=0&&z<(periodmulty)?
                                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopRightRadius:"8px", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty)&&z<(periodmulty*2)?
                                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopRightRadius:"8px", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty*2)&&z<(periodmulty*3)?
                                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopRightRadius:"8px", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty*3)&&z<(periodmulty*4)?
                                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopRightRadius:"8px", padding: "3px"}
                                                                    :
                                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderTopRightRadius:"8px", padding: "3px"}
                                                                    }>
                                                                    {z}
                                                                    
                                                                </div>
                                                                :
                                                                i==(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-2)&&u==(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1].confusionMatrix[i].length-1)?
                                                                <div className={'contop_block_left_col_2 vbmulty1'} style={ 
                                                                    z>=0&&z<(periodmulty)?
                                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomRightRadius:"8px", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty)&&z<(periodmulty*2)?
                                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomRightRadius:"8px", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty*2)&&z<(periodmulty*3)?
                                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomRightRadis:"8px", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty*3)&&z<(periodmulty*4)?
                                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomRightRadius:"8px", padding: "3px"}
                                                                    :
                                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomRightRadius:"8px", padding: "3px"}
                                                                    }>
                                                                    {z}
                                                                    
                                                                </div>
                                                                :
                                                                i==(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-2)&&u==0?
                                                                <div className={'contop_block_left_col_2 vbmulty1'} style={ 
                                                                    z>=0&&z<(periodmulty)?
                                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomLeftRadius:"8px", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty)&&z<(periodmulty*2)?
                                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomLeftRadius:"8px", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty*2)&&z<(periodmulty*3)?
                                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomLeftRadiu:"8px", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty*3)&&z<(periodmulty*4)?
                                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomLeftRadiu:"8px", padding: "3px"}
                                                                    :
                                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", borderBottomLeftRadiu:"8px", padding: "3px"}
                                                                    }>
                                                                    {z}
                                                                    
                                                                </div>
                                                                :
                                                                <div className={'contop_block_left_col_2 vbmulty1'} style={ 
                                                                    z>=0&&z<(periodmulty)?
                                                                    {background:"rgba(218, 232, 253, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty)&&z<(periodmulty*2)?
                                                                    {background:"rgba(193, 216, 252, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty*2)&&z<(periodmulty*3)?
                                                                    {background:"rgba(169, 199, 251, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", padding: "3px"}
                                                                    :
                                                                    z>=(periodmulty*3)&&z<(periodmulty*4)?
                                                                    {background:"rgba(149, 187, 249, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", padding: "3px"}
                                                                    :
                                                                    {background:"rgba(121, 169, 248, 1)", minWidth: "calc( ( 100% "+otstup+" ) / "+(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1) +" )", padding: "3px"}
                                                                    }>
                                                                    {z}
                                                                </div>
                                                               
                                                            )
                                                        }
                                                    
                                                    </div>
                                                    :
                                                    <></>
                                                )
                                            }
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className='contop_block_right zeretabmortis'  style={{marginTop: "30px"}}>
                                <div className='contop_block_title'>
                                {langv?.module_models?.contop?.text4}
                                </div>
                                <div className='contop_block_right_body' style={{justifyContent: "center"}}>
                                    {/* <div className='contop_block_right_body_line_1'>
                                        {
                                            Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields).sort(([,a],[,b]) => a-b).reverse().slice(0,10).map(([key, value])=>
                                                <div className='contop_block_right_body_line'>
                                                    {key}
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='contop_block_right_body_line_2 lintop_bl'>
                                        {
                                            
                                            Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields).sort(([,a],[,b]) => a-b).reverse().slice(0,10).map(([key, value])=>
                                                <div className='contop_block_right_body_line'>
                                                    <div style={{width: value*coef+"%"}}></div>
                                                    <span>{value.toFixed(2)}</span>
                                                </div>
                                            )
                                        }
                                    </div> */}
                                    <Bar data={dataChart} options={optionsChart}/>
                                </div>
                            </div>
                        </div>
                    
                :
                <div className='contop_block'>
                    <div className='contop_block_left'>
                        <div className='contop_block_title'>
                        {langv?.module_models?.contop?.text1}
                        </div>
                        <div className='contop_block_left_body'>
                            <div className='contop_block_left_body_1'>
                                <span>{langv?.module_models?.contop?.text2}</span>
                            </div>
                            <div className='contop_block_left_body_2'>
                                <div className='contop_block_left_body_2_line'>
                                {langv?.module_models?.contop?.text3}
                                </div>
                                <div className='contop_block_left_body_2_line_bl'>
                                    <div className='contop_block_left_body_2_line_1'>
                                        <div className='contop_block_left_col_1'>

                                        </div>
                                        <div className='contop_block_left_col_2'>
                                            {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[0]?.target.split(" ")[0]}
                                        </div>
                                        <div className='contop_block_left_col_3'>
                                            {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.target.split(" ")[0]}
                                        </div>  
                                    </div>
                                    <div className='contop_block_left_body_2_line_2'>
                                        <div className='contop_block_left_col_1'>
                                            {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[0]?.target.split(" ")[0]}
                                        </div>
                                        <div className='contop_block_left_col_2 vb1' style={
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tn>=0&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tn<(period)?
                                            {background:"rgba(218, 232, 253, 1)"}
                                            :
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tn>=(period)&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tn<(period*2)?
                                            {background:"rgba(193, 216, 252, 1)"}
                                            :
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tn>=(period*2)&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tn<(period*3)?
                                            {background:"rgba(169, 199, 251, 1)"}
                                            :
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tn>=(period*3)&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tn<(period*4)?
                                            {background:"rgba(149, 187, 249, 1)"}
                                            :
                                            {background:"rgba(121, 169, 248, 1)"}
                                        }>

                                            {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tn}
                                        </div>
                                        <div className='contop_block_left_col_3 vb2' style={
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fn>=0&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fn<(period)?
                                            {background:"rgba(218, 232, 253, 1)"}
                                            :
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fn>=(period)&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fn<(period*2)?
                                            {background:"rgba(193, 216, 252, 1)"}
                                            :
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fn>=(period*2)&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fn<(period*3)?
                                            {background:"rgba(169, 199, 251, 1)"}
                                            :
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fn>=(period*3)&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fn<(period*4)?
                                            {background:"rgba(149, 187, 249, 1)"}
                                            :
                                            {background:"rgba(121, 169, 248, 1)"}
                                        }>
                                            {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fn}
                                        </div>
                                    </div>
                                    <div className='contop_block_left_body_2_line_2'>
                                        <div className='contop_block_left_col_1'>
                                            {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.target.split(" ")[0]}
                                        </div>
                                        <div className='contop_block_left_col_2 vb3' style={
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fp>=0&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fp<(period)?
                                            {background:"rgba(218, 232, 253, 1)"}
                                            :
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fp>=(period)&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fp<(period*2)?
                                            {background:"rgba(193, 216, 252, 1)"}
                                            :
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fp>=(period*2)&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fp<(period*3)?
                                            {background:"rgba(169, 199, 251, 1)"}
                                            :
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fp>=(period*3)&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fp<(period*4)?
                                            {background:"rgba(149, 187, 249, 1)"}
                                            :
                                            {background:"rgba(121, 169, 248, 1)"}
                                        }>
                                            {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.fp}
                                        </div>
                                        <div className='contop_block_left_col_3 vb4' style={
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tp>=0&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tp<(period)?
                                            {background:"rgba(218, 232, 253, 1)"}
                                            :
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tp>=(period)&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tp<(period*2)?
                                            {background:"rgba(193, 216, 252, 1)"}
                                            :
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tp>=(period*2)&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tp<(period*3)?
                                            {background:"rgba(169, 199, 251, 1)"}
                                            :
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tp>=(period*3)&&
                                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tp<(period*4)?
                                            {background:"rgba(149, 187, 249, 1)"}
                                            :
                                            {background:"rgba(121, 169, 248, 1)"}
                                        }>
                                            {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets[1]?.confusionMatrix.tp}
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className='contop_block_right'>
                        <div className='contop_block_title'>
                        {langv?.module_models?.contop?.text4}
                        </div>
                        <div className='contop_block_right_body'>
                            {/* <div className='contop_block_right_body_line_1'>
                                {
                                    Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields||[]).sort(([,a],[,b]) => a-b).reverse().slice(0,10).map(([key, value])=>
                                        <div className='contop_block_right_body_line'>
                                            {key}
                                        </div>
                                    )
                                }
                            </div>
                            <div className='contop_block_right_body_line_2 lintop_bl'>
                                {
                                    
                                    Object.entries(GlobalState.activemodel?.lastTrain?.metadata?.topFields||[]).sort(([,a],[,b]) => a-b).reverse().slice(0,10).map(([key, value])=>
                                        <div className='contop_block_right_body_line'>
                                            <div style={{width: value*coef+"%"}}></div>
                                            <span>{value.toFixed(2)}</span>
                                        </div>
                                    )
                                }
                            </div> */}
                            <Bar data={dataChart} options={optionsChart}/>
                        </div>
                    </div>
                </div>
                }
            
        </>
    )
})
export default Contop;