import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react'
import AttachmentBox from "./AttachmentBox";
import TableExcelDeployment from "./tableExcelDeployment";
import './index.css';
import Papa from "papaparse";
import GlobalState, {changepopupDeployment} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import lang from "../../lang"
const PopupDeployment = observer(() =>{
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [file, setFile] = useState();
    const [save, setSave] = useState(false);
    const [savename, setSavename] = useState("Set Downloading");
    const changesave = () =>{
        //setSave(true);
        // setTimeout(function(){
        //     changepopupDeployment();
        // }, 100)
        downloaddataset();
    }
    const downloaddataset = async () => {
        var mass = "&columns=";
        var i = 0;
        GlobalState.segmentdeploymass1.map((n, id)=>{
            mass = mass + n + "&columns=";
            i++;
        })
        GlobalState.segmentdeploymass2.map((n, id)=>{
            mass = mass + n + "&columns="
            i++;
        })
        if(i>0)
        {
            mass = mass.slice(0, -9);
        }
        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `segmentations/download_csv?dataset_key=`+GlobalState.activedatasetSegment.file_key+mass, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        
        if(response.status=="200")
        {
            const data = await response.json();
        
            window.location.href=data.url      
        } 
    }
    async function uploadFile(fileurl){
        var token = localStorage.getItem("token");
        await fetch(fileurl, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        }).then(response => {
         
        })
    }
    useEffect(() => {
        uploadFile(file)
    }, [file]);

    
    return (
        <div className="popupInfofon">
            <div className="popupInfo addwork attach">
                <div className='top-element' style={{marginBottom:"24px"}}>
                    <div className='namepopup'>
                    {langv?.deploy?.text1}
                    </div>
                    {/* <div className='separator' style={{width:"240px"}}></div> */}
                    <div></div>
                    <div className='buttonspopup' style={{minWidth:"320px"}}>
                        <div className={save?'buttonsave active':'buttonsave'} style={{width:"220px"}} onClick={()=>changesave()}>{savename}</div>
                        <div className='buttonexit' onClick={changepopupDeployment}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='adddataset-element'>
                   <TableExcelDeployment {...{setSavename, setSave}}/>
                </div>
            </div>
        </div>
    );
});

export default PopupDeployment;
