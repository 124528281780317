import React, {useState} from 'react';
import './LoginPage.css';
import Sidebar from '../../components/sidebar';
import Login from "../../components/autorize/Login";
import {Helmet} from "react-helmet";
function LoginPage() {
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    return (
        <div className="login-page">
            {
                langmenu=="de"?
                <Helmet>
                    <title>Bind Genius — Hyperpersonalisierte Kundenkommunikation mit Hilfe von Prädiktiver und Generativer KI</title>
                    <meta name="description" content="Verbesserung des Kundenerlebnisses mit Bind Genius. Historische und prädiktive Analysen sowie generative KI zur Erstellung von personalisierten Inhalten, um Kunden effektiv zu binden, den Umsatz zu steigern und den Customer Lifetime Value (CLV) zu erhöhen." />
                </Helmet>
                :
                <Helmet>
                    <title>Bind Genius — Hyper-personalized Customer Communication Using Predictive and Generative AI</title>
                    <meta name="description" content="Enhancing customer experience with Bind Genius. Historical and predictive analytics, along with generative AI, to create personalized content aimed at effectively retaining customers, increasing sales, and boosting Customer Lifetime Value (CLV)." />
                </Helmet>
            }
            <Sidebar/>
            <Login/>
        </div>
    );
}

export default LoginPage;

