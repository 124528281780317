import React, {useState} from 'react';
import Select, { components } from 'react-select'
import { observer } from 'mobx-react'
import './index.css';
import {addinfoproduct, addpopupproduct} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import lang from "../../lang"

const PopupInfo = observer(() =>{
    
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const updateGeneralSettings = async () => {
       
        if(name!=''||type!='')
        {
            var product = {
                type: type,
                name: name,
                description: description,
                status: "Active"
            };
            addinfoproduct(product);
            addpopupproduct();  

            var token = localStorage.getItem("token");
            fetch(Domen.url + `general_settings/product_tool`, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(product),
            })
            .then(response => {
            })
            .catch(error => {
                console.error('There was an error with the fetch operation:', error);
            });
        }
    };

    const options = [
        { value: 'Product', label: 'Product' },
        { value: 'Tool', label: 'Tool' }
    ]

    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          background: 'rgba(28, 30, 32, 1)',
          display: 'flex',
          flexWrap: 'nowrap',
          width: '172px',
          color: '#fff',
          border: '0 !important',
          borderRadius: '8px',
           
          boxShadow: 'none',
            '&:hover': {
            border: '0 !important',
           
            },
            '&:focus': {
                border: '0 !important',
                
            },
            '&:active': {
                border: '0 !important',
                
            }
        }),
        
        menu: (provided) => ({
          ...provided,
          background: 'rgba(28, 30, 32, 1)',
          width: '172px',
          marginTop: '-2px',
          boxShadow: 'none',
          borderRadius: '0px',
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px"
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 15,
          
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'white',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
          }),
    };

    const DropdownIndicator = props => {
        return (
          components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {
                props.selectProps.menuIsOpen ? 
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="12" r="2" fill="white"/>
                        <path d="M1 9L9.24242 1L17 9" stroke="white"/>
                    </svg>
                    : 
                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 7 4.5)" fill="white"/>
                        <path d="M1 5.5L9.24242 13.5L17 5.5" stroke="white"/>
                    </svg> 
                }
            </components.DropdownIndicator>
          )
        );
      };
    console.log(langv)
    return (
        <div className="popupInfofon">
            <div className="popupInfo">
                <div className='top-element'>
                    <div className='namepopup'>
                       {langv?.groupsettings?.popupaddproduct?.text1}
                    </div>
                    {/* <div className='separator'></div> */}
                    <div></div>
                    <div className='buttonspopup'>
                        <div className={name===''||type===''?'buttonsave':'buttonsave active'} onClick={updateGeneralSettings}>{langv?.groupsettings?.popupaddproduct?.text2}</div>
                        <div className='buttonexit' onClick={addpopupproduct}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='form-element'>
                    <div className='popup-inputs'>
                        <Select 
                            components={{ DropdownIndicator }}
                            options={options} 
                            styles={customStyles} 
                            classNamePrefix='filter'
                            className='selectproduct'
                            isSearchable={false} 
                            placeholder={<div className='selectplace'>{langv?.groupsettings?.popupaddproduct?.text3}</div>}
                            onChange={(option) => setType(option.value)}
                        />
                        
                        <input
                            id="website"
                            type="text"
                            placeholder={langv?.groupsettings?.popupaddproduct?.text4}
                            className="pop-input"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </div>
                    <div className='popup-textarea'>
                        <textarea placeholder={langv?.groupsettings?.popupaddproduct?.text5} className="pop-area" onChange={(event) => setDescription(event.target.value)}>{description}</textarea>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PopupInfo;
