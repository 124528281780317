import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import lang from "../../../lang"
const LoadingCompleted = observer(({text}) => {
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    return (
        <div className='loading_block'>
            <div className='loading_icon text'>
            {langv?.loading?.fail?.text1}
            </div>
            <div className='loading_text small' >
            {langv?.loading?.fail?.text2}
            </div>
        </div>
    )

})

export default LoadingCompleted;