import React, {useState, useContext} from "react";
import {AccountContext} from "./Account";
import "./Login.css";
import lang from "../../lang"
const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error1, setError1] = useState(null);
    const [error2, setError2] = useState(null);
    const [typepass, setTypepass] = useState(0);
    const {authenticate} = useContext(AccountContext);
    const [errorlogin, setErrorlogin] = useState(false);
    const [errorpassword, setErrorpassword] = useState(false);
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const onSubmit = (event) => {
        event.preventDefault();
        if(email=="")
        {
            setError1("Invalid username");
            setErrorlogin(true)
        }
        else{
            setError1("");
            setErrorlogin(false)
        }
        if(password=="")
        {
            setError2("Invalid password");
            setErrorpassword(true)
        }
        else{
            setError2("");
            setErrorpassword(false)
        }
        if(email!=""&&password!=""){
            
            setErrorlogin(false);
            setErrorpassword(false);
            setError1(null);
            setError2(null);
            authenticate(email, password)
                .then((data) => {
                    localStorage.setItem("token", data.accessToken.jwtToken);
                    window.location.href = "/";
                })
                .catch((err) => {
                    console.log(err);
                    let str = err.toString();
                    if(str.split(':')[0]==='UserNotFoundException')
                    {
                        setError1("Invalid username");
                        setErrorlogin(true)
                    }
                    else
                    {
                        setError2("Invalid password");
                        setErrorpassword(true)
                    }
                    
                });
        }
        
    };
    const changepass = () =>{
        if(typepass===0)
        {
            setTypepass(1)
        }
        if(typepass===1)
        {
            setTypepass(0)
        } 
    }
    return (
        <>
            <div className='central_img'></div>
            <div className='left_img'></div>
            <div className="login-container">
                <form onSubmit={onSubmit}>
                    <h1 dangerouslySetInnerHTML={{ __html: langv?.login?.title}}>
                    </h1>
                    <label htmlFor="email">{langv?.login?.text1}</label>
                    <input
                        id="email"
                        className={errorlogin?"auth-input error":"auth-input"}
                        type="text"
                        placeholder={langv?.login?.text1}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    {error1 && <div className="error-message">{error1}</div>} {}
                    <label htmlFor="password">{langv?.login?.text2}</label>
                    <input
                        id="password"
                        type={typepass===0?"password":"text"}
                        placeholder={langv?.login?.text2}
                        className={errorpassword?"auth-input error":"auth-input"}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <span class="password-toggle-icon" onClick={changepass} >
                        {typepass===1?
                            <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.6">
                                    <path d="M13.0004 9.93555L15.6537 11.4675V14.5313L13.0004 16.0632L10.347 14.5313V11.4675L13.0004 9.93555Z" stroke="#020203"/>
                                    <path d="M7 19L0.999999 13L7 7C10.3133 3.68667 15.6867 3.68667 19 7L25 13L19 19C15.6867 22.3133 10.3133 22.3133 7 19Z" stroke="#020203"/>
                                    <line x1="0.646447" y1="24.6464" x2="24.6464" y2="0.646445" stroke="black"/>
                                </g>
                            </svg>
                            :
                            
                            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.6">
                                    <path d="M13.0013 8.93604L15.6547 10.4679V13.5318L13.0013 15.0637L10.348 13.5318V10.4679L13.0013 8.93604Z" stroke="#020203"/>
                                    <path d="M7 18L0.999999 12L7 6C10.3133 2.68667 15.6867 2.68667 19 6L25 12L19 18C15.6867 21.3133 10.3133 21.3133 7 18Z" stroke="#020203"/>
                                </g>
                            </svg>
                        }
                    </span>
                    {error2 && <div className="error-message">{error2}</div>} {}
                    <button type="submit">{langv?.login?.text3}</button>
                </form>
            </div>
            <div className='betta'>
                <div className='betta_inside'>
                {langv?.beta?.head_text}
                </div>
            </div>
            <div className='right_img'></div>
        </>
    );
};

export default Login;
