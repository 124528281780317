import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import GlobalState from "../../../stores/GlobalState";
import lang from "../../../lang"
const Priview = observer(() => {
    const [line, setLine] = useState([
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1},
        {z:1}
    ])
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    return (
        <div className='priview'>
            <div className='priview_tittle'>
            {langv?.module_models?.preview?.text1}
            </div>
            <div className='priview_body'>
                <div className='priview_body_scroll'>
                    <div className='priview_body_head'>
                        {
                           GlobalState.activemodel?.lastTrain?.metadata?.predictPreview?.map((n, id)=>
                                id==0?
                                Object.entries(n).map(([key, value])=>
                                    <div className='priview_body_head_col_1 head_line_padding'>
                                        {key.length>15?<></>:key}
                                        {   
                                            key.length>15?
                                            <>
                                            {key.substr(0, 14)}
                                            <span className='open_tooltipa_head_prew'> ...
                                                <div className='tooltipa_head_prew'>{key}</div>
                                            </span>
                                            
                                            </>
                                            :
                                            <></>
                                        }
                                    </div>
                                )
                                :
                                <></>
                            )
                        }
                        
                    </div>
                    <div className='priview_body_body'>
                        {
                            GlobalState.activemodel?.lastTrain?.metadata?.predictPreview?.map((n, id)=>
                                <div className='priview_body_body_line'>
                                    {
                                        Object.entries(n).map(([key, value])=>
                                            <div className='priview_body_head_col_1 head_line_padding'>
                                                
                                                <div className='priview_body_head_col_1'>
                                                    <input
                                                        id="source"
                                                        type="text"
                                                        disabled="true"
                                                        placeholder="Churn Prediction "
                                                        className=""
                                                        value={value}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                        <div className='count_prew'>
                            
                        {langv?.module_models?.preview?.text2} - {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.support} {langv?.module_models?.preview?.text3}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default Priview;