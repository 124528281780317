import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import GlobalState from "../../../stores/GlobalState";
import lang from "../../../lang"
const Fourblock = observer(() => {
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    return (
        <div className='fourblock'>
            <div className='block_four' style={{paddingLeft:"0px", width:"calc(25% - 10px)"}}>
                <div className='block_four_body'>
                    <div>
                        <div className='block_four_1'>
                        {langv?.module_models?.fourblock?.text1}
                        </div>
                    
                        <div className='block_four_3'>
                            {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.mae.toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>
            <div className='block_four' >
                <div className='block_four_body'>
                    <div>
                        <div className='block_four_1'>
                        {langv?.module_models?.fourblock?.text3}
                        </div>
                    
                        <div className='block_four_3'>
                            {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.rmse.toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>
            <div className='block_four'>
                <div className='block_four_body'>
                    <div className='block_four_1'>
                    {langv?.module_models?.fourblock?.text5}
                    </div>
                   
                    <div className='block_four_3'>
                        {(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.r2Score*100).toFixed(2)}%
                    </div>
                    <div className='block_four_4'>

                    </div>
                    <div className='block_four_5'>
                        <div>
                           
                            {langv?.module_models?.fourblock?.text8}<br/>
                            {langv?.module_models?.fourblock?.text9}<br/>
                            {langv?.module_models?.fourblock?.text10}<br/>
                        </div>
                        <div>
                            
                            {langv?.module_models?.fourblock?.text11}<br/>
                            <div dangerouslySetInnerHTML={{__html: langv?.module_models?.fourblock?.text12}}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='block_four' style={{paddingRight:"0px", width:"calc(25% - 10px)"}}>
                <div className='block_four_body'>
                    <div className='block_four_1'>
                    {langv?.module_models?.fourblock?.text13}  
                    </div>
                    
                    <div className='block_four_3'>
                        {(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.mape*100).toFixed(2)>9999?"n/a":(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.mape*100).toFixed(2)}%
                    </div>
                    <div className='block_four_4'>

                    </div>
                    <div className='block_four_5'>
                        <div>
                          
                            {langv?.module_models?.fourblock?.text8}<br/>
                            {langv?.module_models?.fourblock?.text9}<br/>
                            {langv?.module_models?.fourblock?.text10}<br/>
                        </div>
                        <div>
                            
                            {langv?.module_models?.fourblock?.text11}<br/>
                            <div dangerouslySetInnerHTML={{__html: langv?.module_models?.fourblock?.text12}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default Fourblock;