import React, {useState, useEffect} from "react";
import Select, { components } from 'react-select'
import { observer } from 'mobx-react';
import Papa from "papaparse";
import GlobalState, {adddataset} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import lang from "../../lang"
const TableExcelDeployment = observer(({setSavename, setSave}) =>{

  const [activebut, setActivebut] = useState(0);
  const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
  const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
  const [flag, setFlag] =useState(0)
  const activesave = (a) =>{
    setSavename('Download')
    setActivebut(a)
    setSave(true)
  }
  setSavename('Download')
  setSave(true)
  

  return (
    <div className="tableexcel_line">
      <div className="tableexcel_head">
        <div className="tableexcel_select">
          <div className="but_depluyment inactive">
          {langv?.deploy?.text9}
          </div>
          <div className={activebut==2?"but_depluyment active":"but_depluyment active"} onClick={()=>setActivebut(2)}>
          {langv?.deploy?.text10}
          </div>
        </div>
        <div className="params_dataset">
            <div className="line1_params_dataset">
                
               
            </div>
        </div>
      </div>
      <div className="tableexcel_body" style={{marginTop: "0px"}}>
          <table>
            <thead>
            {
                GlobalState.datasetPrew?.map((n, id)=>
                  id==0?
                  <tr>     
                      <th>{GlobalState.activedatasetSegment.object_id}</th>
                              
                           
                    {
                      Object.entries(n).map(([key, value]) =>
                        <>
                          
                          {
                            GlobalState.segmentdeploymass1.includes(key)?
                           
                            <th>{key}</th>
                           
                            :
                            <></>
                          }
                          {
                            GlobalState.segmentdeploymass2.includes(key)?
                           
                            <th>{key}</th>
                            
                            :
                            <></>
                          }
                        </>
                        
                      )
                    }
                  
                  </tr>
                  :
                  <></>
                )
              }


              {/* <tr>
                  <th>{GlobalState.activedatasetSegment.object_id}</th>
                {
                  GlobalState.segmentdeploymass1.map((n,id)=>
                    <th>{n}</th>
                  )
                  
                }
                {
                  GlobalState.segmentdeploymass2.map((n,id)=>
                    <th>{n}</th>
                  )
                }
              </tr> */}
              
            </thead>
            <tbody>
              {
                GlobalState.datasetPrew?.map((n, id)=>
                  <tr>
                    {
                      Object.entries(n).map(([key, value]) =>
                        <>
                          {
                            key==GlobalState.activedatasetSegment.object_id?
                            <td>{value}{value.length>15?"...":<></>}</td>
                            :
                           <></>
                          }
                          {
                            GlobalState.segmentdeploymass1.includes(key)?
                            <td>{value!=null?value?.slice(0,10):<></>}{value!=null?value.length>15?"...":<></>:<></>}</td>
                            :
                            <></>
                          }
                          {
                            GlobalState.segmentdeploymass2.includes(key)?
                            <td>{value}{value.length>15?"...":<></>}</td>
                            :
                            <></>
                          }
                        </>
                      )
                    }
                  </tr>
                )
              }
            
           
            </tbody>
          </table>
        </div>
    </div>
  );
})

export default TableExcelDeployment;

