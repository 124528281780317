import React, {useState} from 'react';
import './Datasets.css';
import { observer } from 'mobx-react';
import Sidebar from '../../components/sidebar/index.js';
import Dataset from '../../components/dataset/index.js'
import Models from '../../components/models/index.js'
import GlobalState from "../../stores/GlobalState.js";
import PopupDeploymentAnalitics from '../../components/dataset/popupDeploymentAnalitics.js';
import PopupAddDataset from '../../components/dataset/popupAddDataset.js';
import PopupAttachment from '../../components/dataset/popupAttachment.js';
import PopupDeleteDataset from '../../components/dataset/popupDeleteDataset';
import PopupRefreshDataset from '../../components/dataset/popupRefreshDataset';
import PopupRefreshDatasetCSV from '../../components/dataset/popupRefreshDatasetCSV';
import {Helmet} from "react-helmet";


const Datasets= observer(() => {
    const [holdBlock, setholdBlock] = useState(true);
    const [datasegment, setDatasegment] = useState(1);
    const changemenu = () =>{
        setholdBlock(false)
    }
    window.onbeforeunload = () => {
        setholdBlock(true)
    }
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    return (
        <>
        {
            langmenu=="de"?
            <Helmet>
                <title>Bind Genius — Hochladen historischer Datensätze</title>
                <meta name="description" content="Mit Bind Genius ist es einfach, Kundendaten in wertvolle Business-Insights zu verwandeln. Lade deinen Datensatz für die weitere Analyse hoch." />
            </Helmet>
            :
            <Helmet>
                <title>Bind Genius — Uploading Historical Datasets</title>
                <meta name="description" content="With Bind Genius, it's easy to turn customer datasets into valuable business insights. Upload your dataset for further analysis." />
            </Helmet>
        }
        <Sidebar/>
        <div className="segmentation models hold">
           
            <div className='body_segment'>
                <Dataset/>
            </div>
        </div>
        {GlobalState.ispopupaddAttachmentShowAnalitics?<PopupDeploymentAnalitics/>:<></>}
        {GlobalState.ispopupadddatasetShow?<PopupAddDataset/>:<></>}
        {GlobalState.ispopupaddAttachmentShow?<PopupAttachment/>:<></>}
        {GlobalState.ispopupdeleteDatasetShow ?<PopupDeleteDataset id={GlobalState.ispopupdeletedatasetid} deleteIdnum = {GlobalState.ispopupdeleteatasetname}/>:<></>}
        {GlobalState.ispopuprefreshDatasetShow ?<PopupRefreshDataset id={GlobalState.ispopupdeletedatasetid} deleteIdnum = {GlobalState.ispopupdeleteatasetname}/>:<></>}
        {GlobalState.ispopuprefreshDatasetShowcsv ?<PopupRefreshDatasetCSV id={GlobalState.ispopupdeletedatasetid} deleteIdnum = {GlobalState.ispopupdeleteatasetname}/>:<></>}
        </>
    );
})

export default Datasets;

