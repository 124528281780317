import React, {useState, useEffect} from 'react';
import Select, { components } from 'react-select'
import { observer } from 'mobx-react'
import './index.css';
import GlobalState, {addModels, changemodelflag, changeActiveModel, changepopupaddAttachmentAnalitics, changeActiveModeldatasetPrew} from "../../stores/GlobalState";
import Pool from "../autorize/UserPool";
import Domen from "../../stores/Domen";
import Loading from '../common/loading';
import LoadingCompleted from '../common/loading_completed';
import LoadingFail from '../common/loading_fail';
import Accuracy from '../common/accuracy';
import Contop from '../common/contop';
import TabsTarget from '../common/tabstarget';
import Fourblock from '../common/fourblock';
import TopField from '../common/topfield';
import Priview from '../common/preview';
import Metric from '../common/metric';
import lang from "../../lang"

const Addmodels = observer(({setAddmodel}) => {
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [optionfields, setOptionfields] = useState([]);
    const [load, setLoad] = useState(0);
    const [namemodal, setNamemodal] = useState("");
    const [countmodal, setCountmodal] = useState("");
    const [optiontype, setOptiontype] = useState("");
    const [optionmetrics, setOptionmetrics] = useState("");
    const [optiontarget, setOptiontarget] = useState("");
    const [optiontargetfields, setOptiontargetfields] = useState("");
    const [disablebut, setDisablebut] = useState(true)

    const [disabletitle, setDisabletitle] = useState(false)
    const [disablebody, setDisablebody] = useState(false)


    let options = [];
    let optionstype = [{value: "binary", label: "Binary"}, {value: "multiclass", label: "Multiclass"}, {value: "regression", label: "Regression"}];
    let options3 = [];
    let optionsstep = [{value: "1", label: "1"},{value: "2", label: "2"},{value: "3", label: "3"},{value: "4", label: "4"},{value: "5", label: "5"},{value: "10", label: "10"},{value: "15", label: "15"},{value: "20", label: "20"},];
    
    
    GlobalState.dataset?.map((n, id)=>
        options = [...options, { value: n.id, label: n.name }]
    )

    const Countamoda = (a) =>
    {
        if(a>0&&a<=20)
        {
            setCountmodal(a)
        }
        else if(a=='')
        {
            setCountmodal(a)
        }
    }
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: 'rgba(255, 255, 255, 1)',
            display: 'flex',
            flexWrap: 'nowrap',
            width: 'calc(100% - 20px)',
            height: '38px',
            color: 'rgba(2, 2, 3, 1)',
            border: '1px solid rgba(255, 255, 255, 1)',
            boxShadow: 'none',
            fontSize: 16,
            '&:hover': {
                border: '1px solid rgba(245, 197, 56, 1)',
            },
        }),
        
        menu: (provided) => ({
            ...provided,
            background: 'rgba(255, 255, 255, 1)',
            width: 'calc(100% - 20px)',
            marginTop: '0px',
            boxShadow: 'none',
            cursor: 'pointer',
            color: 'rgba(2, 2, 3, 0.6)',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 16,
            paddingLeft: 0,
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'rgba(2, 2, 3, 1)',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
    };
    const DropdownIndicator = props => {
        return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {
                props.selectProps.menuIsOpen ? 
                    
                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="6.8451" cy="9.46229" r="1.53846" fill="#020203"/>
                        <circle cx="6.8451" cy="9.46229" r="1.53846" fill="black" fill-opacity="0.2"/>
                        <circle cx="6.8451" cy="9.46229" r="1.53846" fill="black" fill-opacity="0.2"/>
                        <path d="M0.691406 7.15385L7.03173 1L12.9991 7.15385" stroke="#020203"/>
                        <path d="M0.691406 7.15385L7.03173 1L12.9991 7.15385" stroke="black" stroke-opacity="0.2"/>
                        <path d="M0.691406 7.15385L7.03173 1L12.9991 7.15385" stroke="black" stroke-opacity="0.2"/>
                    </svg>
                    : 
                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30786 3.3584)" fill="#020203"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30786 3.3584)" fill="black" fill-opacity="0.2"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30786 3.3584)" fill="black" fill-opacity="0.2"/>
                        <path d="M0.692383 4.1274L7.03271 10.2812L13.0001 4.1274" stroke="#020203"/>
                        <path d="M0.692383 4.1274L7.03271 10.2812L13.0001 4.1274" stroke="black" stroke-opacity="0.2"/>
                        <path d="M0.692383 4.1274L7.03271 10.2812L13.0001 4.1274" stroke="black" stroke-opacity="0.2"/>
                    </svg>
                }
            </components.DropdownIndicator>
        )
        );
    };
    const changecategorytarget = (val) =>{
        
        var op = [];
        GlobalState.dataset.map((n, id)=>{
            n.id==val?
            Object.entries(n?.columnNames).map(([key, value])=>{
                op = [...op, { value: value, label: value}]
            })
            :
            <></>
        })
        setOptiontarget(val);
        setOptionfields(op);
    }
    const [textloading, setTextloading] = useState(langv?.add_model?.text18);
    const [textloadingcompleated, setTextloadingcompleated] = useState(langv?.add_model?.text19);
    const startmodel = () =>{
        createModel();
    }
    async function getModels()
    {
        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `model`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        if(response.status=="200")
        {
            const data = await response.json();
         
            if(data&&data.items.length>0)
            {
                data.items?.map((n)=>
                {
                    addModels(n)
                })
                setLoad(2);
                setTimeout(function(){
                    setAddmodel(false);
                }, 2000)
                
            }
            
        }
        else
        {
            const user = Pool.getCurrentUser();
            user.getSession((err, session) => {
                if (err) {
                    console.error("Error fetching user session:", err);                       
                } 
                else 
                {
                    if (!session) {
                        console.log("No session found");
                        return;
                    }
                    else
                    {
                        const refreshToken  = session.getRefreshToken();
                        user.refreshSession(refreshToken, (err, result) => {
                            const { accessToken } = result;
                            localStorage.setItem("token", accessToken.jwtToken);
                            getModels();
                        });
                    }
                    
                }
            })
        }
    } 
    const addmodel = () =>{
        changepopupaddAttachmentAnalitics(0)
    }
    
    const adddatasetprewWorkspacecheck = async () => {

        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `dataset/`+GlobalState.activemodel?.lastTrain?.featureDataset+`/preview`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        
        if(response.status=="200")
        {
            const data = await response.json();
           
            changeActiveModeldatasetPrew(data.sample.slice(0, 3));
        } 
    }
    async function getModel(activemodel)
    {

        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `model/`+activemodel, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        if(response.status=="200")
        {
            const data = await response.json();
           
            
            if(data.lastTrain.state=="done")
            {
                changeActiveModel(data);
                setLoad(2);
                setTimeout(function(){
                    setLoad(3);
                }, 2000)
                adddatasetprewWorkspacecheck();
            }
            else if(data.lastTrain.state=="failed")
            {
                setLoad(1);
               
                setTimeout(function(){
                    setLoad(4);
                }, 2000)
            }
            else if(data.lastTrain.state=="in_progress")
            {
                changeActiveModel(data);
                setTimeout(function(){
                    getModel(activemodel)
                }, 30000)
                //changeActiveModel(data);
                setLoad(1);
            }
            else{
                
                setTimeout(function(){
                    getModel(activemodel)
                }, 30000)
                setLoad(1);
                
            }
            
        }
       
    } 
    const retrain = async () =>{
        if(GlobalState.activemodel?.lastTrain?.deploymentState=='done'||GlobalState.activemodel?.lastTrain?.deploymentState=='in_progress')
        {

        }
        else
        {
            setDisablebut(false)
            setLoad(0);
        }
      
        
    }

    const createModel = async () =>{

        setLoad(1);
        var token = localStorage.getItem("token");
        var model = {
            featureDataset: optionmetrics,
            targetDataset: optiontarget,
            targetColumnName: optiontargetfields,
            learningType: optiontype,
            learningCyclesCount: countmodal,
            name: namemodal
        };

        const response = await fetch(Domen.url + `model`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(model),
        })
        if(response.status=="200")
        {
            const data = await response.json();
           
            getModel(data.id)
            
            
        }
       
    }
    const setAddmodel0 = ()=>{
        window.location.href='/analytics'
    }
    return (
        <div className='addmodel_block'>
           <div className='addmodel_head'>
                <div className='addmodel_head_tabs'>
                    <div className='addmodel_head_tab active'>
                        {
                            GlobalState.activemodel.modelName!=null?
                            <>{langv?.open_model?.text16}: {GlobalState.activemodel.modelName}</>
                            :
                            <>{langv?.open_model?.text17}</>
                        }
                        
                        
                    </div>  
                </div>
                <div className='controler_top'> 
                    <div className='betta'>
                        <div className='betta_inside'>
                        {langv?.beta?.head_text}
                        </div>
                    </div>
                    {
                        GlobalState.activemodel.modelName!=null?
                        <div className='buttons_line_v'>
                            <div className={GlobalState.activemodel?.lastTrain?.deploymentState=='done'||GlobalState.activemodel?.lastTrain?.deploymentState=='in_progress'?'button_retrain inactive':'button_retrain'} onClick={()=>retrain()}>
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.6956 2.82349L15.8737 3.0029L16.0511 2.82291L16.7705 2.0932C16.7707 2.09299 16.7709 2.09278 16.7711 2.09257C17.3228 1.54261 18.2695 1.93632 18.2695 2.71488V6.75267C18.2695 7.2329 17.875 7.6274 17.3934 7.6274H13.3505C12.5746 7.6274 12.1845 6.68463 12.7389 6.131L14.4393 4.43265L14.6156 4.25657L14.4402 4.07969C13.1805 2.81 11.4513 2.00374 9.51033 2.00374H9.5101C8.45948 2.0047 7.42251 2.24154 6.47588 2.69677C5.52925 3.152 4.69717 3.81396 4.04121 4.63373C3.38524 5.45349 2.92218 6.41007 2.68629 7.43273C2.45052 8.45486 2.44774 9.51684 2.67812 10.5402C3.22815 13.1318 5.31754 15.2418 7.91224 15.8144C9.47803 16.1796 11.122 15.9953 12.5678 15.2924C14.0134 14.5897 15.1728 13.4113 15.851 11.9553C15.9857 11.6796 16.2863 11.5016 16.6163 11.5016C17.2635 11.5016 17.6863 12.1737 17.4043 12.7634L17.404 12.764C16.6955 14.2558 15.5777 15.5162 14.1804 16.3988C12.7831 17.2815 11.1637 17.75 9.51033 17.75C4.3316 17.75 0.225969 13.2785 0.828369 7.97309L0.828415 7.97268C1.27699 3.96246 4.58448 0.702395 8.60067 0.298004L8.6018 0.297885C9.89815 0.161421 11.2086 0.317531 12.4365 0.754679C13.6643 1.19183 14.7781 1.89884 15.6956 2.82349Z" fill="#020203"/>
                                    <path d="M15.6956 2.82349L15.8737 3.0029L16.0511 2.82291L16.7705 2.0932C16.7707 2.09299 16.7709 2.09278 16.7711 2.09257C17.3228 1.54261 18.2695 1.93632 18.2695 2.71488V6.75267C18.2695 7.2329 17.875 7.6274 17.3934 7.6274H13.3505C12.5746 7.6274 12.1845 6.68463 12.7389 6.131L14.4393 4.43265L14.6156 4.25657L14.4402 4.07969C13.1805 2.81 11.4513 2.00374 9.51033 2.00374H9.5101C8.45948 2.0047 7.42251 2.24154 6.47588 2.69677C5.52925 3.152 4.69717 3.81396 4.04121 4.63373C3.38524 5.45349 2.92218 6.41007 2.68629 7.43273C2.45052 8.45486 2.44774 9.51684 2.67812 10.5402C3.22815 13.1318 5.31754 15.2418 7.91224 15.8144C9.47803 16.1796 11.122 15.9953 12.5678 15.2924C14.0134 14.5897 15.1728 13.4113 15.851 11.9553C15.9857 11.6796 16.2863 11.5016 16.6163 11.5016C17.2635 11.5016 17.6863 12.1737 17.4043 12.7634L17.404 12.764C16.6955 14.2558 15.5777 15.5162 14.1804 16.3988C12.7831 17.2815 11.1637 17.75 9.51033 17.75C4.3316 17.75 0.225969 13.2785 0.828369 7.97309L0.828415 7.97268C1.27699 3.96246 4.58448 0.702395 8.60067 0.298004L8.6018 0.297885C9.89815 0.161421 11.2086 0.317531 12.4365 0.754679C13.6643 1.19183 14.7781 1.89884 15.6956 2.82349Z" fill="black" fill-opacity="0.2"/>
                                    <path d="M15.6956 2.82349L15.8737 3.0029L16.0511 2.82291L16.7705 2.0932C16.7707 2.09299 16.7709 2.09278 16.7711 2.09257C17.3228 1.54261 18.2695 1.93632 18.2695 2.71488V6.75267C18.2695 7.2329 17.875 7.6274 17.3934 7.6274H13.3505C12.5746 7.6274 12.1845 6.68463 12.7389 6.131L14.4393 4.43265L14.6156 4.25657L14.4402 4.07969C13.1805 2.81 11.4513 2.00374 9.51033 2.00374H9.5101C8.45948 2.0047 7.42251 2.24154 6.47588 2.69677C5.52925 3.152 4.69717 3.81396 4.04121 4.63373C3.38524 5.45349 2.92218 6.41007 2.68629 7.43273C2.45052 8.45486 2.44774 9.51684 2.67812 10.5402C3.22815 13.1318 5.31754 15.2418 7.91224 15.8144C9.47803 16.1796 11.122 15.9953 12.5678 15.2924C14.0134 14.5897 15.1728 13.4113 15.851 11.9553C15.9857 11.6796 16.2863 11.5016 16.6163 11.5016C17.2635 11.5016 17.6863 12.1737 17.4043 12.7634L17.404 12.764C16.6955 14.2558 15.5777 15.5162 14.1804 16.3988C12.7831 17.2815 11.1637 17.75 9.51033 17.75C4.3316 17.75 0.225969 13.2785 0.828369 7.97309L0.828415 7.97268C1.27699 3.96246 4.58448 0.702395 8.60067 0.298004L8.6018 0.297885C9.89815 0.161421 11.2086 0.317531 12.4365 0.754679C13.6643 1.19183 14.7781 1.89884 15.6956 2.82349Z" fill="black" fill-opacity="0.2"/>
                                    <path d="M15.6956 2.82349L15.8737 3.0029L16.0511 2.82291L16.7705 2.0932C16.7707 2.09299 16.7709 2.09278 16.7711 2.09257C17.3228 1.54261 18.2695 1.93632 18.2695 2.71488V6.75267C18.2695 7.2329 17.875 7.6274 17.3934 7.6274H13.3505C12.5746 7.6274 12.1845 6.68463 12.7389 6.131L14.4393 4.43265L14.6156 4.25657L14.4402 4.07969C13.1805 2.81 11.4513 2.00374 9.51033 2.00374H9.5101C8.45948 2.0047 7.42251 2.24154 6.47588 2.69677C5.52925 3.152 4.69717 3.81396 4.04121 4.63373C3.38524 5.45349 2.92218 6.41007 2.68629 7.43273C2.45052 8.45486 2.44774 9.51684 2.67812 10.5402C3.22815 13.1318 5.31754 15.2418 7.91224 15.8144C9.47803 16.1796 11.122 15.9953 12.5678 15.2924C14.0134 14.5897 15.1728 13.4113 15.851 11.9553C15.9857 11.6796 16.2863 11.5016 16.6163 11.5016C17.2635 11.5016 17.6863 12.1737 17.4043 12.7634L17.404 12.764C16.6955 14.2558 15.5777 15.5162 14.1804 16.3988C12.7831 17.2815 11.1637 17.75 9.51033 17.75C4.3316 17.75 0.225969 13.2785 0.828369 7.97309L0.828415 7.97268C1.27699 3.96246 4.58448 0.702395 8.60067 0.298004L8.6018 0.297885C9.89815 0.161421 11.2086 0.317531 12.4365 0.754679C13.6643 1.19183 14.7781 1.89884 15.6956 2.82349Z" stroke="white" stroke-width="0.5"/>
                                </svg>
                                {langv?.open_model?.text14}
                            </div>
                            
                            <div className={GlobalState.activemodel?.lastTrain?.deploymentState=='done'||GlobalState.activemodel?.lastTrain?.deploymentState=='in_progress'?'button_retrain inactive':'button_retrain'} onClick={()=>addmodel()}> 
                                <svg width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.5 24L17 13.5H10.5V3L4 13.5H10.5V24Z" stroke="#1C1E20" stroke-opacity="0.6" stroke-width="1.5"/>
                                </svg>
                                {langv?.open_model?.text15}
                            </div>
                        </div>
                        :
                        <></>
                    }
                    
                    <div className='addmodel_head_back addmod' onClick={()=>setAddmodel0(false)}>
                    
                        
                        <svg className='def_icon' width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32.4226 15C32.7799 15.6188 32.7799 16.3812 32.4226 17L25.5773 28.8564C25.2201 29.4752 24.5598 29.8564 23.8453 29.8564L10.1547 29.8564C9.44017 29.8564 8.77991 29.4752 8.42265 28.8564L1.57735 17C1.22008 16.3812 1.22008 15.6188 1.57735 15L8.42265 3.14359C8.77991 2.52479 9.44017 2.14359 10.1547 2.14359L23.8453 2.14359C24.5598 2.14359 25.2201 2.52479 25.5774 3.14359L32.4226 15Z" fill="#F5C538" stroke="#F5C538"/>
                            <path d="M21.9492 11.0488L12.0497 20.9483" stroke="#020203" stroke-linecap="round"/>
                            <path d="M21.9492 11.0488L12.0497 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M21.9492 11.0488L12.0497 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M12.0508 11.0488L21.9503 20.9483" stroke="#020203" stroke-linecap="round"/>
                            <path d="M12.0508 11.0488L21.9503 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M12.0508 11.0488L21.9503 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                        </svg>
                        <svg className='hov_icon' width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32.4226 15C32.7799 15.6188 32.7799 16.3812 32.4226 17L25.5773 28.8564C25.2201 29.4752 24.5598 29.8564 23.8453 29.8564L10.1547 29.8564C9.44017 29.8564 8.77991 29.4752 8.42265 28.8564L1.57735 17C1.22008 16.3812 1.22008 15.6188 1.57735 15L8.42265 3.14359C8.77991 2.52479 9.44017 2.14359 10.1547 2.14359L23.8453 2.14359C24.5598 2.14359 25.2201 2.52479 25.5774 3.14359L32.4226 15Z" fill="#41A8B1" stroke="#41A8B1"/>
                            <path d="M21.9492 11.0488L12.0497 20.9483" stroke="#020203" stroke-linecap="round"/>
                            <path d="M21.9492 11.0488L12.0497 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M21.9492 11.0488L12.0497 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M12.0508 11.0488L21.9503 20.9483" stroke="#020203" stroke-linecap="round"/>
                            <path d="M12.0508 11.0488L21.9503 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M12.0508 11.0488L21.9503 20.9483" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                        </svg>

                    </div>
                   
                </div>
           </div>
           <div className='addmodel_body'>
                <div className='addmodel_fields'>
                    <div className='addmodel_fields_line'>
                        <div className='addmodel_fields_line_title'>{langv?.open_model?.text18}</div>
                        <div className='icon_disable' onClick={()=>setDisabletitle(!disabletitle)}>
                            {
                                !disabletitle?
                               
                                <svg className='def_icon' width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.4">
                                    <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="#020203"/>
                                    <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="black" fill-opacity="0.2"/>
                                    <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.61523 3.07617)" fill="black" fill-opacity="0.2"/>
                                    <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="#020203"/>
                                    <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="black" stroke-opacity="0.2"/>
                                    <path d="M1 3.84615L7.34033 10L13.3077 3.84615" stroke="black" stroke-opacity="0.2"/>
                                    </g>
                                </svg>
                                
                                :
                                
                                <svg className='def_icon' width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.4">
                                    <circle cx="7.1537" cy="9.46229" r="1.53846" fill="#020203"/>
                                    <circle cx="7.1537" cy="9.46229" r="1.53846" fill="black" fill-opacity="0.2"/>
                                    <circle cx="7.1537" cy="9.46229" r="1.53846" fill="black" fill-opacity="0.2"/>
                                    <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="#020203"/>
                                    <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="black" stroke-opacity="0.2"/>
                                    <path d="M1 7.15385L7.34033 1L13.3077 7.15385" stroke="black" stroke-opacity="0.2"/>
                                    </g>
                                </svg>
                                
                            }
                            

                            
                        </div>
                    </div>
                    {

                        disabletitle?
                        <></>
                        :
                        <>
                        <div className='addmodel_fields_line' style={{marginTop: "24px", position: "relative", zIndex: "99999999"}}>
                            <div className='addmodel_fields_col'>
                                <label>{langv?.add_model?.text4}</label>
                                <input
                                    id="source"
                                    type="text"
                                    placeholder={langv?.add_model?.text5}
                                    className=""
                                    value={namemodal}
                                    onChange={(event) =>setNamemodal(event.target.value)}
                                />
                            </div>
                            <div className='addmodel_fields_col'>
                                <label>{langv?.add_model?.text20}</label>
                                {/* <input
                                    id="source"
                                    type="text"
                                    placeholder={langv?.add_model?.text6}
                                    className=""
                                    value={countmodal}
                                    onChange={(event) => Countamoda(event.target.value)}
                                /> */}
                                <Select 
                                    components={{ DropdownIndicator }}
                                    options={optionsstep} 
                                    styles={customStyles} 
                                    classNamePrefix='filter'
                                    className='selectproduct'
                                    isSearchable={false} 
                                    placeholder={<div className='selectplace active'>{countmodal||langv?.open_model?.text9}</div>}
                                    onChange={(option) => Countamoda(option.value)}
                                />
                            </div>
                            <div className='addmodel_fields_col' style={{position: "relative", zIndex: "999999999999999"}}>
                                <label>{langv?.add_model?.text7}</label>
                                <Select 
                                    components={{ DropdownIndicator }}
                                    options={optionstype} 
                                    styles={customStyles} 
                                    classNamePrefix='filter'
                                    className='selectproduct'
                                    isSearchable={false} 
                                    placeholder={<div className='selectplace active'>{langv?.add_model?.text8}</div>}
                                    onChange={(option) => setOptiontype(option.value)}
                                />
                            </div>
                        </div>
                        <div className='addmodel_fields_line' style={{marginTop: "20px"}}>
                            <div className='addmodel_fields_col'>
                                <label>{langv?.add_model?.text9}</label>
                                <Select 
                                    components={{ DropdownIndicator }}
                                    options={options} 
                                    styles={customStyles} 
                                    classNamePrefix='filter'
                                    className='selectproduct'
                                    isSearchable={false} 
                                    placeholder={<div className='selectplace active'>{langv?.add_model?.text10}</div>}
                                    onChange={(option) => setOptionmetrics(option.value)}
                                />
                            </div>
                            <div className='addmodel_fields_col'>
                                <label>{langv?.add_model?.text11}</label>
                                <Select 
                                    components={{ DropdownIndicator }}
                                    options={options} 
                                    styles={customStyles} 
                                    classNamePrefix='filter'
                                    className='selectproduct'
                                    isSearchable={false} 
                                    placeholder={<div className='selectplace active'>{langv?.add_model?.text12}</div>}
                                    onChange={(option) => changecategorytarget(option.value)}
                                />
                            </div>
                            <div className='addmodel_fields_col'>
                                <label>{langv?.add_model?.text13}</label>
                                <Select 
                                    components={{ DropdownIndicator }}
                                    options={optionfields} 
                                    styles={customStyles} 
                                    classNamePrefix='filter'
                                    className='selectproduct'
                                    isSearchable={false} 
                                    placeholder={<div className='selectplace active'>{langv?.add_model?.text14}</div>}
                                    onChange={(option) => setOptiontargetfields(option.value)}
                                />
                            </div>
                        </div>
                        <div className='addmodel_fields_line' style={{marginBottom:"0px", marginTop: "20px"}}>
                            
                                {
                                    load==0?
                                    <>
                                        <div className='addmodel_fields_col'></div>
                                        <div className='addmodel_fields_col'>
                                            <div className='addmodel_button_line' >
                                                <div className='addmodel_but' onClick={()=>startmodel()}>
                                                {langv?.open_model?.text17}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='addmodel_fields_col'></div>
                                    </>
                                    :
                                
                                    <div className='line_detailedeploy'>
                                        
                                        {
                                            GlobalState.activemodel?.lastTrain?.inputDatasetName||GlobalState.activemodel?.lastTrain?.outputDatasetName!=null?
                                            <>
                                                <label>{langv?.open_model?.text19}</label>
                                                <div className='detailedeploy'>
                                                    
                                                    {
                                                        GlobalState.activemodel?.lastTrain?.deploymentSchedule!=null?
                                                        <>{langv?.open_model?.text20}: {GlobalState.activemodel?.lastTrain?.deploymentSchedule}<br/></>
                                                        :
                                                        <></>
                                                    }
                                                    {
                                                        GlobalState.activemodel?.lastTrain?.deploymentSchedule!=null?
                                                        <>{langv?.open_model?.text21}: {GlobalState.activemodel?.lastTrain?.deploymentSchedule}<br/></>
                                                        :
                                                        <></>
                                                    }
                                                    {
                                                        GlobalState.activemodel?.lastTrain?.deploymentSchedule!=null?
                                                        <>{langv?.open_model?.text22} {GlobalState.activemodel?.lastTrain?.deploymentSchedule.split('T')[0]+" "+GlobalState.activemodel?.lastTrain?.deploymentSchedule.split('T')[1].split(".")[0]} {GlobalState.activemodel?.lastTrain?.deploymentPeriodicity}</>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        
                                    </div> 
                                }
                            
                        </div>
                        </>
                    }
                </div>
           </div>
           
            
            {
                load==1?
                <Loading text = {textloading}/>
                :
                    load==2?
                    <LoadingCompleted text = {textloadingcompleated}/>
                    :   
                        load==4?
                        <LoadingFail text = {textloadingcompleated}/>
                        :
                            load==3?
                            <>
                                {
                                    GlobalState.activemodel?.lastTrain?.learningType=="binary"?
                                    
                                    <div className='blok_metrix'>
                                        <div className='addmodel_fields_line' style={{marginBottom:'0px'}}>
                                            <div className='addmodel_fields_line_title'>{langv?.open_model?.text23}</div>
                                        </div>
                                        <Accuracy/>
                                        <Contop/>
                                        <TabsTarget/>
                                        <Metric/>
                                        <Priview/>
                                        
                                    </div>
                                    :
                                    <>      
                                    </>       
                                }
                                {
                                    GlobalState.activemodel?.lastTrain?.learningType=="multiclass"?
                                    <div className='blok_metrix'>
                                        <div className='addmodel_fields_line'>
                                            <div className='addmodel_fields_line_title'>{langv?.open_model?.text23}</div>
                                        </div>
                                        <Accuracy/>
                                        <Contop/>
                                        <TabsTarget/>
                                        <Metric/>
                                        <Priview/>
                                        
                                    </div>
                                    :
                                    <>
                                        
                                    </>       
                                }
                                {
                                    GlobalState.activemodel?.lastTrain?.learningType=="regression"?
                                    <div className='blok_metrix'>
                                    <div className='addmodel_fields_line'>
                                        <div className='addmodel_fields_line_title'>{langv?.open_model?.text23}</div>
                                    </div>
                                        <Fourblock/>
                                        <TopField/>
                                        <Metric/>
                                        <Priview/>
                                        <div className='buttons_line'>
                                            <div className='buttons_line_v'>
                                                <div className='button_retrain'>
                                                {langv?.add_model?.text16}
                                                </div>
                                                
                                                <div className='button_retrain' onClick={()=>addmodel()}> 
                                                {langv?.add_model?.text17}
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    :
                                    <>
                                    </>       
                                }
                            </>
                            :
                            <></>
            }
           
        </div>
    );
})

export default Addmodels;
