import React, {useState} from 'react';
import './Analytics.css';
import { observer } from 'mobx-react';
import Sidebar from '../../components/sidebar';
import Dataset from '../../components/dataset/index.js'
import Models from '../../components/models/index.js'
import GlobalState from "../../stores/GlobalState";
import PopupDeploymentAnalitics from '../../components/dataset/popupDeploymentAnalitics.js';
import PopupAddDataset from '../../components/dataset/popupAddDataset.js';
import PopupAttachment from '../../components/dataset/popupAttachment';
import PopupDeleteModel from '../../components/models/popupDeleteModel';
import {Helmet} from "react-helmet";
const Analytics= observer(() => {
    const [holdBlock, setholdBlock] = useState(true);
    const [datasegment, setDatasegment] = useState(1);
    const changemenu = () =>{
        setholdBlock(false)
    }
    window.onbeforeunload = () => {
        setholdBlock(true)
    }
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    return (
        <>
        {
            langmenu=="de"?
            <Helmet>
                <title>Bind Genius — Vorhersage von Nutzerverhalten</title>
                <meta name="description" content="Bind Genius — eine No-Code-Lösung für maschinelles Lernen. Entscheidungsfindung auf Basis einer tiefgehenden Analyse historischer Ereignisse und Prognosen." />
            </Helmet>
            :
            <Helmet>
                <title>Bind Genius — Customer Behavior Prediction</title>
                <meta name="description" content="Bind Genius — a no-code machine learning solution. Decision-making based on in-depth analysis of historical events and forecasting." />
            </Helmet>
        }
        <Sidebar/>
        <div className="segmentation models hold">
           
            <div className='body_segment'>
                <Models/>
                
            </div>
        </div>
        {GlobalState.ispopupaddAttachmentShowAnalitics?<PopupDeploymentAnalitics/>:<></>}
        {GlobalState.ispopupadddatasetShow?<PopupAddDataset/>:<></>}
        {GlobalState.ispopupaddAttachmentShow?<PopupAttachment/>:<></>}
        {GlobalState.ispopupdeleteModelShow ?<PopupDeleteModel/>:<></>}
        </>
    );
})

export default Analytics;

