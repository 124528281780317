import React, {useState} from 'react';
import { observer } from 'mobx-react'
import Select, { components } from 'react-select'
import './index.css';
import GlobalState, {addmessage, changepopupsavenewmessage, addnewmessageemptycompany, changepopupNewMessage} from "../../stores/GlobalState";
import Pool from "../autorize/UserPool";
import Domen from "../../stores/Domen";
import lang from "../../lang"
const PopupAddNewMessage = observer(() =>{

    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [flag, setFlag] = useState(false)

    const [sel1, setSel1] = useState("")
    const [sel2, setSel2] = useState("")
    const [sel3, setSel3] = useState("")
    const [inp, setInp] = useState("")



    let optionsCompaings2 = [
        {value: "Professional", label: "Professional"}, 
        {value: "Informative", label: "Informative"},
        {value: "Testimonial", label: "Testimonial"},
        {value: "Solution-oriented", label: "Solution-oriented"},
        {value: "Emotional", label: "Emotional"},
        {value: "Casual", label: "Casual"},
        {value: "None", label: "None"}
    ];
    let optionsCompaings3 = [
        {value: "AIDA(Attention-Interest-Desire-Action)", label: "AIDA(Attention-Interest-Desire-Action)"}, 
        {value: "PAS(Problem-Agitation-Solution)", label: "PAS(Problem-Agitation-Solution)"},
        {value: "BAB(Before-After-Bridge)", label: "BAB(Before-After-Bridge)"},
        {value: "PPPP(Promise-Picture-Proof-Push)", label: "PPPP(Promise-Picture-Proof-Push)"},
        {value: "AICPBSAWN(Action Interest Credibility Proof Benefits Scarcity Action Warn)", label: "AICPBSAWN(Action Interest Credibility Proof Benefits Scarcity Action Warn)"},
        {value: "None", label: "None"}
    ];
    let optionsCompaings4 = [
        {value: "Personal connection (Emotional conection through empathy)", label: "Personal connection (Emotional conection through empathy)"}, 
        {value: "Simple (Short simple and to the point)", label: "Simple (Short simple and to the point)"},
        {value: "Wild intro (Weird intros to hook readers in)", label: "Wild intro (Weird intros to hook readers in)"},
        {value: "Creative hook (creative intros to hook readers in)", label: "Creative hook (creative intros to hook readers in)"},
        {value: "Personal transformation (Emotional connection through transformation personal story)", label: "Personal transformation (Emotional connection through transformation personal story)"},
        {value: "None", label: "None"}
    ];
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            display: 'flex',
            flexWrap: 'nowrap',
            width: '314px',
            height: '58px',
            minHeight: '34px',
            marginRight:"16px",
            marginBottom:"24px",
            fontSize: '12px',
            color: 'rgba(245, 197, 56, 1)',
            border: "0px",
            borderRadius: "8px",
            background: "rgba(45, 50, 57, 1)",
            boxShadow: 'none',
            cursor:"pointer"
        }),
        
        menu: (provided) => ({
            ...provided,
            background: "rgba(45, 50, 57, 1)",
            border: "0px ",
            color: 'rgba(2, 2, 3, 0.6)',
            width: '314px',
            marginTop: '-5px',
            boxShadow: 'none',
            cursor: 'pointer',
            zIndex: "9999999"
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 12,
            color: state.isSelected ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 1)',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
            cursor: "pointer"
    };
    const DropdownIndicator = props => {
        return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {
                props.selectProps.menuIsOpen ? 
                    
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="12" r="2" fill="white"/>
                        <path d="M1 9L9.24242 1L17 9" stroke="white"/>
                    </svg>
                    : 
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 7 4)" fill="white"/>
                        <path d="M1 5L9.24242 13L17 5" stroke="white"/>
                    </svg>
                }
            </components.DropdownIndicator>
        )
        );
    };
    const changepopupAddNewMessage = () =>{
        
        if(sel1!=''&&sel2!=''&&sel3!=''&&inp!='')
        {
            var mass = [];
            mass.push(sel1);
            mass.push(sel2);
            mass.push(sel3);
            mass.push(inp);
            addnewmessageemptycompany(mass);
            changepopupNewMessage(GlobalState.idchangecompany)
            changepopupsavenewmessage();
        }
        
            
    }
    const setSel01 = (a)=>{
        if(sel1!=''&&sel2!=''&&sel3!=''&&inp!='')
        {
            setFlag(true)
        }
        setSel1(a)
    }
    const setSel02 = (a)=>{
        if(sel1!=''&&sel2!=''&&sel3!=''&&inp!='')
        {
            setFlag(true)
        }
        setSel2(a)
    }
    const setSel03 = (a)=>{
        if(sel1!=''&&sel2!=''&&sel3!=''&&inp!='')
        {
            setFlag(true)
        }
        setSel3(a)
    }
    const setInp0 = (a)=>{
        if(sel1!=''&&sel2!=''&&sel3!=''&&inp!='')
        {
            setFlag(true)
        }
        setInp(a)
    }
    return (
        <div className="popupInfofon">
            <div className="popupInfo popupInfo_addnewmessage">
                <div className='top-element'>
                    <div className='namepopup'>
                        {langv?.groupsettings?.popupaddproduct?.text6}
                        
                    </div>
                    {/* <div className='separator'></div> */}
                    <div></div>
                    <div className='buttonspopup'>
                        <div className={flag?'buttonsave active':'buttonsave'} onClick={()=>changepopupAddNewMessage()}>{langv?.groupsettings?.popupaddproduct?.text2}</div>
                        <div className='buttonexit' onClick={changepopupNewMessage}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='form-element form_addnewellement'>
                    <div className='line_addnewmessage'>
                        <Select 
                            components={{ DropdownIndicator }}
                            options={optionsCompaings2} 
                            styles={customStyles} 
                            classNamePrefix='filter'
                            className=' select_message_Style'
                            isSearchable={false} 
                            placeholder={<div className='selectplace'>{langv?.groupsettings?.popupaddproduct?.text7}</div>}
                            onChange={(option) =>  setSel01(option.value)}
                        />
                    </div>
                    <div className='line_addnewmessage'>
                        <Select 
                            components={{ DropdownIndicator }}
                            options={optionsCompaings4} 
                            styles={customStyles} 
                            classNamePrefix='filter'
                            className='select_message_Style'
                            isSearchable={false} 
                            placeholder={<div className='selectplace'>{langv?.groupsettings?.popupaddproduct?.text8}</div>}
                            onChange={(option) =>  setSel02(option.value)}
                        />
                    </div>
                    <div className='line_addnewmessage'>
                        <Select 
                            components={{ DropdownIndicator }}
                            options={optionsCompaings3} 
                            styles={customStyles} 
                            classNamePrefix='filter'
                            className='select_message_Style'
                            isSearchable={false} 
                            placeholder={<div className='selectplace'>{langv?.groupsettings?.popupaddproduct?.text9}</div>}
                            onChange={(option) =>  setSel03(option.value)}
                        />
                    </div>
                   
                    <div className='line_addnewmessage'>
                        <input
                            id="workspacename"
                            type="text"
                            placeholder="Type Signature"
                            className="line_input_mess input_addnewmessage"
                            value={inp}
                            onChange={e=>setInp0(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PopupAddNewMessage;
