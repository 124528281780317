import React, {useState} from 'react';
import { observer } from 'mobx-react'
import Select, { components } from 'react-select'
import './index.css';
import GlobalState, {addmessage, changepopupsavenewmessage, changemessages} from "../../stores/GlobalState";
import Pool from "../autorize/UserPool";
import Domen from "../../stores/Domen";
import lang from "../../lang"
const PopupAddSaveMessage = observer(({setDatasegment, datasegment}) =>{

    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)

    const saveNewMessage = async()=>{
        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `campaign/`+GlobalState.idchangecompany+`/create_card`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        if(response.status>199&&response.status<399)
        {
            const data = await response.json();
            addmessage(data)
           
            const response2 = await fetch(Domen.url + `campaign/`+GlobalState.idchangecompany+`/cards`, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                }
            })
            if(response2.status>199&&response2.status<399)
            {
                const data2 = await response2.json();
                console.log(data2 + " ubtest")
                // data2[0].check=false
                // data2[0].message_name=GlobalState.newmessageemptycompany[3]
                // data2[0].user_parameters.writing_style=GlobalState.newmessageemptycompany[0]
                // data2[0].user_parameters.communication_format=GlobalState.newmessageemptycompany[1]
                // data2[0].user_parameters.copywriting_formula=GlobalState.newmessageemptycompany[2]
                changemessages(data2)
                
            }
            changepopupsavenewmessage();
            setDatasegment(GlobalState.idchangecompany);
            
        }
    }
    return (
        <div className="popupInfofon">
            <div className="popupInfo deleteProduct">
                <div className='top-element'>
                    {/* <div className='separator'></div> */}
                    <div></div>
                    <div className='buttonspopup'>
                        <div className='buttonexit' onClick={changepopupsavenewmessage}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='form-element'>
                    <div className='titlePopupAddNewMessage' dangerouslySetInnerHTML={{ __html: langv?.compaings?.popupdeletecompany?.text4 }}>
                        
                    </div>
                    <div className='buttonsPopupDelete'>
                        <div className='buttonPopupDelete' onClick={changepopupsavenewmessage}>
                            {langv?.compaings?.popupdeletecompany?.text2}
                        </div>
                        <div className='buttonPopupDelete' onClick={() =>saveNewMessage()}>
                            {langv?.compaings?.popupdeletecompany?.text3}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PopupAddSaveMessage;
