import React, {useState} from 'react';
import { observer } from 'mobx-react'
import Select, { components } from 'react-select'
import './index.css';
import GlobalState, {changeinfoproductsdescription, changeinfoproductsname,changeinfoproductstype, changepopupproduct} from "../../stores/GlobalState";
import lang from "../../lang"
const PopupInfo = observer(() =>{
    
    var id = GlobalState.curentIdProduct 
    const [name, setName] = useState(GlobalState.products[id].name);  
    const [type, setType] = useState(GlobalState.products[id].type);
    const [description, setDescription] = useState(GlobalState.products[id].desciption);
    const [flag, setFlag] = useState(0)
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const updateproduct = () =>
    {
        
        
        if(GlobalState.products[id].name!=''&&GlobalState.products[id].type)
        {
            changeinfoproductstype(id, type);
            changeinfoproductsdescription(id, description);
            changeinfoproductsname(id, name);
            changepopupproduct()
        }
        
    }

    const options = [
        { value: 'Product', label: 'Product' },
        { value: 'Tool', label: 'Tool' }
    ]

    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          background: 'rgba(28, 30, 32, 1)',
          display: 'flex',
          flexWrap: 'nowrap',
          width: '172px',
          color: '#fff',
          border: '0 !important',
          borderRadius: '8px',
         

          boxShadow: 'none',
            '&:hover': {
            border: '0 !important',
           
            },
            '&:focus': {
                border: '0 !important',
                
            },
            '&:active': {
                border: '0 !important',
               
            }
        }),
        
        menu: (provided) => ({
          ...provided,
          background: 'rgba(28, 30, 32, 1)',
          width: '172px',
          marginTop: '-2px',
          boxShadow: 'none',
          borderRadius: '0px',
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px"
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 15,
          
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'white',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
          }),
    };

    const DropdownIndicator = props => {
        return (
          components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {
                props.selectProps.menuIsOpen ? 
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="12" r="2" fill="white"/>
                        <path d="M1 9L9.24242 1L17 9" stroke="white"/>
                    </svg>
                    : 
                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 7 4.5)" fill="white"/>
                        <path d="M1 5.5L9.24242 13.5L17 5.5" stroke="white"/>
                    </svg> 
                }
            </components.DropdownIndicator>
          )
        );
    };
    
    const chname = (a) =>{
        setName(a)
        if(a!="")
        {
            setFlag(1);
        }
        else
        {
            setFlag(0);
        } 
    }
    const chtype = (a) =>{
        setType(a)
        setFlag(1);
    }
    const chdesk = (a) =>{
        setDescription(a);
        setFlag(1);
    }

    return (
        <div className="popupInfofon">
            <div className="popupInfo">
                <div className='top-element'>
                    <div className='namepopup'>
                    {langv?.groupsettings?.popupaddproduct?.text1}
                    </div>
                    {/* <div className='separator'></div> */}
                    <div></div>
                    <div className='buttonspopup'>
                        <div className={GlobalState.products[id].name===''||flag===0?'buttonsave':'buttonsave active'} onClick={()=>updateproduct()}>{langv?.groupsettings?.popupaddproduct?.text2}</div>
                        <div className='buttonexit' onClick={changepopupproduct}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='form-element'>
                    <div className='popup-inputs'>
                        
                        <Select 
                            components={{ DropdownIndicator }}
                            options={options} 
                            styles={customStyles} 
                            classNamePrefix='filter'
                            className='selectproduct'
                            isSearchable={false} 
                            placeholder={<div className='selectplace'>{type}</div>}
                            onChange={(option) => chtype(option.value)}
                        />
                        <input
                            id="website"
                            type="text"
                            placeholder={langv?.groupsettings?.popupaddproduct?.text4}
                            className="pop-input"
                            value={name}
                            onChange={(event) => chname(event.target.value)}
                        />
                    </div>
                    <div className='popup-textarea'>
                        <textarea placeholder={langv?.groupsettings?.popupaddproduct?.text5} className="pop-area" onChange={(event) => chdesk(event.target.value)}>{description}</textarea>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PopupInfo;
