import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import Nedopen from '../../assets/nedopen.svg'
import GlobalState, {
    changeinfocname, 
    changeinfowebsite, 
    changepopupinfo, 
    addpopupproduct, 
    changepopupproduct, 
    changeinfoproductsname,  
    changeinfodescriptions,
    changepopupdeleteProduct, 
    addinfoproduct,
    clearinfoproduct,
    deletecompaingproduct
} from "../../stores/GlobalState";
import lang from "../../lang"
import PopupDeleteProduct from './popupDeleteProduct'
import Pool from "../autorize/UserPool";
import Domen from "../../stores/Domen";

const GSettings = observer(() =>{
    const [deleteId, setdeleteId] = useState("");
    const [deleteName, setdeleteName] = useState("");
    const [productsline, setProductsline] = useState(GlobalState.products);
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    async function getData(){
        
        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `general_settings/`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        if(response.status=="200")
        {
            const data = await response.json();
            changeinfocname(data.company.company_name)
            changeinfowebsite(data.company.link)
            changeinfodescriptions(data.company.description)
            clearinfoproduct();
            data.products_tools.map((n)=>
                {
                    var product = {
                        type: n.type,
                        name: n.name,
                        description: n.description
                    };
                    if(n.status!=="Inactive")
                    {
                        addinfoproduct(product);
                    }
                    
                    return "";
                }
                
            )
            setProductsline(GlobalState.products);
        }
        else
        {
            const user = Pool.getCurrentUser();
            user.getSession((err, session) => {
                if (err) {
                    console.error("Error fetching user session:", err);                       
                } 
                else 
                {
                    if (!session) {
                        console.log("No session found");
                        return;
                    }
                    else
                    {
                        const refreshToken  = session.getRefreshToken();
                        user.refreshSession(refreshToken, (err, result) => {
                            const { idToken, refreshToken, accessToken } = result;
                            localStorage.setItem("token", accessToken.jwtToken);
                            getData();
                        });
                    }
                    
                }
            })
        }
    }
    
    const openPopupDeleteProduct = (id, name) =>{
        deletecompaingproduct(id, name)
        changepopupdeleteProduct();
    }
    
    useEffect(() => {
        getData()
    }, []);
    return (
        <div className="gsettings">
            <div className='block-gsettings'>
                <div className='form-gsettings' >
                    <h1>
                        {langv?.groupsettings?.main?.text1}
                    </h1>
                    <div className='line-input'>
                        <label>{langv?.groupsettings?.main?.text2}</label>
                        <input
                            id="cname"
                            type="text"
                            placeholder={langv?.groupsettings?.main?.text2}
                            className="gset-input"
                            value={GlobalState.cname}
                            disabled="true"
                            onChange={(event) => changeinfocname(event.target.value)}
                        />
                    </div>
                    <div className='line-input'>
                        <label>{langv?.groupsettings?.main?.text3}</label>
                        <input
                            id="website"
                            type="text"
                            placeholder={langv?.groupsettings?.main?.text3}
                            className="gset-input"
                            value={GlobalState.website}
                            disabled="true"
                            onChange={(event) => changeinfowebsite(event.target.value)}
                        />
                    </div>
                    <div className='nedopen' onClick={changepopupinfo}>
                        <svg className='arrowprod' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.6">
                                <path d="M20.1464 10.1464C20.4614 10.4614 21 10.2383 21 9.79289V3H14.2071C13.7617 3 13.5386 3.53857 13.8536 3.85355L16.29 6.29L6.29 16.29L3.85355 13.8536C3.53857 13.5386 3 13.7617 3 14.2071V21H9.79289C10.2383 21 10.4614 20.4614 10.1464 20.1464L7.71 17.71L17.71 7.71L20.1464 10.1464Z" fill="#1C1E20"/>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className='form-products'>
                    <h1>
                    {langv?.groupsettings?.main?.text4}
                    </h1>
                    <div className='line-input-mapproducts'>
                       
                        {
                            GlobalState.products?.map((n, id)=>
                                <div className='line_producta'>
                                    <div className='but_line_prod'>
                                        <svg className='arrowprod' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>changepopupproduct(id)}>
                                            <g opacity="0.6">
                                                <path d="M20.1464 10.1464C20.4614 10.4614 21 10.2383 21 9.79289V3H14.2071C13.7617 3 13.5386 3.53857 13.8536 3.85355L16.29 6.29L6.29 16.29L3.85355 13.8536C3.53857 13.5386 3 13.7617 3 14.2071V21H9.79289C10.2383 21 10.4614 20.4614 10.1464 20.1464L7.71 17.71L17.71 7.71L20.1464 10.1464Z" fill="#1C1E20"/>
                                            </g>
                                        </svg>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>openPopupDeleteProduct(id, n.name)}> 
                                            <path d="M18.4551 5.27441L5.90838 18.181" stroke="#EA4334" stroke-width="2" stroke-linecap="round"/>
                                            <path d="M5.72656 5.45312L18.6332 17.9998" stroke="#EA4334" stroke-width="2" stroke-linecap="round"/>
                                        </svg>
                                    </div>
                                    <div className='line-input pole_line_prod'>
                                        <label>{n.type}</label>
                                        <input
                                            id="website"
                                            type="text"
                                            placeholder="Website"
                                            className="gset-input"
                                            value={n.name}
                                            disabled="true"
                                            onChange={(event) => changeinfoproductsname(id, event.target.value)}
                                        />
                                    </div>
                                </div>
                               
                            )
                        }
                    </div>
                    <div className='line-input-buttonadd' onClick={addpopupproduct}>
                        <svg className='default-image' width="41" height="38" viewBox="0 0 41 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M39.4226 18.5C39.7799 19.1188 39.7799 19.8812 39.4226 20.5L30.8273 35.3875C30.4701 36.0063 29.8098 36.3875 29.0953 36.3875L11.9047 36.3875C11.1902 36.3875 10.5299 36.0063 10.1726 35.3875L1.57735 20.5C1.22008 19.8812 1.22008 19.1188 1.57735 18.5L10.1726 3.6125C10.5299 2.9937 11.1902 2.6125 11.9047 2.6125L29.0953 2.6125C29.8098 2.6125 30.4701 2.9937 30.8274 3.6125L39.4226 18.5Z" fill="#F5C538" stroke="#F5C538"/>
                            <path d="M21 10.6875V27.3125" stroke="#020203" stroke-linecap="round"/>
                            <path d="M12.6875 19H29.3125" stroke="#020203" stroke-linecap="round"/>
                        </svg>
                       
                        <svg className='hover-image' width="41" height="38" viewBox="0 0 41 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M39.4226 18.5C39.7799 19.1188 39.7799 19.8812 39.4226 20.5L30.8273 35.3875C30.4701 36.0063 29.8098 36.3875 29.0953 36.3875L11.9047 36.3875C11.1902 36.3875 10.5299 36.0063 10.1726 35.3875L1.57735 20.5C1.22008 19.8812 1.22008 19.1188 1.57735 18.5L10.1726 3.6125C10.5299 2.9937 11.1902 2.6125 11.9047 2.6125L29.0953 2.6125C29.8098 2.6125 30.4701 2.9937 30.8274 3.6125L39.4226 18.5Z" fill="#41A8B1" stroke="#41A8B1"/>
                            <path d="M21 10.6875V27.3125" stroke="#020203" stroke-linecap="round"/>
                            <path d="M21 10.6875V27.3125" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M21 10.6875V27.3125" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M12.6875 19H29.3125" stroke="#020203" stroke-linecap="round"/>
                            <path d="M12.6875 19H29.3125" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            <path d="M12.6875 19H29.3125" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                        </svg>


                        {langv?.groupsettings?.main?.text5}
                    </div>
                </div>
            </div>
            
           
        </div>
    );
});

export default GSettings;
