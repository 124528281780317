import React, {useState} from 'react';
import Select, { components } from 'react-select'
import { observer } from 'mobx-react'
import GlobalState, {addmessage, changemessages, gmessage, changemessagetext, changepopupdeleteMessage, changemessagesone, changeMesName, changeCondition, ChangeMessageType, changeTool, ChangeWritingStyle, ChangeCopywritingFormula, changeGoal, changeSignature, ChangeCommunicationFormat} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import lang from "../../lang"
import './index.css';
import Loading from '../common/loading';

const Messages = observer(({setDatasegment, datasegment, companyname}) => {
    
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [load, setload] = useState(2);
    const [textloading, setTextloading] = useState("Conten creation");

    let optionsCompaings = [{value: "Email", label: "Email"}, {value: "Push", label: "Push"}];
    let optionsCompaings2 = [
        {value: "Professional", label: "Professional"}, 
        {value: "Informative", label: "Informative"},
        {value: "Testimonial", label: "Testimonial"},
        {value: "Solution-oriented", label: "Solution-oriented"},
        {value: "Emotional", label: "Emotional"},
        {value: "Casual", label: "Casual"},
        {value: "None", label: "None"}
    ];
    let optionsCompaings3 = [
        {value: "AIDA(Attention-Interest-Desire-Action)", label: "AIDA(Attention-Interest-Desire-Action)"}, 
        {value: "PAS(Problem-Agitation-Solution)", label: "PAS(Problem-Agitation-Solution)"},
        {value: "BAB(Before-After-Bridge)", label: "BAB(Before-After-Bridge)"},
        {value: "PPPP(Promise-Picture-Proof-Push)", label: "PPPP(Promise-Picture-Proof-Push)"},
        {value: "AICPBSAWN(Action Interest Credibility Proof Benefits Scarcity Action Warn)", label: "AICPBSAWN(Action Interest Credibility Proof Benefits Scarcity Action Warn)"},
        {value: "None", label: "None"}
    ];
    let optionsCompaings4 = [
        {value: "Personal connection (Emotional conection through empathy)", label: "Personal connection (Emotional conection through empathy)"}, 
        {value: "Simple (Short simple and to the point)", label: "Simple (Short simple and to the point)"},
        {value: "Wild intro (Weird intros to hook readers in)", label: "Wild intro (Weird intros to hook readers in)"},
        {value: "Creative hook (creative intros to hook readers in)", label: "Creative hook (creative intros to hook readers in)"},
        {value: "Personal transformation (Emotional connection through transformation personal story)", label: "Personal transformation (Emotional connection through transformation personal story)"},
        {value: "None", label: "None"}
    ];
    const customStylesEmail = {
        control: (provided, state) => ({
            ...provided,
            display: 'flex',
            flexWrap: 'nowrap',
            width: '90px',
            height: '34px',
            minHeight: '34px',
            fontSize: '12px',
            color: 'rgba(2, 2, 3, 0.6)',
            border: "1px solid rgba(244, 245, 247, 1)",
            borderRadius: "8px",
            background: "rgba(255, 255, 255, 1)",
            boxShadow: 'none',
            '&:hover': {
                border: "1px solid rgba(244, 245, 247, 1)",
            },
            '&:focus': {
                border: "1px solid rgba(244, 245, 247, 1)",
            }
        }),
        
        menu: (provided) => ({
            ...provided,
            background: "rgba(255, 255, 255, 1)",
            border: "1px solid rgba(244, 245, 247, 1)",
            color: 'rgba(2, 2, 3, 0.6)',
            width: '90px',
            marginTop: '-5px',
            boxShadow: 'none',
            cursor: 'pointer',
            zIndex: "9999999"
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 12,
            color: state.isSelected ? 'rgba(2, 2, 3, 0.6)' : 'rgba(2, 2, 3, 0.6)',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
    };
    const customStylesEmailmessage = {
        control: (provided, state) => ({
            ...provided,
            display: 'flex',
            flexWrap: 'nowrap',
            width: '176px',
            height: '34px',
            minHeight: '34px',
            marginRight:"16px",
            fontSize: '12px',
            color: 'rgba(2, 2, 3, 0.6)',
            border: "1px solid rgba(244, 245, 247, 1)",
            borderRadius: "8px",
            background: "rgba(255, 255, 255, 1)",
            boxShadow: 'none',
            '&:hover': {
                border: "1px solid rgba(244, 245, 247, 1)",
            },
            '&:focus': {
                border: "1px solid rgba(244, 245, 247, 1)",
            }
        }),
        
        menu: (provided) => ({
            ...provided,
            background: "rgba(255, 255, 255, 1)",
            border: "1px solid rgba(244, 245, 247, 1)",
            color: 'rgba(2, 2, 3, 0.6)',
            width: '90px',
            marginTop: '-5px',
            boxShadow: 'none',
            cursor: 'pointer',
            zIndex: "9999999"
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 12,
            color: state.isSelected ? 'rgba(2, 2, 3, 0.6)' : 'rgba(2, 2, 3, 0.6)',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
    };
    const customStylesWritingStyle = {
        control: (provided, state) => ({
            ...provided,
            display: 'flex',
            flexWrap: 'nowrap',
            width: '352px',
            height: '34px',
            minHeight: '34px',
            fontSize: '12px',
            marginRight:"16px",
            color: 'rgba(2, 2, 3, 0.6)',
            border: "1px solid rgba(244, 245, 247, 1)",
            borderRadius: "8px",
            background: "rgba(255, 255, 255, 1)",
            boxShadow: 'none',
            '&:hover': {
                border: "1px solid rgba(244, 245, 247, 1)",
            },
            '&:focus': {
                border: "1px solid rgba(244, 245, 247, 1)",
            }
        }),
        
        menu: (provided) => ({
            ...provided,
            background: "rgba(255, 255, 255, 1)",
            border: "1px solid rgba(244, 245, 247, 1)",
            color: 'rgba(2, 2, 3, 0.6)',
            width: '338px',
            marginTop: '-7px',
            boxShadow: 'none',
            cursor: 'pointer',
            zIndex: "999999"
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 12,
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'rgba(2, 2, 3, 0.6)',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
    };
    const customStylesCopywritingFormula = {
        control: (provided, state) => ({
            ...provided,
            display: 'flex',
            flexWrap: 'nowrap',
            width: '176px',
            height: '34px',
            minHeight: '34px',
            fontSize: '12px',
            color: 'rgba(2, 2, 3, 0.6)',
            border: "1px solid rgba(244, 245, 247, 1)",
            borderRadius: "8px",
            background: "rgba(255, 255, 255, 1)",
            boxShadow: 'none',
            '&:hover': {
                border: "1px solid rgba(244, 245, 247, 1)",
            },
            '&:focus': {
                border: "1px solid rgba(244, 245, 247, 1)",
            }
        }),
        
        menu: (provided) => ({
            ...provided,
            background: "rgba(255, 255, 255, 1)",
            border: "1px solid rgba(244, 245, 247, 1)",
            color: 'rgba(2, 2, 3, 0.6)',
            width: '338px',
            marginTop: '-7px',
            boxShadow: 'none',
            cursor: 'pointer',
            zIndex: "999999"
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 12,
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'rgba(2, 2, 3, 0.6)',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
    };
    const customStylesStyle = {
        control: (provided, state) => ({
            ...provided,
            display: 'flex',
            flexWrap: 'nowrap',
            width: '176px',
            height: '34px',
            minHeight: '34px',
            fontSize: '12px',
            color: 'rgba(2, 2, 3, 0.6)',
            border: "1px solid rgba(244, 245, 247, 1)",
            borderRadius: "8px",
            background: "rgba(255, 255, 255, 1)",
            boxShadow: 'none',
            '&:hover': {
                border: "1px solid rgba(244, 245, 247, 1)",
            },
            '&:focus': {
                border: "1px solid rgba(244, 245, 247, 1)",
            }
        }),
        
        menu: (provided) => ({
            ...provided,
            background: "rgba(255, 255, 255, 1)",
            border: "1px solid rgba(244, 245, 247, 1)",
            color: 'rgba(2, 2, 3, 0.6)',
            width: '338px',
            marginTop: '-7px',
            boxShadow: 'none',
            cursor: 'pointer',
            zIndex: "999999"
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 12,
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'rgba(2, 2, 3, 0.6)',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
    };
    const customStylesStylFormatCommunication = {
        control: (provided, state) => ({
            ...provided,
            display: 'flex',
            flexWrap: 'nowrap',
            width: '352px',
            height: '34px',
            minHeight: '34px',
            fontSize: '12px',
            color: 'rgba(2, 2, 3, 0.6)',
            border: "1px solid rgba(244, 245, 247, 1)",
            borderRadius: "8px",
            background: "rgba(255, 255, 255, 1)",
            boxShadow: 'none',
            '&:hover': {
                border: "1px solid rgba(244, 245, 247, 1)",
            },
            '&:focus': {
                border: "1px solid rgba(244, 245, 247, 1)",
            }
        }),
        
        menu: (provided) => ({
            ...provided,
            background: "rgba(255, 255, 255, 1)",
            border: "1px solid rgba(244, 245, 247, 1)",
            color: 'rgba(2, 2, 3, 0.6)',
            width: '338px',
            marginTop: '-7px',
            boxShadow: 'none',
            cursor: 'pointer',
            zIndex: "999999"
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 12,
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'rgba(2, 2, 3, 0.6)',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
    };

    const DropdownIndicator = props => {
        return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {
                props.selectProps.menuIsOpen ? 
                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="#020203"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="black" fill-opacity="0.2"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="black" fill-opacity="0.2"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="#020203"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="black" stroke-opacity="0.2"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="black" stroke-opacity="0.2"/>
                    </svg>
                    : 
                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="#020203"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="black" fill-opacity="0.2"/>
                        <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="black" fill-opacity="0.2"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="#020203"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="black" stroke-opacity="0.2"/>
                        <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="black" stroke-opacity="0.2"/>
                    </svg>
                }
            </components.DropdownIndicator>
        )
        );
    };
    const handlecompaings = (e, id) => {
        // var result =  GlobalState.compaings.map((x)=>
        //     x.id===id? x.segmentname==e.target.value:<></>
        // )
        // changeChannelCompany(result);
    };
    const addmessages = async() =>
    {
        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `campaign/`+datasegment+`/create_card`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        if(response.status>199&&response.status<399)
        {
            const data = await response.json();
            
            addmessage(data.latest_message)
        }

        // if(GlobalState.gmessage!=true)
        // {
        //      addmessage({id:(GlobalState.messages.length+1), check: false})
        // }
    }
    const openMessagetext = async(id)=>{
        var token = localStorage.getItem("token");
        const response = await fetch( Domen.url + `campaign/messages/`+id, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
        if(response.status>199&&response.status<399)
        {
            const data = await response.json();

            if(data.status=='completed')
            {
                
                changemessagetext(data.id, data.generated_message)
                setload(2)
            }
            else{
                setTimeout(function(){
                    openMessagetext(id)
                }, 2000)
            }
            
        }
    }
    const generatemessage = async(id)=>{
        var token = localStorage.getItem("token");
        var product = {};
        setload(1)
        console.log(id)
        GlobalState.messages.map((n, i)=>
            
            n.id.includes(id)?
            product = {
                campaign_goal: n.user_parameters.campaign_goal ||  "string0",
                type_segment: "string0",
                message_type: n.user_parameters.message_type ||  "string0",
                writing_style: n.user_parameters.writing_style ||  "string0",
                copywriting_formula: n.user_parameters.copywriting_formula ||  "string0",
                communication_format: n.user_parameters.communication_format ||  "string0",
                tool: n.user_parameters.tool ||  "string0",
                react: n.user_parameters.react ||  "string0",
                signature: n.user_parameters.signature ||  "string0",
                company_name: n.user_parameters?.campaign_name ||  "string0",
                length_message: 200,
                language: langmenu=="de"?"german":"english"
            }
            :
            <></>
        )
        const response = await fetch( Domen.url + `campaign/generate_message/`+id, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(product),
        });
        if(response.status>199&&response.status<399)
        {
            openMessagetext(id)
        }
        
    }
    const openmes = async(id) =>{

       
            var token = localStorage.getItem("token");
            const response = await fetch(Domen.url + `campaign/`+id+`/cards`, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                }
            })
            if(response.status>199&&response.status<399)
            {
                const data = await response.json();
                changemessages(data);
                console.log(data.lenght)
                // data.lenght==undefined?setDatasegment(0):<></>
            }
            
        
    }
    const deletemessage = async(id) =>{


        changepopupdeleteMessage(id, datasegment)
        
        // var token = localStorage.getItem("token");
        // const response = await fetch( Domen.url + `campaign/messages/`+id, {
        //     method: 'DELETE',
        //     headers: {
        //         'Authorization': token,
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //     },
        // });
        // if(response.status>199&&response.status<399)
        // {
        //     openmes(datasegment)
            
        // }
    }
    const checkmessage = (id, type) =>{

        var mass = GlobalState.messages;
        mass.map((n, z)=>(
            n.id==id?n.check=type:<></>
        ))
        changemessagesone(mass)
    }
    const successfulmessage = (id, type) =>{
        var mass = [];
        GlobalState.messages.map((n, z)=>(
            z!=id?mass.push({id:n.id, check: n.check, successful: n.successful}):mass.push({id:n.id, check: type, successful: "true"})
        ))
        changemessages(mass)
    }
    const copytext = (text)=>{
        navigator.clipboard.writeText(text).then(function() {
        console.log('Текст успешно скопирован в буфер обмена');
        }, function(err) {
        console.error('Произошла ошибка при копировании текста: ', err);
        });
    }
    const setcloseparam = (a)=>{
        var mass = GlobalState.messages;
        mass.map((n, z)=>(
            n.id==a?
                n.closemesage==true?
                    n.closemesage=false:
                    n.closemesage=true
                    :
                <></>
        ))
        changemessagesone(mass)
    }
    return (
        <>
            <div className='top_line_message' >
                <div className='campaing_name'>
                {companyname||langv?.compaings?.messages?.text1}
                </div>
                <div className='back_message' onClick={()=>setDatasegment(0)}>
                    <svg  width="41" height="38" viewBox="0 0 41 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M39.4226 18.5C39.7799 19.1188 39.7799 19.8812 39.4226 20.5L30.8273 35.3875C30.4701 36.0063 29.8098 36.3875 29.0953 36.3875L11.9047 36.3875C11.1902 36.3875 10.5299 36.0063 10.1726 35.3875L1.57735 20.5C1.22008 19.8812 1.22008 19.1188 1.57735 18.5L10.1726 3.6125C10.5299 2.9937 11.1902 2.6125 11.9047 2.6125L29.0953 2.6125C29.8098 2.6125 30.4701 2.9937 30.8274 3.6125L39.4226 18.5Z" fill="#F5C538" stroke="#F5C538"/>
                        <ellipse cx="2.125" cy="2.15385" rx="2.125" ry="2.15385" transform="matrix(0 1 1 0 23.6914 17.375)" fill="#020203"/>
                        <ellipse cx="2.125" cy="2.15385" rx="2.125" ry="2.15385" transform="matrix(0 1 1 0 23.6914 17.375)" fill="black" fill-opacity="0.2"/>
                        <ellipse cx="2.125" cy="2.15385" rx="2.125" ry="2.15385" transform="matrix(0 1 1 0 23.6914 17.375)" fill="black" fill-opacity="0.2"/>
                        <path d="M22.6154 11L14 19.7576L22.6154 28" stroke="#020203" stroke-linecap="round"/>
                        <path d="M22.6154 11L14 19.7576L22.6154 28" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                        <path d="M22.6154 11L14 19.7576L22.6154 28" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                    </svg>
                </div>
                
            </div>
            <div className='but_add_message_line' onClick={()=>addmessages()}>
                <div className='but_add_message'>
                    <svg width="41" height="38" viewBox="0 0 41 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M39.4226 18.5C39.7799 19.1188 39.7799 19.8812 39.4226 20.5L30.8273 35.3875C30.4701 36.0063 29.8098 36.3875 29.0953 36.3875L11.9047 36.3875C11.1902 36.3875 10.5299 36.0063 10.1726 35.3875L1.57735 20.5C1.22008 19.8812 1.22008 19.1188 1.57735 18.5L10.1726 3.6125C10.5299 2.9937 11.1902 2.6125 11.9047 2.6125L29.0953 2.6125C29.8098 2.6125 30.4701 2.9937 30.8274 3.6125L39.4226 18.5Z" fill="#F5C538" stroke="#F5C538"/>
                        <path d="M21 10.6875V27.3125" stroke="#020203" stroke-linecap="round"/>
                        <path d="M21 10.6875V27.3125" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                        <path d="M21 10.6875V27.3125" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                        <path d="M12.6875 19H29.3125" stroke="#020203" stroke-linecap="round"/>
                        <path d="M12.6875 19H29.3125" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                        <path d="M12.6875 19H29.3125" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                    </svg>
                    {langv?.compaings?.messages?.text2}
                </div>
            </div>
            
                {
                    GlobalState.messages.slice().reverse().map((n, id)=>(
                        n.check=="true"?
                            n.status=="finishes"?
                            <div className='message_lines'>
                                <div className='minimessage successful' onClick={() =>checkmessage(id, "false")}>
                                    <div className='block_input'>
                                        <label>{langv?.compaings?.messages?.text3}</label>
                                        <input
                                            id="workspacename"
                                            type="text"
                                            placeholder={langv?.compaings?.messages?.text4}
                                            className="line_input_mess line_input_mess_name"
                                            value={n.message_name}
                                        />
                                    </div>
                                    <div className='block_input'>
                                        <label>{langv?.compaings?.messages?.text5}</label>
                                        <Select 
                                            components={{ DropdownIndicator }}
                                            options={optionsCompaings} 
                                            styles={customStylesEmail} 
                                            classNamePrefix='filter'
                                            className='select_message_email'
                                            isSearchable={false} 
                                            placeholder={<div className='selectplace'>{langv?.compaings?.messages?.text6}</div>}
                                            onChange={(option) => handlecompaings(option.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='message_lines'>
                                <div className='minimessage'>
                                    <div className='block_input'>
                                        <label>{langv?.compaings?.messages?.text7}</label>
                                        <input
                                            id="workspacename"
                                            type="text"
                                            placeholder={langv?.compaings?.messages?.text8}
                                            className="line_input_mess line_input_mess_name"
                                            value={n.user_parameters.react}
                                            onChange={e=>changeCondition(n.id, e.target.value)}
                                        />
                                    </div>
                                    <div className='block_input'>
                                        <label>{langv?.compaings?.messages?.text5}</label>
                                        <Select 
                                            components={{ DropdownIndicator }}
                                            options={optionsCompaings} 
                                            styles={customStylesEmail} 
                                            classNamePrefix='filter'
                                            className='select_message_email'
                                            isSearchable={false} 
                                            placeholder={<div className='selectplace'>{langv?.compaings?.messages?.text6}</div>}
                                            onChange={(option) => handlecompaings(option.value)}
                                        />
                                    </div>
                                    <div className='hover_minimessage'>
                                        <div>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                <path d="M16.8513 7.65668C17.2967 7.65668 17.5198 7.11811 17.2048 6.80313L12.4015 1.99983L7.59824 6.80313C7.28326 7.11811 7.50634 7.65668 7.95179 7.65668L11.3974 7.65668V21.7988L13.4056 21.7988L13.4056 7.65668L16.8513 7.65668Z" fill="#1C1E20"/>
                                                </g>
                                            </svg>
                                            {langv?.compaings?.messages?.text28}
                                          
                                        </div>
                                        <div>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                <path d="M16.8513 17.1461C17.2967 17.1461 17.5198 17.6846 17.2048 17.9996L12.4015 22.8029L7.59824 17.9996C7.28326 17.6846 7.50634 17.1461 7.95179 17.1461L11.3974 17.1461V3.00392L13.4056 3.00392L13.4056 17.1461L16.8513 17.1461Z" fill="#1C1E20"/>
                                                </g>
                                            </svg>
                                            {langv?.compaings?.messages?.text29}
                                            
                                        </div>
                                        <div onClick={() =>checkmessage(n.id, "false")}>
                                            <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                <path d="M20.1464 10.1464C20.4614 10.4614 21 10.2383 21 9.79289V3H14.2071C13.7617 3 13.5386 3.53857 13.8536 3.85355L16.29 6.29L6.29 16.29L3.85355 13.8536C3.53857 13.5386 3 13.7617 3 14.2071V21H9.79289C10.2383 21 10.4614 20.4614 10.1464 20.1464L7.71 17.71L17.71 7.71L20.1464 10.1464Z" fill="#1C1E20"/>
                                                </g>
                                            </svg>
                                            {langv?.compaings?.messages?.text30}
                                        </div>
                                        <div onClick={()=>deletemessage(n.id)}>
                                            <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.4551 5.27441L5.90838 18.181" stroke="#EA4334" stroke-width="2" stroke-linecap="round"/>
                                                <path d="M5.72656 5.45312L18.6332 17.9998" stroke="#EA4334" stroke-width="2" stroke-linecap="round"/>
                                            </svg>
                                            {langv?.compaings?.messages?.text31}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                        // <></>
                        <div className='message_lines'>
                            <div className={GlobalState.gmessage==true?'message_line messages_line':'message_line '}>
                                <div className='line_but_new_mess'>
                                    <div className='right_mes_but'>
                                        <div className='but_message_top' onClick={() =>checkmessage(n.id, "true")}>
                                            <svg className='active_but_message_top' width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.63927 2.1998C7.32428 1.88482 6.78571 2.1079 6.78571 2.55336L6.78571 5.71387L9.94623 5.71387C10.3917 5.71387 10.6148 5.1753 10.2998 4.86031L9.35715 3.91769L12.1464 1.12838C12.3417 0.933111 12.3417 0.616523 12.1464 0.421263L12.0783 0.353118C11.883 0.157864 11.5664 0.157868 11.3712 0.353128L8.5819 3.14243L7.63927 2.1998Z" fill="#020203"/>
                                                <path d="M7.63927 2.1998C7.32428 1.88482 6.78571 2.1079 6.78571 2.55336L6.78571 5.71387L9.94623 5.71387C10.3917 5.71387 10.6148 5.1753 10.2998 4.86031L9.35715 3.91769L12.1464 1.12838C12.3417 0.933111 12.3417 0.616523 12.1464 0.421263L12.0783 0.353118C11.883 0.157864 11.5664 0.157868 11.3712 0.353128L8.5819 3.14243L7.63927 2.1998Z" fill="black" fill-opacity="0.2"/>
                                                <path d="M7.63927 2.1998C7.32428 1.88482 6.78571 2.1079 6.78571 2.55336L6.78571 5.71387L9.94623 5.71387C10.3917 5.71387 10.6148 5.1753 10.2998 4.86031L9.35715 3.91769L12.1464 1.12838C12.3417 0.933111 12.3417 0.616523 12.1464 0.421263L12.0783 0.353118C11.883 0.157864 11.5664 0.157868 11.3712 0.353128L8.5819 3.14243L7.63927 2.1998Z" fill="black" fill-opacity="0.2"/>
                                                <path d="M5.36073 9.8002C5.67572 10.1152 6.21429 9.8921 6.21429 9.44664L6.21429 6.28613L3.05377 6.28613C2.60831 6.28613 2.38523 6.8247 2.70021 7.13969L3.64285 8.08231L0.853559 10.8716C0.658295 11.0669 0.6583 11.3835 0.853569 11.5787L0.921717 11.6469C1.11698 11.8421 1.43356 11.8421 1.62882 11.6469L4.4181 8.85757L5.36073 9.8002Z" fill="#020203"/>
                                                <path d="M5.36073 9.8002C5.67572 10.1152 6.21429 9.8921 6.21429 9.44664L6.21429 6.28613L3.05377 6.28613C2.60831 6.28613 2.38523 6.8247 2.70021 7.13969L3.64285 8.08231L0.853559 10.8716C0.658295 11.0669 0.6583 11.3835 0.853569 11.5787L0.921717 11.6469C1.11698 11.8421 1.43356 11.8421 1.62882 11.6469L4.4181 8.85757L5.36073 9.8002Z" fill="black" fill-opacity="0.2"/>
                                                <path d="M5.36073 9.8002C5.67572 10.1152 6.21429 9.8921 6.21429 9.44664L6.21429 6.28613L3.05377 6.28613C2.60831 6.28613 2.38523 6.8247 2.70021 7.13969L3.64285 8.08231L0.853559 10.8716C0.658295 11.0669 0.6583 11.3835 0.853569 11.5787L0.921717 11.6469C1.11698 11.8421 1.43356 11.8421 1.62882 11.6469L4.4181 8.85757L5.36073 9.8002Z" fill="black" fill-opacity="0.2"/>
                                            </svg>
                                            <svg className='hover_but_message_top' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.13927 2.20078C6.82428 1.8858 6.28571 2.10888 6.28571 2.55433L6.28571 5.71484L9.44623 5.71484C9.89169 5.71484 10.1148 5.17627 9.79979 4.86129L8.85715 3.91866L11.6464 1.12935C11.8417 0.934087 11.8417 0.617499 11.6464 0.422239L11.5783 0.354095C11.383 0.158841 11.0664 0.158845 10.8712 0.354105L8.0819 3.14341L7.13927 2.20078Z" fill="#F5C538"/>
                                                <path d="M4.86073 9.79922C5.17572 10.1142 5.71429 9.89112 5.71429 9.44567L5.71429 6.28516L2.55377 6.28516C2.10831 6.28516 1.88523 6.82373 2.20021 7.13871L3.14285 8.08134L0.353559 10.8706C0.158295 11.0659 0.1583 11.3825 0.353569 11.5778L0.421717 11.6459C0.616981 11.8412 0.933558 11.8412 1.12882 11.6459L3.9181 8.85659L4.86073 9.79922Z" fill="#F5C538"/>
                                            </svg>
                                        </div>
                                
                                        <div className='but_message_top bmt2' onClick={()=>deletemessage(n.id)}>
                                            <svg className='active_but_message_top' width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.6836 4.69824L4.31913 13.3026" stroke="#EA4334" stroke-width="1.5" stroke-linecap="round"/>
                                                <path d="M4.19727 4.81836L12.8017 13.1828" stroke="#EA4334" stroke-width="1.5" stroke-linecap="round"/>
                                            </svg>
                                            <svg className='hover_but_message_top' width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.668 4.69922L4.3035 13.3036" stroke="#F5C538" stroke-width="1.5" stroke-linecap="round"/>
                                                <path d="M4.18164 4.81836L12.786 13.1828" stroke="#F5C538" stroke-width="1.5" stroke-linecap="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {
                                    n.closemesage==true||n.closemesage==null||n.closemesage==undefined?
                                    <div className='line_top_mes'>
                                        <div className='line_top_mes_top'>
                                            <div className='mes_body_left'>
                                                <div className='message_body_line changecurs'>
                                                    <div className='block_input'>
                                                        <label>{langv?.compaings?.messages?.text3}</label>
                                                        <input
                                                            id="workspacename"
                                                            type="text"
                                                            placeholder={langv?.compaings?.messages?.text4}
                                                            className="line_input_mess line_input_mess_name"
                                                            value={n.user_parameters?.campaign_name}
                                                            onChange={e=>changeMesName(n.id, e.target.value)}
                                                        />
                                                    </div>

                                                    

                                                </div>
                                                <div className='message_body_line'>
                                                    <div className='block_input'>
                                                        <label>{langv?.compaings?.messages?.text5}</label>
                                                        <Select 
                                                            components={{ DropdownIndicator }}
                                                            options={optionsCompaings} 
                                                            styles={customStylesEmailmessage} 
                                                            classNamePrefix='filter'
                                                            className='select_message_email'
                                                            isSearchable={false} 
                                                            placeholder={<div className='selectplace'>{n.user_parameters.message_type||langv?.compaings?.messages?.text6}</div>}
                                                            onChange={(option) => ChangeMessageType(n.id, option.value)}
                                                            
                                                        />
                                                    </div>
                                                    <div className='block_input'>
                                                        <label>{langv?.compaings?.messages?.text7}</label>
                                                        <input
                                                            id="condition"
                                                            type="text"
                                                            placeholder={langv?.compaings?.messages?.text8}
                                                            className="line_input_mess line_input_mess_purpose"
                                                            value={n.user_parameters.react}//n.user_parameters.message_type}
                                                            onChange={e=>changeCondition(n.id, e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='message_body_line'>
                                                    <div className='block_input'>
                                                        <label>{langv?.compaings?.messages?.text9}</label>
                                                        <input
                                                            id="condition"
                                                            type="text"
                                                            placeholder={langv?.compaings?.messages?.text10}
                                                            className="line_input_mess line_input_mess_tool"
                                                            value={n.user_parameters.tool}
                                                            onChange={e=>changeTool(n.id, e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='message_body_line'>
                                                    <div className='block_input'>
                                                        <label>{langv?.compaings?.messages?.text17}</label>
                                                        <Select 
                                                            components={{ DropdownIndicator }}
                                                            options={optionsCompaings2} 
                                                            styles={customStylesWritingStyle} 
                                                            classNamePrefix='filter'
                                                            className='select_message_email select_message_Style'
                                                            isSearchable={false} 
                                                            placeholder={<div className='selectplace'>{n.user_parameters.writing_style||langv?.compaings?.messages?.text18}</div>}
                                                            onChange={(option) =>  ChangeWritingStyle(n.id, option.value)}
                                                        />
                                                    </div>
                                                    <div className='block_input'>
                                                        <label>{langv?.compaings?.messages?.text19}</label>
                                                        <Select 
                                                            components={{ DropdownIndicator }}
                                                            options={optionsCompaings3} 
                                                            styles={customStylesCopywritingFormula} 
                                                            classNamePrefix='filter'
                                                            className='select_message_email'
                                                            isSearchable={false} 
                                                            placeholder={<div className='selectplace'>{n.user_parameters.copywriting_formula||langv?.compaings?.messages?.text20}</div>}
                                                            onChange={(option) => ChangeCopywritingFormula(n.id, option.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mes_body_right'>
                                                <div className='message_body_line'>
                                                    <div className='block_input' onClick={()=>setcloseparam(n.id)} style={{position:"relative"}}>
                                                        <svg className='strelka' width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="#020203"/>
                                                            <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="black" fill-opacity="0.2"/>
                                                            <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1 0 0 -1 5.30664 3.07715)" fill="black" fill-opacity="0.2"/>
                                                            <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="#020203"/>
                                                            <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="black" stroke-opacity="0.2"/>
                                                            <path d="M0.691406 3.84615L7.03173 10L12.9991 3.84615" stroke="black" stroke-opacity="0.2"/>
                                                        </svg>
                                                        <label style={{opacity:"0",position:"relative",zIndex:"-1"}}>{langv?.compaings?.messages?.text13}</label>
                                                        <input
                                                            id="goala"
                                                            type="text"
                                                            placeholder={langv?.compaings?.messages?.text14}
                                                            className="line_input_mess line_input_mess_goal_input"
                                                            value={n.user_parameters.campaign_goal}
                                                            style={{opacity:"0",position:"relative",zIndex:"-1"}}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='message_body_line'>
                                                    <div className='block_input'>
                                                        <label>{langv?.compaings?.messages?.text13}</label>
                                                        <input
                                                            id="goal"
                                                            type="text"
                                                            placeholder={langv?.compaings?.messages?.text14}
                                                            className="line_input_mess line_input_mess_goal_input"
                                                            value={n.user_parameters.sequence_goal}
                                                            onChange={e=>changeGoal(n.id, e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='message_body_line'>
                                                    <div className='block_input'>
                                                        <label>{langv?.compaings?.messages?.text15}</label>
                                                        <input
                                                            id="condition"
                                                            type="text"
                                                            placeholder={langv?.compaings?.messages?.text16}
                                                            className="line_input_mess line_input_mess_goal_input"
                                                            value={n.user_parameters.signature}
                                                            onChange={e=>changeSignature(n.id, e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='block_input'>
                                                    <label>{langv?.compaings?.messages?.text21}</label>
                                                    <Select 
                                                        components={{ DropdownIndicator }}
                                                        options={optionsCompaings4} 
                                                        styles={customStylesStylFormatCommunication} 
                                                        classNamePrefix='filter'
                                                        className='select_message_email select_message_Style'
                                                        isSearchable={false} 
                                                        placeholder={<div className='selectplace'>{n.user_parameters.communication_format||langv?.compaings?.messages?.text22}</div>}
                                                        onChange={(option) =>  ChangeCommunicationFormat(n.id, option.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='info_message'>
                                            <div>
                                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 13.5H10V8H9V13.5ZM9.5 6.577C9.67467 6.577 9.821 6.518 9.939 6.4C10.057 6.282 10.1157 6.13567 10.115 5.961C10.1143 5.78633 10.0553 5.64033 9.938 5.523C9.82067 5.40567 9.67467 5.34667 9.5 5.346C9.32533 5.34533 9.17933 5.40433 9.062 5.523C8.94467 5.64167 8.88567 5.788 8.885 5.962C8.88433 6.136 8.94333 6.282 9.062 6.4C9.18067 6.518 9.32667 6.577 9.5 6.577ZM9.503 18C8.25833 18 7.08833 17.764 5.993 17.292C4.89767 16.8193 3.94467 16.178 3.134 15.368C2.32333 14.558 1.68167 13.606 1.209 12.512C0.736333 11.418 0.5 10.2483 0.5 9.003C0.5 7.75767 0.736333 6.58767 1.209 5.493C1.681 4.39767 2.32133 3.44467 3.13 2.634C3.93867 1.82333 4.891 1.18167 5.987 0.709C7.083 0.236333 8.253 0 9.497 0C10.741 0 11.911 0.236333 13.007 0.709C14.1023 1.181 15.0553 1.82167 15.866 2.631C16.6767 3.44033 17.3183 4.39267 17.791 5.488C18.2637 6.58333 18.5 7.753 18.5 8.997C18.5 10.241 18.264 11.411 17.792 12.507C17.32 13.603 16.6787 14.556 15.868 15.366C15.0573 16.176 14.1053 16.8177 13.012 17.291C11.9187 17.7643 10.749 18.0007 9.503 18ZM9.5 17C11.7333 17 13.625 16.225 15.175 14.675C16.725 13.125 17.5 11.2333 17.5 9C17.5 6.76667 16.725 4.875 15.175 3.325C13.625 1.775 11.7333 1 9.5 1C7.26667 1 5.375 1.775 3.825 3.325C2.275 4.875 1.5 6.76667 1.5 9C1.5 11.2333 2.275 13.125 3.825 14.675C5.375 16.225 7.26667 17 9.5 17Z" fill="#EB731B"/>
                                                </svg>
                                                {langv?.compaings?.messages?.text23}
                                            </div>
                                            <div className={n.generated_message!=""?'but_input_messages_generate':'but_input_messages_generate grey'} onClick={()=>generatemessage(n.id)}>
                                                {langv?.compaings?.messages?.text27}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='line_top_mes'>
                                        <div className='title_mesa'>{n.user_parameters?.message_name||"Message Name"}</div>
                                        <div onClick={()=>setcloseparam(n.id)} style={{cursor:"pointer"}}>
                                            <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 3.07617 8.53906)" fill="#020203"/>
                                                <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 3.07617 8.53906)" fill="black" fill-opacity="0.2"/>
                                                <circle cx="1.53846" cy="1.53846" r="1.53846" transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 3.07617 8.53906)" fill="black" fill-opacity="0.2"/>
                                                <path d="M3.84615 13.1543L10 6.81397L3.84615 0.846604" stroke="#020203"/>
                                                <path d="M3.84615 13.1543L10 6.81397L3.84615 0.846604" stroke="black" stroke-opacity="0.2"/>
                                                <path d="M3.84615 13.1543L10 6.81397L3.84615 0.846604" stroke="black" stroke-opacity="0.2"/>
                                            </svg>
                                        </div>
                                    </div>
                                }
                                {/* <div className='line_top_message'>
                                    <div className='message_body_left'>
                                        <div className='message_body_line'>
                                            <div className='block_input'>
                                                <label>{langv?.compaings?.messages?.text3}</label>
                                                <input
                                                    id="workspacename"
                                                    type="text"
                                                    placeholder={langv?.compaings?.messages?.text4}
                                                    className="line_input_mess line_input_mess_name"
                                                    value=""
                                                />
                                            </div>
                                            
                                        </div>
                                        <div className='message_body_line'>
                                            <div className='block_input'>
                                                <label>{langv?.compaings?.messages?.text5}</label>
                                                <Select 
                                                    components={{ DropdownIndicator }}
                                                    options={optionsCompaings} 
                                                    styles={customStylesEmailmessage} 
                                                    classNamePrefix='filter'
                                                    className='select_message_email'
                                                    isSearchable={false} 
                                                    placeholder={<div className='selectplace'>{langv?.compaings?.messages?.text6}</div>}
                                                    onChange={(option) => handlecompaings(option.value)}
                                                />
                                            </div>
                                            <div className='block_input'>
                                                <label>{langv?.compaings?.messages?.text7}</label>
                                                <input
                                                    id="condition"
                                                    type="text"
                                                    placeholder={langv?.compaings?.messages?.text8}
                                                    className="line_input_mess line_input_mess_purpose"
                                                    value=""
                                                />
                                            </div>
                                        </div>
                                        <div className='message_body_line'>
                                            <div className='block_input'>
                                                <label>{langv?.compaings?.messages?.text9}</label>
                                                <input
                                                    id="condition"
                                                    type="text"
                                                    placeholder={langv?.compaings?.messages?.text10}
                                                    className="line_input_mess line_input_mess_purpose"
                                                    value=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='message_body_left' style={{paddingLeft: "15px"}}>
                                        <div className='message_body_line right_mes_but'>
                                            <div className='block_input'>
                                                <div className='but_message_top' onClick={() =>checkmessage(id, "true")}>
                                                    <svg className='active_but_message_top' width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.63927 2.1998C7.32428 1.88482 6.78571 2.1079 6.78571 2.55336L6.78571 5.71387L9.94623 5.71387C10.3917 5.71387 10.6148 5.1753 10.2998 4.86031L9.35715 3.91769L12.1464 1.12838C12.3417 0.933111 12.3417 0.616523 12.1464 0.421263L12.0783 0.353118C11.883 0.157864 11.5664 0.157868 11.3712 0.353128L8.5819 3.14243L7.63927 2.1998Z" fill="#020203"/>
                                                        <path d="M7.63927 2.1998C7.32428 1.88482 6.78571 2.1079 6.78571 2.55336L6.78571 5.71387L9.94623 5.71387C10.3917 5.71387 10.6148 5.1753 10.2998 4.86031L9.35715 3.91769L12.1464 1.12838C12.3417 0.933111 12.3417 0.616523 12.1464 0.421263L12.0783 0.353118C11.883 0.157864 11.5664 0.157868 11.3712 0.353128L8.5819 3.14243L7.63927 2.1998Z" fill="black" fill-opacity="0.2"/>
                                                        <path d="M7.63927 2.1998C7.32428 1.88482 6.78571 2.1079 6.78571 2.55336L6.78571 5.71387L9.94623 5.71387C10.3917 5.71387 10.6148 5.1753 10.2998 4.86031L9.35715 3.91769L12.1464 1.12838C12.3417 0.933111 12.3417 0.616523 12.1464 0.421263L12.0783 0.353118C11.883 0.157864 11.5664 0.157868 11.3712 0.353128L8.5819 3.14243L7.63927 2.1998Z" fill="black" fill-opacity="0.2"/>
                                                        <path d="M5.36073 9.8002C5.67572 10.1152 6.21429 9.8921 6.21429 9.44664L6.21429 6.28613L3.05377 6.28613C2.60831 6.28613 2.38523 6.8247 2.70021 7.13969L3.64285 8.08231L0.853559 10.8716C0.658295 11.0669 0.6583 11.3835 0.853569 11.5787L0.921717 11.6469C1.11698 11.8421 1.43356 11.8421 1.62882 11.6469L4.4181 8.85757L5.36073 9.8002Z" fill="#020203"/>
                                                        <path d="M5.36073 9.8002C5.67572 10.1152 6.21429 9.8921 6.21429 9.44664L6.21429 6.28613L3.05377 6.28613C2.60831 6.28613 2.38523 6.8247 2.70021 7.13969L3.64285 8.08231L0.853559 10.8716C0.658295 11.0669 0.6583 11.3835 0.853569 11.5787L0.921717 11.6469C1.11698 11.8421 1.43356 11.8421 1.62882 11.6469L4.4181 8.85757L5.36073 9.8002Z" fill="black" fill-opacity="0.2"/>
                                                        <path d="M5.36073 9.8002C5.67572 10.1152 6.21429 9.8921 6.21429 9.44664L6.21429 6.28613L3.05377 6.28613C2.60831 6.28613 2.38523 6.8247 2.70021 7.13969L3.64285 8.08231L0.853559 10.8716C0.658295 11.0669 0.6583 11.3835 0.853569 11.5787L0.921717 11.6469C1.11698 11.8421 1.43356 11.8421 1.62882 11.6469L4.4181 8.85757L5.36073 9.8002Z" fill="black" fill-opacity="0.2"/>
                                                    </svg>
                                                    <svg className='hover_but_message_top' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.13927 2.20078C6.82428 1.8858 6.28571 2.10888 6.28571 2.55433L6.28571 5.71484L9.44623 5.71484C9.89169 5.71484 10.1148 5.17627 9.79979 4.86129L8.85715 3.91866L11.6464 1.12935C11.8417 0.934087 11.8417 0.617499 11.6464 0.422239L11.5783 0.354095C11.383 0.158841 11.0664 0.158845 10.8712 0.354105L8.0819 3.14341L7.13927 2.20078Z" fill="#F5C538"/>
                                                        <path d="M4.86073 9.79922C5.17572 10.1142 5.71429 9.89112 5.71429 9.44567L5.71429 6.28516L2.55377 6.28516C2.10831 6.28516 1.88523 6.82373 2.20021 7.13871L3.14285 8.08134L0.353559 10.8706C0.158295 11.0659 0.1583 11.3825 0.353569 11.5778L0.421717 11.6459C0.616981 11.8412 0.933558 11.8412 1.12882 11.6459L3.9181 8.85659L4.86073 9.79922Z" fill="#F5C538"/>
                                                    </svg>
                                                    {langv?.compaings?.messages?.text11}
                                                </div>
                                            </div>
                                            <div className='block_input'>
                                                <div className='but_message_top bmt2' onClick={()=>deletemessage(id)}>
                                                    <svg className='active_but_message_top' width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.6836 4.69824L4.31913 13.3026" stroke="#EA4334" stroke-width="1.5" stroke-linecap="round"/>
                                                        <path d="M4.19727 4.81836L12.8017 13.1828" stroke="#EA4334" stroke-width="1.5" stroke-linecap="round"/>
                                                    </svg>
                                                    <svg className='hover_but_message_top' width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.668 4.69922L4.3035 13.3036" stroke="#F5C538" stroke-width="1.5" stroke-linecap="round"/>
                                                        <path d="M4.18164 4.81836L12.786 13.1828" stroke="#F5C538" stroke-width="1.5" stroke-linecap="round"/>
                                                    </svg>
                                                    {langv?.compaings?.messages?.text12}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='message_body_line'>
                                            <div className='block_input'>
                                                <label>{langv?.compaings?.messages?.text13}</label>
                                                <input
                                                    id="goal"
                                                    type="text"
                                                    placeholder={langv?.compaings?.messages?.text14}
                                                    className="line_input_mess line_input_mess_purpose"
                                                    value=""
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className='message_body_line'>
                                            <div className='block_input'>
                                                <label>{langv?.compaings?.messages?.text15}</label>
                                                <input
                                                    id="condition"
                                                    type="text"
                                                    placeholder={langv?.compaings?.messages?.text16}
                                                    className="line_input_mess line_input_mess_purpose"
                                                    value=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className='message_body_center'> 
                                    <div className='block_input'>
                                        <label>{langv?.compaings?.messages?.text17}</label>
                                        <Select 
                                            components={{ DropdownIndicator }}
                                            options={optionsCompaings2} 
                                            styles={customStylesStyle} 
                                            classNamePrefix='filter'
                                            className='select_message_email select_message_Style'
                                            isSearchable={false} 
                                            placeholder={<div className='selectplace'>{langv?.compaings?.messages?.text18}</div>}
                                            onChange={(option) => handlecompaings(option.value)}
                                        />
                                    </div>
                                    <div className='block_input'>
                                        <label>{langv?.compaings?.messages?.text19}</label>
                                        <Select 
                                            components={{ DropdownIndicator }}
                                            options={optionsCompaings3} 
                                            styles={customStylesStyle} 
                                            classNamePrefix='filter'
                                            className='select_message_email'
                                            isSearchable={false} 
                                            placeholder={<div className='selectplace'>{langv?.compaings?.messages?.text20}</div>}
                                            onChange={(option) => handlecompaings(option.value)}
                                        />
                                    </div>
                                    <div className='block_input'>
                                        <label>{langv?.compaings?.messages?.text21}</label>
                                        <Select 
                                            components={{ DropdownIndicator }}
                                            options={optionsCompaings4} 
                                            styles={customStylesStyle} 
                                            classNamePrefix='filter'
                                            className='select_message_email select_message_Style'
                                            isSearchable={false} 
                                            placeholder={<div className='selectplace'>{langv?.compaings?.messages?.text22}</div>}
                                            onChange={(option) => handlecompaings(option.value)}
                                        />
                                    </div>
                                </div> */}
                                
                                <div className='messages'>
                                    
                                    <div className='mesage_val'>
                                        <div className='telo_text'>
                                            <div className='defalt_text_message'>
                                                {n.generated_message==null?langv?.compaings?.messages?.text24:<></>}
                                            </div>
                                            
                                                {
                                                    load==1?
                                                    <div className='text_message'>
                                                        <Loading text = {textloading}/>
                                                    </div>
                                                    :
                                                    <>
                                                        <div className='text_message' dangerouslySetInnerHTML={{ __html: n.generated_message?.replaceAll("\n\n", "<br/>") }}></div>
                                                    </>
                                                    
                                                }
                                            
                                            <div className='copy_message' onClick={()=>copytext(n.generated_message)}>
                                                <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.834 1.5H0.833984V12.3333" stroke="#020203" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M10.834 1.5H0.833984V12.3333" stroke="black" stroke-opacity="0.2" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M10.834 1.5H0.833984V12.3333" stroke="black" stroke-opacity="0.2" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M4.16797 4.83301H14.168V14.833C14.168 15.275 13.9924 15.699 13.6798 16.0115C13.3673 16.3241 12.9433 16.4997 12.5013 16.4997H5.83464C5.39261 16.4997 4.96869 16.3241 4.65612 16.0115C4.34356 15.699 4.16797 15.275 4.16797 14.833V4.83301Z" stroke="#020203" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M4.16797 4.83301H14.168V14.833C14.168 15.275 13.9924 15.699 13.6798 16.0115C13.3673 16.3241 12.9433 16.4997 12.5013 16.4997H5.83464C5.39261 16.4997 4.96869 16.3241 4.65612 16.0115C4.34356 15.699 4.16797 15.275 4.16797 14.833V4.83301Z" stroke="black" stroke-opacity="0.2" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M4.16797 4.83301H14.168V14.833C14.168 15.275 13.9924 15.699 13.6798 16.0115C13.3673 16.3241 12.9433 16.4997 12.5013 16.4997H5.83464C5.39261 16.4997 4.96869 16.3241 4.65612 16.0115C4.34356 15.699 4.16797 15.275 4.16797 14.833V4.83301Z" stroke="black" stroke-opacity="0.2" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        </div>
                                            
                                    </div>
                                    <div className='lineinput_line_message_all'>
                                        <div className='lineinput_line_message'>
                                            <input
                                                id="workspacename"
                                                type="text"
                                                placeholder={langv?.compaings?.messages?.text25}
                                                className="line_input_send"
                                                value=""
                                            />
                                            <div className='but_input_messages'>
                                            {langv?.compaings?.messages?.text26}
                                                <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.6">
                                                    <ellipse cx="2.125" cy="2.15385" rx="2.125" ry="2.15385" transform="matrix(-1 0 0 1 11.625 11.1924)" fill="#020203"/>
                                                    <ellipse cx="2.125" cy="2.15385" rx="2.125" ry="2.15385" transform="matrix(-1 0 0 1 11.625 11.1924)" fill="black" fill-opacity="0.2"/>
                                                    <ellipse cx="2.125" cy="2.15385" rx="2.125" ry="2.15385" transform="matrix(-1 0 0 1 11.625 11.1924)" fill="black" fill-opacity="0.2"/>
                                                    <path d="M18 10.1154L9.24242 1.5L1 10.1154" stroke="#020203" stroke-linecap="round"/>
                                                    <path d="M18 10.1154L9.24242 1.5L1 10.1154" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                                    <path d="M18 10.1154L9.24242 1.5L1 10.1154" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className={n.generated_message!=""?'but_input_messages_generate':'but_input_messages_generate grey'}  onClick={()=>generatemessage(n.id)}>
                                            {langv?.compaings?.messages?.text32}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
           
            
            {/* <div className='generate_message' onClick={gmessage}>
                Generate Messages
            </div> */}
        </>
    );
})

export default Messages;
