import React, {useState} from "react";
import { toBase64 } from "../../fuse/utils";
import { DropzoneArea } from "material-ui-dropzone";
import GlobalState from "../../stores/GlobalState";
import lang from "../../lang"
export default function AttachmentBox({ attachments, setAttachments }) {
  const sizeInMB = (sizeInBytes) => {
    return (sizeInBytes / (1024 * 1024)).toFixed(2);
  };
  
  const handleFileDelete = (file, arg2, arg3) => {};
  const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
  const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
  const handleFilesChange = async (files) => {
    if (!files.length === 0) {
      return;
    }

    const filesToUpload = [];

    for (const file of files) {


      const fileContent = await toBase64(file);
      const fileExtension = file.name.split(".").pop();
      const fileName = file.name;
      const fileSize = sizeInMB(file.size);

      filesToUpload.push({
        file_content: fileContent,
        file_extension: fileExtension,
        file_name: fileName,
        file_path: fileName,
        file_size: fileSize,
        file_type: 2,
        file: file,
      });
    }
    setAttachments(filesToUpload);
  };
  
  

  return (
    <div className="my-14 px-10 zeras">
      <DropzoneArea
        initialFiles={attachments}
        acceptedFiles={['.csv, .json']}
        filesLimit={1}
        maxFileSize={99000000}
        clearOnUnmount={false}
        dropzoneClass="min-h-88"
        onDelete={handleFileDelete}
        onChange={handleFilesChange}
        dropzoneText={dropzoneText()}
        showPreviewsInDropzone={false}
        showPreviews={false}
        Icon="none"
      />

      {/* <div className="flex flex-wrap">
        {attachments.map((val, index) => (
          <SingleFile deleteFile={() => deleteFile(index)} {...val} />
        ))}
      </div> */}
    </div>
  );
}

const dropzoneText = () => {
  var langv = lang.en

  return (
    <>
      <div className="text-center my-16 dropanddrag">
        <div className="uploadiconline">
          <div className="uploadicon">
            <FileIcon />
          </div>
        </div>
        
        <div className="title_dropanddrag"  >
        {GlobalState.ispopupaddAttachmentShowbigdata==2 ? langv?.dataset?.popupadddataset?.text4: langv?.dataset?.popupadddataset?.text5}
        </div>
        <p className="text_dropanddrag">
          {langv?.dataset?.popupadddataset?.text6}
        </p>
        <div className="buttom_dropanddrag_line">
          <div className="buttom_dropanddrag">{GlobalState.ispopupaddAttachmentShowbigdata==2 ?langv?.dataset?.popupadddataset?.text7: langv?.dataset?.popupadddataset?.text8}</div>
        </div>
        
      </div>
    </>
  );
};



const FileIcon = () => (
  <>
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.9992 0.642365L9.89062 9.64347L11.9106 11.4722L18.5706 5.5435V27.5439H21.4278V5.5435L28.0849 11.4696L30.1078 9.64347L19.9992 0.642365Z" fill="#F5C538"/>
      <path d="M0 38.1002V40.6436H2.85714H20H37.1429H40V38.1002H0Z" fill="#F5C538"/>
    </svg>

  </>
);

