import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import GlobalState from "../../../stores/GlobalState";
import lang from "../../../lang"
const Accuracy = observer(() => {
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    return (
        
        <div className='accuracy_block'>
           <div className='accuracy_left'>
                <div className='accuraccy_1_one_blcok'>
                    <div className='accuracy_left_title'>
                    {langv?.module_models?.accuracy?.text1}
                    </div>
                    <div className='accuracy_left_value' style={GlobalState.activemodel?.lastTrain?.metadata?.metrics?.accuracy>=0&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.accuracy<=0.49?{color:"rgba(234, 67, 52, 1)"}:GlobalState.activemodel?.lastTrain?.metadata?.metrics?.accuracy>0.49&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.accuracy<=0.59?{color:"rgba(235, 115, 27, 1)"}:GlobalState.activemodel?.lastTrain?.metadata?.metrics?.accuracy>0.59&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.accuracy<=0.69?{color:"rgba(66, 133, 244, 1)"}:GlobalState.activemodel?.lastTrain?.metadata?.metrics?.accuracy>0.69&&GlobalState.activemodel?.lastTrain?.metadata?.metrics?.accuracy<=0.79?{color:"rgba(65, 168, 177, 1)"}:{color:"rgba(51, 167, 83, 1)"}}>
                        {
                            (GlobalState.activemodel?.lastTrain?.metadata?.metrics?.accuracy*100).toFixed(1)
                        }    %
                    </div>
                    <div className='accuracy_left_subtitle'>
                        {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.correctPredictions !=null?GlobalState.activemodel?.lastTrain?.metadata?.metrics?.correctPredictions:'n/a'} <span dangerouslySetInnerHTML={{ __html: langv?.module_models?.accuracy?.text2}}></span> {GlobalState.activemodel?.lastTrain?.metadata?.metrics?.predictionsTotal||'n/a'} <span dangerouslySetInnerHTML={{ __html: langv?.module_models?.accuracy?.text3}}></span>
                    </div>
                </div>

                
           </div>
           <div className='accuracy_right'>
                <div className='line_1_accur'>
                    <div className='accuracy_right_col' >
                        <label>{langv?.module_models?.accuracy?.text5}</label>
                        {
                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets?.map((n, id)=>


                                GlobalState.activemodel?.lastTrain?.learningType=="binary"?
                                <input
                                    id="source"
                                    type="text"
                                    disabled="true"
                                    placeholder="0"
                                    className=""
                                    value={n.target}
                                />
                                :
                                id<(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1)?
                                <input
                                    id="source"
                                    type="text"
                                    disabled="true"
                                    placeholder="0"
                                    className=""
                                    value={n.target}
                                />
                                :
                                <></>
                            )
                        }
                        
                    </div>
                    <div className='accuracy_right_col'>
                        <label>{langv?.module_models?.accuracy?.text7}</label>
                        {
                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets?.map((n, id)=>
                                
                                GlobalState.activemodel?.lastTrain?.learningType=="binary"?
                                <input
                                    id="source"
                                    type="text"
                                    disabled="true"
                                    placeholder="0"
                                    className=""
                                    style={n.recall>=0&&n.recall<0.5?{color:"rgba(234, 67, 52, 1)"}:n.recall>=0.5&&n.recall<0.6?{color:"rgba(235, 115, 27, 1)"}:n.recall>=0.6&&n.recall<0.7?{color:"rgba(66, 133, 244, 1)"}:n.recall>=0.7&&n.recall<0.8?{color:"rgba(65, 168, 177, 1)"}:{color:"rgba(51, 167, 83, 1)"}}
                                    value={n.recall.toFixed(2)}
                                />
                                :
                                id<(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1)?
                                <input
                                    id="source"
                                    type="text"
                                    disabled="true"
                                    placeholder="0"
                                    className=""
                                    style={n.recall>=0&&n.recall<0.5?{color:"rgba(234, 67, 52, 1)"}:n.recall>=0.5&&n.recall<0.6?{color:"rgba(235, 115, 27, 1)"}:n.recall>=0.6&&n.recall<0.7?{color:"rgba(66, 133, 244, 1)"}:n.recall>=0.7&&n.recall<0.8?{color:"rgba(65, 168, 177, 1)"}:{color:"rgba(51, 167, 83, 1)"}}
                                    value={n.recall.toFixed(2)}
                                />
                                :
                                <></>
                            )
                        }
                    
                    
                       
                    </div>
                    <div className='accuracy_right_col'>
                        <label>{langv?.module_models?.accuracy?.text9}</label>
                        {
                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets?.map((n, id)=>
                                
                                GlobalState.activemodel?.lastTrain?.learningType=="binary"?
                                <input
                                    id="source"
                                    type="text"
                                    disabled="true"
                                    placeholder="0"
                                    className=""
                                    style={n.precision>=0&&n.precision<=0.49?{color:"rgba(234, 67, 52, 1)"}:n.precision>0.49&&n.precision<=0.59?{color:"rgba(235, 115, 27, 1)"}:n.precision>0.59&&n.precision<=0.69?{color:"rgba(66, 133, 244, 1)"}:n.precision>0.69&&n.precision<=0.79?{color:"rgba(65, 168, 177, 1)"}:{color:"rgba(51, 167, 83, 1)"}}
                                    value={n.precision.toFixed(2)}
                                />
                                :
                                id<(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1)?
                                <input
                                    id="source"
                                    type="text"
                                    disabled="true"
                                    placeholder="0"
                                    className=""
                                    style={n.precision>=0&&n.precision<=0.49?{color:"rgba(234, 67, 52, 1)"}:n.precision>0.49&&n.precision<=0.59?{color:"rgba(235, 115, 27, 1)"}:n.precision>0.59&&n.precision<=0.69?{color:"rgba(66, 133, 244, 1)"}:n.precision>0.69&&n.precision<=0.79?{color:"rgba(65, 168, 177, 1)"}:{color:"rgba(51, 167, 83, 1)"}}
                                    value={n.precision.toFixed(2)}
                                />
                                :
                                <></>
                            )
                        }
                        
                    
                       
                    </div>
                    <div className='accuracy_right_col'>
                        <label>{langv?.module_models?.accuracy?.text11}</label>
                        {
                            GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets?.map((n, id)=>
                               
                                GlobalState.activemodel?.lastTrain?.learningType=="binary"?
                                <input
                                    id="source"
                                    type="text"
                                    disabled="true"
                                    placeholder="0"
                                    className=""
                                    style={n.f1Score>=0&&n.f1Score<=0.49?{color:"rgba(234, 67, 52, 1)"}:n.f1Score>0.49&&n.f1Score<=0.59?{color:"rgba(235, 115, 27, 1)"}:n.f1Score>0.59&&n.f1Score<=0.69?{color:"rgba(66, 133, 244, 1)"}:n.f1Score>0.69&&n.f1Score<=0.79?{color:"rgba(65, 168, 177, 1)"}:{color:"rgba(51, 167, 83, 1)"}}
                                    value={n.f1Score.toFixed(2)}
                                />
                                :
                                id<(GlobalState.activemodel?.lastTrain?.metadata?.metrics?.targets.length-1)?
                                <input
                                    id="source"
                                    type="text"
                                    disabled="true"
                                    placeholder="0"
                                    className=""
                                    style={n.f1Score>=0&&n.f1Score<=0.49?{color:"rgba(234, 67, 52, 1)"}:n.f1Score>0.49&&n.f1Score<=0.59?{color:"rgba(235, 115, 27, 1)"}:n.f1Score>0.59&&n.f1Score<=0.69?{color:"rgba(66, 133, 244, 1)"}:n.f1Score>0.69&&n.f1Score<=0.79?{color:"rgba(65, 168, 177, 1)"}:{color:"rgba(51, 167, 83, 1)"}}
                                    value={n.f1Score.toFixed(2)}
                                />
                                :
                                <></>
                            )
                        }
                        
                    
                        
                    </div>
                </div>
                <div className='line_2_accur'>
                    <div className='line_texta_acura line1_acura'>
                        {langv?.module_models?.accuracy?.text13}
                    </div>
                    <div className='line_texta_acura'>
                        <div className='accuracy_right_col0 mini'>
                            <div className='accuracy_right_buttom_text'>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="5" cy="5" r="5" fill="#33A753"/>
                                </svg>
                                {langv?.module_models?.accuracy?.text4}
                            </div>
                        </div>
                        <div className='accuracy_right_col0'>
                            <div className='accuracy_right_buttom_text'>
                                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="5.75" cy="5" r="5" fill="#41A8B1"/>
                                </svg>

                                {langv?.module_models?.accuracy?.text6}
                            </div>
                        </div>
                        <div className='accuracy_right_col0 mini'>
                            <div className='accuracy_right_buttom_text '>
                                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="5.5" cy="5" r="5" fill="#4285F4"/>
                                </svg>

                                {langv?.module_models?.accuracy?.text8}
                            </div>
                        </div>
                        <div className='accuracy_right_col0 maxi'>
                            <div className='accuracy_right_buttom_text '>
                                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="5.25" cy="5" r="5" fill="#EB731B"/>
                                </svg>
                                {langv?.module_models?.accuracy?.text10}
                            </div>
                        </div>
                        <div className='accuracy_right_col0 maxi'>
                            <div className='accuracy_right_buttom_text '>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="5" cy="5" r="5" fill="#EA4334"/>
                                </svg>

                                {langv?.module_models?.accuracy?.text12}
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    )
})
export default Accuracy;