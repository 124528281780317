import React, {useState} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import { changepopuprefreshDataset, deletepopupdataset, changepopuprefreshDatasetnum} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import lang from "../../lang"
const PopupRefreshDataset = observer(({id, deleteIdnum}) =>{
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [disable, setDisable]= useState(false)
    
    const refreshproductline = async (id) => {
        var token = localStorage.getItem("token");
        setDisable(true)
        fetch(Domen.url + `dataset/bigQuery/`+id+`/refresh`, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
           console.log(response+"aliluya")
           changepopuprefreshDataset()
           changepopuprefreshDatasetnum(id);
           setDisable(false)
           setTimeout(function(){
            changepopuprefreshDatasetnum(0)
           }, 5000)
        })
        .catch(error => {
            console.error('There was an error with the fetch operation:', error);
        });
        
    };

    return (
        <div className="popupInfofon refreshpopup">
            <div className="popupInfo deleteProduct" style={{position: "fixed"}}>
                <div className='top-element'>
                    {/* <div className='separator'></div> */}
                    <div></div>
                    <div className='buttonspopup'>
                        <div className='buttonexit' onClick={changepopuprefreshDataset}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                {
                    id==0?
                    <div className='form-element'>
                        <div className='titlePopupDelete' dangerouslySetInnerHTML={{ __html: langv?.dataset?.popuprefreshdataset?.text1+"<span> all datasets</span>" }}>
                        
                        </div>
                        <div className='buttonsPopupDelete'>
                            <div className='buttonPopupDelete' onClick={changepopuprefreshDataset}>
                                {langv?.dataset?.popupdeletedataset?.text2}
                            </div>
                            <div className='buttonPopupDelete add_refresh' >
                                {langv?.dataset?.popupdeletedataset?.text3}
                            </div>
                        </div>
                    </div>
                    :

                
                    <div className='form-element'>
                        <div className='titlePopupDelete' dangerouslySetInnerHTML={{ __html: langv?.dataset?.popuprefreshdataset?.text1+"<span>"+ deleteIdnum+"</span>" }}>
                        
                        </div>
                        <div className='buttonsPopupDelete'>
                            <div className='buttonPopupDelete' onClick={changepopuprefreshDataset}>
                                {langv?.dataset?.popupdeletedataset?.text2}
                            </div>
                            {
                                disable==false?
                                <div className='buttonPopupDelete add_refresh' onClick={() => refreshproductline(id)}>
                                    {langv?.dataset?.popupdeletedataset?.text3}
                                </div>
                                :
                                <div className='buttonPopupDelete add_refresh' style={{background:"#ccc", cursor: "default"}}>
                                    {langv?.dataset?.popupdeletedataset?.text3}
                                </div>
                            }
                            
                        </div>
                    </div>
                }               
            </div>
        </div>
    );
});

export default PopupRefreshDataset;
